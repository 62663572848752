
import admin from "@/store/modules/admin";
import { adminModule } from "@/store/modules/admin";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import SendInviteDialog from "@/components/send-invite/SendInviteDialog.vue";
import CreateSpaceDialog from "@/components/create-space/CreateSpaceDialog.vue";
import SpacesTable from "@/components/spaces-table/SpacesTable.vue";
import { GetSpacesRequest, Pagination } from "zaehlerfreunde-central/space_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SearchParams } from "@/components/devices-table/DevicesTable.vue";

@Component({
  components: {
    SendInviteDialog,
    CreateSpaceDialog,
    SpacesTable,
  },
})
export default class AdminSpacesOverview extends Vue {
  @adminModule.State partnerSpaces: RemoteData<UserError, Space[]>;
  @adminModule.State spacePages: RemoteData<UserError, number>;
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  showInviteDialog: boolean = false;
  selectedSpaceForInvite: Space | null = null;
  showCreateSpaceDialog: boolean = false;
  itemsPerPage = 10;
  page = 1;
  search: string = "";

  itemControls = [
    {
      icon: "mdi-account-plus",
      event: "invite-user-to-space",
      color: "primary",
    },
  ];
  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "create-new-space",
      color: "primary",
    },
  ];

  beforeMount(): void {
    const search = (this.$route.query.search as string) ?? "";
    const page = typeof this.$route.query.page === "string" ? parseInt(this.$route.query.page, 10) : 1;
    if (search == "" && page == 1) {
      this.loadSpaces();
    }
  }

  async searchParamsInput(params: SearchParams): Promise<void> {
    this.page = params.page;
    this.search = params.search;
    this.loadSpaces();
  }

  loadSpaces(): void {
    const request = new GetSpacesRequest();
    request.setPartnerSpaces(true);
    request.setPagination(new Pagination().setPageSize(this.itemsPerPage).setPage(this.page - 1));
    if (this.search != "") {
      request.setFilter(this.search);
    }

    if (this.partner.data) request.setPartnerId(this.partner.data.getId());

    admin.getPartnerSpaces(request);
  }

  onInviteToSpaceClicked(spaceId: string): void {
    event?.stopPropagation();
    this.selectedSpaceForInvite = this.partnerSpaces.list.find((s) => s.getId() == spaceId) ?? null;
    this.showInviteDialog = true;
  }
}
