
import InvitesTable from "@/components/invites-table/InvitesTable.vue";
import { userServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";
import { GetInvitesRequest } from "zaehlerfreunde-central/user_service_pb";
import SendInviteDialog from "@/components/send-invite/SendInviteDialog.vue";
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: {
    InvitesTable,
    SendInviteDialog,
  },
})
export default class AdminInvitesOverview extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  invites: RemoteData<UserError, Invite[]> = initialized;
  totalNumPages: RemoteData<UserError, number> = initialized;

  itemsPerPage = 10;

  showSendInviteDialog = false;
  paths = paths;

  headerControls = [
    {
      icon: "mdi-plus",
      event: "send-invite",
    },
  ];

  beforeMount(): void {
    this.loadInvites(0);
  }

  async loadInvites(page: number, search?: string): Promise<void> {
    this.invites = pending;

    const request = new GetInvitesRequest();
    request.setPage(page);
    request.setPageSize(this.itemsPerPage);

    if (search) {
      request.setEmail(search);
    }

    if (this.partner.data) request.setPartnerId(this.partner.data.getId());

    try {
      const response = await userServiceClient.getInvites(request, {});
      this.invites = success(response.getInvitesList());
      this.totalNumPages = success(response.getTotalNumPages());
    } catch (error) {
      this.invites = failure(userErrorFrom(error));
    }
  }
}
