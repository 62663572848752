
import { Vue, Component, Watch } from "vue-property-decorator";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import account, { accountModule } from "@/store/modules/account";
import IoUtils, { FileType } from "@/utils/io-utils";
import { userServiceClient } from "@/config/service-clients";
import { formatNumber } from "@/utils/number-utils";
import { Invoice } from "zaehlerfreunde-proto-types/invoice_pb";
import { DownloadInvoiceResponse, GetInvoicesRequest } from "zaehlerfreunde-central/user_service_pb";
import { spacesModule } from "@/store/modules/spaces";

export interface InvoiceModel {
  id: string;
  title: string;
  periodStart: string;
  periodEnd: string;
  issueDate: string;
  dueDate: string;
  total: string;
  hasHtml: boolean;
}
@Component({})
export default class InvoicesOverview extends Vue {
  @accountModule.State downloadInvoiceResponse: RemoteData<UserError, DownloadInvoiceResponse>;
  @accountModule.State invoiceHTML: RemoteData<UserError, string>;
  @spacesModule.Getter selectedSpaceId: string;

  invoices: RemoteData<UserError, Invoice[]> = initialized;
  itemsPerPage = 20;
  page = 1;
  invoicesTotalNumPages = 0;
  invoicesColumns: string[] = [];

  invoiceHTMLContent: string = "";
  selectedInvoice: InvoiceModel;
  showInvoiceDialog = false;

  get headers(): { text: string; value: string }[] {
    const baseHeaders = [
      { text: "Rechnungstitel", value: "title" },
      { text: "Von", value: "periodStart" },
      { text: "Bis", value: "periodEnd" },
      { text: "Fälligkeitsdatum", value: "dueDate" },
      { text: "Ausgabetag", value: "issueDate" },
      { text: "Gesamtbetrag", value: "total" },
      { text: "", value: "download" },
      { text: "", value: "details" },
    ];
    return baseHeaders.filter((baseHeader) => this.invoicesColumns.includes(baseHeader.text) || !baseHeader.text);
  }

  metaInfo(): { title: string } {
    return {
      title: "Rechnungen",
    };
  }

  async mounted(): Promise<void> {
    await this.loadInvoices();
  }

  onCloseButtonClicked(): void {
    this.showInvoiceDialog = false;
  }

  viewDetails(invoice: InvoiceModel): void {
    account.getInvoiceHTML(invoice.id).then(() => {
      this.showInvoiceDialog = true;
      this.selectedInvoice = invoice;

      this.invoiceHTMLContent = this.invoiceHTML.data ?? "";

      //remove invoice page footer style
      this.invoiceHTMLContent = this.invoiceHTMLContent.replace(/page-footer/gi, "");
    });
  }

  downloadInvoice(invoice: InvoiceModel): void {
    account.downloadInvoice(invoice.id).then(() => {
      if (this.downloadInvoiceResponse.succeeded) {
        IoUtils.fetchFile(
          this.downloadInvoiceResponse.data?.getFileBytes() as Uint8Array,
          FileType.PDF,
          `Invoice_${invoice.dueDate ?? invoice.issueDate}`
        );
      }
    });
  }

  @Watch("selectedSpaceId")
  async onSpaceChanged(): Promise<void> {
    await this.loadInvoices();
  }

  @Watch("page")
  async onPageChanged(): Promise<void> {
    await this.loadInvoices();
  }

  async loadInvoices(): Promise<void> {
    this.invoices = pending;
    const request = new GetInvoicesRequest();
    request.setPage(this.page - 1);
    request.setPageSize(this.itemsPerPage);
    request.setSpaceId(this.selectedSpaceId);

    try {
      const response = await userServiceClient.getInvoices(request, {});
      this.invoices = success(response.getInvoicesList());
      this.invoicesTotalNumPages = response.getTotalNumPages();
      this.invoicesColumns = response.getInvoicesColumnsList();
    } catch (error) {
      this.invoices = failure(userErrorFrom(error));
    }
  }

  get invoicesList(): InvoiceModel[] {
    let result: InvoiceModel[] = [];
    this.invoices?.list.forEach((inv) => {
      result.push({
        id: inv.getId(),
        title: inv.getTitle(),
        periodStart: inv.getPeriodStart(),
        periodEnd: inv.getPeriodEnd(),
        issueDate: inv.getIssueDate(),
        dueDate: inv.getDueDate(),
        total: `${formatNumber(inv.getTotal())} ${inv.getCurrency()}`,
        hasHtml: inv.getHasHtml(),
      });
    });
    return result;
  }
}
