
import { deviceServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import {
  GetIometerCredentialsRequest,
  UpdateIometerCredentialsRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class IometerCreds extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  getCredsCall: RemoteCall<UserError> = initialized;
  updateCredsCall: RemoteCall<UserError> = initialized;
  showUpdatedSnackbar = false;

  token = "";
  secret = "asdasd";

  mounted(): void {
    this.getIometerCreds();
  }

  async getIometerCreds() {
    try {
      this.getCredsCall = pending;

      const request = new GetIometerCredentialsRequest();

      if (this.partner) {
        request.setPartnerId(this.partner.data?.getId() ?? "");
      }

      const response = await deviceServiceClient.getIometerCredentials(request, {});

      this.token = response.getToken();
      this.secret = response.getSecret();

      this.getCredsCall = success(void 0);
    } catch (error) {
      this.getCredsCall = failure(userErrorFrom(error));
    }
  }

  async updateIometerCreds() {
    try {
      this.updateCredsCall = pending;

      const request = new UpdateIometerCredentialsRequest();
      request.setSecret(this.secret);
      request.setToken(this.token);

      if (this.partner) {
        request.setPartnerId(this.partner.data?.getId() ?? "");
      }

      await deviceServiceClient.updateIometerCredentials(request, {});

      this.showUpdatedSnackbar = true;
      this.updateCredsCall = success(void 0);
    } catch (error) {
      this.updateCredsCall = failure(userErrorFrom(error));
    }
  }
}
