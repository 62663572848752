/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_misc_service_pb from '../../services/central/misc_service_pb';


export class MiscServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSubscribeToNewsletter = new grpcWeb.MethodDescriptor(
    '/services.central.MiscService/SubscribeToNewsletter',
    grpcWeb.MethodType.UNARY,
    services_central_misc_service_pb.SubscribeToNewsletterRequest,
    services_central_misc_service_pb.SubscribeToNewsletterResponse,
    (request: services_central_misc_service_pb.SubscribeToNewsletterRequest) => {
      return request.serializeBinary();
    },
    services_central_misc_service_pb.SubscribeToNewsletterResponse.deserializeBinary
  );

  subscribeToNewsletter(
    request: services_central_misc_service_pb.SubscribeToNewsletterRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_misc_service_pb.SubscribeToNewsletterResponse>;

  subscribeToNewsletter(
    request: services_central_misc_service_pb.SubscribeToNewsletterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.SubscribeToNewsletterResponse) => void): grpcWeb.ClientReadableStream<services_central_misc_service_pb.SubscribeToNewsletterResponse>;

  subscribeToNewsletter(
    request: services_central_misc_service_pb.SubscribeToNewsletterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.SubscribeToNewsletterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.MiscService/SubscribeToNewsletter',
        request,
        metadata || {},
        this.methodInfoSubscribeToNewsletter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.MiscService/SubscribeToNewsletter',
    request,
    metadata || {},
    this.methodInfoSubscribeToNewsletter);
  }

  methodInfoLoadFAQContent = new grpcWeb.MethodDescriptor(
    '/services.central.MiscService/LoadFAQContent',
    grpcWeb.MethodType.UNARY,
    services_central_misc_service_pb.LoadFAQContentRequest,
    services_central_misc_service_pb.LoadFAQContentResponse,
    (request: services_central_misc_service_pb.LoadFAQContentRequest) => {
      return request.serializeBinary();
    },
    services_central_misc_service_pb.LoadFAQContentResponse.deserializeBinary
  );

  loadFAQContent(
    request: services_central_misc_service_pb.LoadFAQContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_misc_service_pb.LoadFAQContentResponse>;

  loadFAQContent(
    request: services_central_misc_service_pb.LoadFAQContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.LoadFAQContentResponse) => void): grpcWeb.ClientReadableStream<services_central_misc_service_pb.LoadFAQContentResponse>;

  loadFAQContent(
    request: services_central_misc_service_pb.LoadFAQContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.LoadFAQContentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.MiscService/LoadFAQContent',
        request,
        metadata || {},
        this.methodInfoLoadFAQContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.MiscService/LoadFAQContent',
    request,
    metadata || {},
    this.methodInfoLoadFAQContent);
  }

  methodInfoGetAnnouncement = new grpcWeb.MethodDescriptor(
    '/services.central.MiscService/GetAnnouncement',
    grpcWeb.MethodType.UNARY,
    services_central_misc_service_pb.GetAnnouncementRequest,
    services_central_misc_service_pb.GetAnnouncementResponse,
    (request: services_central_misc_service_pb.GetAnnouncementRequest) => {
      return request.serializeBinary();
    },
    services_central_misc_service_pb.GetAnnouncementResponse.deserializeBinary
  );

  getAnnouncement(
    request: services_central_misc_service_pb.GetAnnouncementRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_misc_service_pb.GetAnnouncementResponse>;

  getAnnouncement(
    request: services_central_misc_service_pb.GetAnnouncementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.GetAnnouncementResponse) => void): grpcWeb.ClientReadableStream<services_central_misc_service_pb.GetAnnouncementResponse>;

  getAnnouncement(
    request: services_central_misc_service_pb.GetAnnouncementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.GetAnnouncementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.MiscService/GetAnnouncement',
        request,
        metadata || {},
        this.methodInfoGetAnnouncement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.MiscService/GetAnnouncement',
    request,
    metadata || {},
    this.methodInfoGetAnnouncement);
  }

  methodInfoSignUpToWebinar = new grpcWeb.MethodDescriptor(
    '/services.central.MiscService/SignUpToWebinar',
    grpcWeb.MethodType.UNARY,
    services_central_misc_service_pb.SignUpToWebinarRequest,
    services_central_misc_service_pb.SignUpToWebinarResponse,
    (request: services_central_misc_service_pb.SignUpToWebinarRequest) => {
      return request.serializeBinary();
    },
    services_central_misc_service_pb.SignUpToWebinarResponse.deserializeBinary
  );

  signUpToWebinar(
    request: services_central_misc_service_pb.SignUpToWebinarRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_misc_service_pb.SignUpToWebinarResponse>;

  signUpToWebinar(
    request: services_central_misc_service_pb.SignUpToWebinarRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.SignUpToWebinarResponse) => void): grpcWeb.ClientReadableStream<services_central_misc_service_pb.SignUpToWebinarResponse>;

  signUpToWebinar(
    request: services_central_misc_service_pb.SignUpToWebinarRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.SignUpToWebinarResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.MiscService/SignUpToWebinar',
        request,
        metadata || {},
        this.methodInfoSignUpToWebinar,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.MiscService/SignUpToWebinar',
    request,
    metadata || {},
    this.methodInfoSignUpToWebinar);
  }

  methodInfoGetUserGuide = new grpcWeb.MethodDescriptor(
    '/services.central.MiscService/GetUserGuide',
    grpcWeb.MethodType.UNARY,
    services_central_misc_service_pb.GetUserGuideRequest,
    services_central_misc_service_pb.GetUserGuideResponse,
    (request: services_central_misc_service_pb.GetUserGuideRequest) => {
      return request.serializeBinary();
    },
    services_central_misc_service_pb.GetUserGuideResponse.deserializeBinary
  );

  getUserGuide(
    request: services_central_misc_service_pb.GetUserGuideRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_misc_service_pb.GetUserGuideResponse>;

  getUserGuide(
    request: services_central_misc_service_pb.GetUserGuideRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.GetUserGuideResponse) => void): grpcWeb.ClientReadableStream<services_central_misc_service_pb.GetUserGuideResponse>;

  getUserGuide(
    request: services_central_misc_service_pb.GetUserGuideRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_misc_service_pb.GetUserGuideResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.MiscService/GetUserGuide',
        request,
        metadata || {},
        this.methodInfoGetUserGuide,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.MiscService/GetUserGuide',
    request,
    metadata || {},
    this.methodInfoGetUserGuide);
  }

}

