
import spaces from "@/store/modules/spaces";
import { pending, failure, success, initialized, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { GetTibberAuthURLRequest } from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";

export interface TibberState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
}

@Component
export default class TibberConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;
  dialog: boolean = false;
  redirectURI: RemoteData<UserError, string> = initialized;
  paths = paths;

  mounted(): void {
    const currentURL: string = window.location.href;

    if (this.hasDE(currentURL)) {
      this.connectTibber();
    } else {
      this.dialog = true;
    }
  }
  
  hasDE(url: string): boolean {
    return url.includes("zaehlerfreunde.de");
  }

  get generalInfoJson(): string {
    return JSON.stringify(this.generalInfo);
  }

  get spaceId(): string {
    return this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId;
  }

  async connectTibber(): Promise<void> {
    this.redirectURI = pending;
    const state = {
      spaceId: this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId,
      deviceName: this.generalInfo.deviceName,
      isMainDevice: this.generalInfo.isMainDevice,
      isAdminAction: this.adminAction,
    };

    try {
      const request = new GetTibberAuthURLRequest();
      request.setState(JSON.stringify(state));
      const response = await deviceServiceClient.getTibberAuthURL(request, {});
      this.redirectURI = success(response.getAuthUrl());

      window.open(response.getAuthUrl(), "_self");
    } catch (error) {
      this.redirectURI = failure(userErrorFrom(error));
    }
  }
}
