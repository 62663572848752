
import { deviceServiceClient, readingsServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { GetDeviceProvidersRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device, DeviceProviderInfo } from "zaehlerfreunde-proto-types/device_pb";
import { Measurement } from "zaehlerfreunde-proto-types/device_reading_pb";
import {
  DynamicTopic,
  ForwardingDestination,
  ForwardingRule,
  MqttDestination,
} from "zaehlerfreunde-proto-types/forwarding_pb";
import { AddForwardingRuleRequest } from "zaehlerfreunde-central/reading_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class CreateForwardingRule extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  name: string = "";
  deviceProviders: RemoteData<UserError, DeviceProviderInfo[]> = initialized;

  selectedMeasurements: Measurement[] = [];
  selectedProviders: Device.Provider[] = [];
  selectedForwardingType: "mqtt" | null = null;
  selectedFormat: ForwardingDestination.Format | null = null;

  createForwardingRuleCall: RemoteCall<UserError> = initialized;

  mqtt = {
    broker: "",
    username: null,
    password: null,
    tls: false,
    topic: "",
    dynamicTopic: false,
    topicDynamicPart: null,
    hearbeatTopic: null,
  };

  forwardingTypes = [
    {
      label: "MQTT",
      value: "mqtt",
    },
  ];

  dynamicTopicParts = [
    {
      label: "Geräte-ID",
      value: DynamicTopic.DynamicPart.DEVICE_ID,
    },
    {
      label: "Seriennummer",
      value: DynamicTopic.DynamicPart.SERIAL_NUMBER,
    },
    {
      label: "Messlokationsnummer",
      value: DynamicTopic.DynamicPart.MELO_ID,
    },
  ];

  measurements = [
    {
      label: "Zählerstände",
      value: Measurement.TOTAL,
    },
    {
      label: "Lastgänge",
      value: Measurement.AMOUNT,
    },
    {
      label: "Leistung",
      value: Measurement.THROUGHPUT,
    },
  ];

  formats = [
    {
      label: "e3",
      value: ForwardingDestination.Format.E3,
    },
    {
      label: "Roh",
      value: ForwardingDestination.Format.RAW,
    },
  ];

  get deviceProviderItems() {
    return this.deviceProviders.list.map((p: DeviceProviderInfo) => ({
      value: p.getValue(),
      label: p.getName(),
    }));
  }

  get isInfoComplete(): boolean {
    return (
      !!this.name &&
      !!this.selectedForwardingType &&
      this.selectedMeasurements.length > 0 &&
      this.selectedProviders.length > 0 &&
      this.selectedFormat !== null &&
      (this.selectedForwardingType !== "mqtt" ||
        (!!this.mqtt.broker && !!this.mqtt.topic && (!this.mqtt.dynamicTopic || this.mqtt.topicDynamicPart !== null)))
    );
  }

  async mounted(): Promise<void> {
    try {
      this.deviceProviders = pending;
      const providers = await deviceServiceClient.getDeviceProviders(new GetDeviceProvidersRequest(), {});
      this.deviceProviders = success(providers.getDeviceProvidersList());
    } catch (error) {
      this.deviceProviders = failure(userErrorFrom(error));
    }
  }

  async createForwardingRule(): Promise<void> {
    if (!this.isInfoComplete) {
      return;
    }

    try {
      this.createForwardingRuleCall = pending;

      const mqttDestination = new MqttDestination();
      mqttDestination.setBroker(this.mqtt.broker);
      mqttDestination.setTls(this.mqtt.tls);

      if (this.mqtt.hearbeatTopic) {
        mqttDestination.setHeartbeatTopic(this.mqtt.hearbeatTopic);
      }

      if (this.mqtt.username) {
        mqttDestination.setUsername(this.mqtt.username);
      }

      if (this.mqtt.password) {
        mqttDestination.setPassword(this.mqtt.password);
      }

      if (this.mqtt.dynamicTopic && this.mqtt.topicDynamicPart !== null) {
        const dynamicTopic = new DynamicTopic();
        dynamicTopic.setBase(this.mqtt.topic);
        dynamicTopic.setDynamicPart(this.mqtt.topicDynamicPart);

        mqttDestination.setDynamicTopic(dynamicTopic);
      } else {
        mqttDestination.setStaticTopic(this.mqtt.topic);
      }

      const forwardingDestination = new ForwardingDestination();
      forwardingDestination.setName(this.name);
      forwardingDestination.setFormat(this.selectedFormat ?? ForwardingDestination.Format.E3);

      if (this.selectedForwardingType === "mqtt") {
        forwardingDestination.setMqttDestination(mqttDestination);
      }

      const rule = new ForwardingRule();
      rule.setMeasurementsList(this.selectedMeasurements);
      rule.setDeviceProvidersList(this.selectedProviders);
      rule.setDestination(forwardingDestination);

      if (this.partner.data) rule.setPartnerId(this.partner.data.getId());

      const request = new AddForwardingRuleRequest();
      request.setRule(rule);

      await readingsServiceClient.addForwardingRule(request, {});

      this.createForwardingRuleCall = success(void 0);

      this.$emit("rule-created");
    } catch (error) {
      this.createForwardingRuleCall = failure(userErrorFrom(error));
    }
  }
}
