
import { devicesModule } from "@/store/modules/devices";
import { RemoteData, map } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ChargingAction, ChargingActionType } from "zaehlerfreunde-proto-types/automations_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class ChargingActionComponent extends Vue {
  @Prop()
  chargingAction: ChargingAction;

  @Prop()
  editing: boolean;

  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  selectedChargingDevice: Device | null = null;
  selectedChargingAction: ChargingActionType = ChargingActionType.START_CHARGING;

  @Watch("selectedChargingDevice")
  onDeviceSelectionChange(): void {
    this.chargingAction?.setDeviceId(this.selectedChargingDevice?.getId() ?? "");
    this.chargingAction.setActionType(this.selectedChargingAction);
    this.$emit("valid", this.selectedChargingDevice !== null);
  }

  mounted() {
    this.selectedChargingDevice =
      this.devices.list.find((val) => val.getId() === this.chargingAction.getDeviceId()) ?? null;
    this.selectedChargingAction = this.chargingAction.getActionType();
  }

  get chargingDevices(): RemoteData<UserError, Device[]> {
    return map(this.devices, (devices) =>
      devices.filter((d) => d.getProvider() === Device.Provider.OCPP || d.getProvider() == Device.Provider.TESLA)
    );
  }

  chargingActionLabel(actionType: ChargingActionType): string {
    switch (actionType) {
      case ChargingActionType.START_CHARGING:
        return "Starten";
      case ChargingActionType.STOP_CHARGING:
        return "Beenden";
      default:
        return "";
    }
  }

  onChargingActionType(chargingActionType: ChargingActionType): void {
    this.chargingAction.setActionType(chargingActionType);
  }

  chargingActions = [
    {
      label: this.chargingActionLabel(ChargingActionType.START_CHARGING),
      value: ChargingActionType.START_CHARGING,
    },
    {
      label: this.chargingActionLabel(ChargingActionType.STOP_CHARGING),
      value: ChargingActionType.STOP_CHARGING,
    },
  ];
}
