
import { deviceServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetPartnerDevicesRequest, ImportReadingsRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Direction, Unit, Measurement } from "zaehlerfreunde-proto-types/device_reading_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class CsvImport extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  @Prop() value: boolean;

  devices: RemoteData<UserError, Device[]> = initialized;
  importReadingsCall: RemoteCall<UserError> = initialized;

  selectedDeviceId: string | null = null;
  searchFilter: string = "";

  csvFile: File | null = null;
  sampleCSVLine: string = "";
  showTimeIntervalSelection: boolean = false;
  separator = ";";

  columnTypesMapping: { [index: number]: "datetime" | "total" | "amount" } = {};

  columnTypes: { label: string; type: "datetime" | "total" | "amount" }[] = [
    {
      label: "Zeitpunkt",
      type: "datetime",
    },
    {
      label: "Zählerstand",
      type: "total",
    },
    {
      label: "Lastgang",
      type: "amount",
    },
  ];

  dateFormat: string | null = null;
  timeFormat: string | null = null;
  timeInterval: number | null = null;
  indexMeasurement: Measurement | null;

  dateFormats: string[] = ["02 Jan 06", "2/1/2006", "02/01/2006", "2/1/06", "02/01/06", "2006-01-02", "02.01.2006"];
  timeFormats: string[] = ["15:04", "15:04:05"];

  direction: Direction | null = null;
  unit: Unit | null = null;
  timeIntervals: number[] = [5, 10, 15, 20, 30, 60];

  directions: { label: string; value: Direction }[] = [
    { label: "Verbrauch", value: Direction.IN },
    { label: "Einspeisung", value: Direction.OUT },
  ];

  units: { label: string; value: Unit }[] = [
    { label: "kWh", value: Unit.KILO_WATT_HOUR },
    { label: "m3", value: Unit.CUBIC_METRE },
    { label: "kW", value: Unit.KILO_WATT },
    { label: "W", value: Unit.WATT },
  ];

  get sampleCSVLineColumns(): string[] {
    return this.sampleCSVLine.split(this.separator);
  }

  @Watch("value")
  onValueChanged(): void {
    this.$emit("input", this.value);
  }

  @Watch("columnTypesMapping", { deep: true })
  onColumnTypesMappingUpdated(): void {
    if (Object.keys(this.columnTypesMapping).some((v) => this.columnTypesMapping[v] === "amount")) {
      this.showTimeIntervalSelection = true;
      this.restoreUnits();
    } else if (Object.keys(this.columnTypesMapping).some((v) => this.columnTypesMapping[v] === "total")) {
      this.showTimeIntervalSelection = false;
      this.units.splice(2);
    } else {
      this.showTimeIntervalSelection = false;
      this.restoreUnits();
    }
  }

  @Watch("csvFile")
  async onCsvFileChanged(): Promise<void> {
    this.columnTypesMapping = {};
    const csvFile = this.csvFile;

    if (csvFile) {
      const fileContent = (await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", (e) => resolve(e.target?.result as string));
        reader.addEventListener("error", reject);
        reader.readAsText(csvFile);
      })) as string;

      const lines = fileContent.split("\n");
      if (lines.length > 2) {
        this.sampleCSVLine = lines[0];
      }
    } else {
      this.sampleCSVLine = "";
    }
  }

  get deviceSelectionItems(): { id: string; label: string }[] {
    return this.devices.list.map((d) => ({
      id: d.getId(),
      label: d.getName(),
    }));
  }

  shouldDisable(i: number): boolean {
    const numberOfProperties = Object.keys(this.columnTypesMapping).length;
    for (var j = 0; j <= numberOfProperties; j++) {
      for (var k = 0; k <= numberOfProperties; k++) {
        if (
          ((this.columnTypesMapping[j] as string) == "datetime" &&
            (this.columnTypesMapping[k] as string) == "amount") ||
          ((this.columnTypesMapping[j] as string) == "datetime" && (this.columnTypesMapping[k] as string) == "total")
        ) {
          if (i == j || i == k) {
            return false;
          }

          return i !== j && i !== k;
        }
      }
    }

    return false;
  }

  restoreUnits() {
    this.units = [
      { label: "kWh", value: Unit.KILO_WATT_HOUR },
      { label: "m3", value: Unit.CUBIC_METRE },
      { label: "kW", value: Unit.KILO_WATT },
      { label: "W", value: Unit.WATT },
    ];
  }

  get indexTime(): number | null {
    const index = Object.keys(this.columnTypesMapping).find((i) => this.columnTypesMapping[i] === "datetime");
    if (index) {
      return parseInt(index);
    } else {
      return null;
    }
  }

  get indexValue(): number | null {
    const index = Object.keys(this.columnTypesMapping).find(
      (i) => this.columnTypesMapping[i] === "total" || this.columnTypesMapping[i] === "amount"
    );
    if (index) {
      return parseInt(index);
    } else {
      return null;
    }
  }

  get isDataComplete(): boolean {
    return !!(
      this.selectedDeviceId &&
      this.csvFile &&
      this.direction !== null &&
      this.dateFormat &&
      this.timeFormat &&
      this.indexTime !== null &&
      this.indexValue !== null &&
      this.unit !== null &&
      ((this.showTimeIntervalSelection && this.timeInterval !== null) || !this.showTimeIntervalSelection)
    );
  }

  @Watch("searchFilter")
  async onSearchFilterChanged(): Promise<void> {
    if (this.selectedDeviceId) {
      return;
    }
    await this.loadPartnerDevices();
  }

  async loadPartnerDevices(): Promise<void> {
    try {
      this.devices = pending;

      const request = new GetPartnerDevicesRequest();
      request.setPage(0);
      request.setPageSize(30);
      request.setSearchFilter(this.searchFilter);

      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      const response = await deviceServiceClient.getPartnerDevices(request, {});

      this.devices = success(response.getDevicesList());
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  async startImport(): Promise<void> {
    const csvFile = this.csvFile;
    const direction = this.direction;
    const dateFormat = this.dateFormat;
    const timeFormat = this.timeFormat;
    const deviceId = this.selectedDeviceId;
    const indexTime = this.indexTime;
    const indexValue = this.indexValue;
    const unit = this.unit;
    const timeInterval = this.timeInterval;

    if (
      !deviceId ||
      !csvFile ||
      direction === null ||
      !dateFormat ||
      !timeFormat ||
      indexTime === null ||
      indexValue === null ||
      unit === null ||
      (this.showTimeIntervalSelection && timeInterval === null)
    ) {
      return;
    }

    const fileContent = await new Uint8Array(
      await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", (e) => resolve(e.target?.result as Iterable<number>));
        reader.addEventListener("error", reject);
        reader.readAsArrayBuffer(csvFile);
      })
    );

    try {
      this.importReadingsCall = pending;
      const request = new ImportReadingsRequest();
      request.setFileFormat(ImportReadingsRequest.FileFormat.CSV);
      request.setMeasurement(this.columnTypesMapping[indexValue] === "total" ? Measurement.TOTAL : Measurement.AMOUNT);
      request.setDirection(direction);
      request.setTimeFormat(`${dateFormat} ${timeFormat}`);
      request.setUnit(unit);
      request.setFileContent(fileContent);
      request.setDeviceId(deviceId);
      request.setIndexTime(indexTime);
      request.setIndexValue(indexValue);
      request.setTimeIntervalInMinutes(timeInterval ?? 0);
      request.setCsvDelimiter(this.separator);

      await deviceServiceClient.importReadings(request, {});

      this.importReadingsCall = success(void 0);
      this.value = false;
    } catch (error) {
      this.importReadingsCall = failure(userErrorFrom(error));
    }
  }
}
