
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { GetTreeSelectResponse } from "zaehlerfreunde-central/ui_service_pb";
import { TreeSelectionNode, toTreeSelectionNode } from "@/utils/proto-utils";
import { userServiceClient } from "@/config/service-clients";
import { UpdatePartnerRequest } from "zaehlerfreunde-central/user_service_pb";
import { paths } from "@/router/routes";

@Component({
  components: { Treeselect },
})
export default class SwitchChildPartnerDialog extends Vue {
  @Prop() partner: Partner;
  @Prop({ default: [] }) childPartnersNodes: GetTreeSelectResponse.Node[];
  @Prop({ default: null }) userId: string | null;

  switchPartnerCall: RemoteCall<UserError> = initialized;
  selectedChildPartnerNode: TreeSelectionNode = {
    id: "",
    label: "",
  };

  mounted(): void {
    this.selectedChildPartnerNode = {
      id: this.partner.getId(),
      label: this.partner.getName(),
      children: this.childPartnersNodes.map((node) => toTreeSelectionNode(node)),
    };
  }

  get options(): TreeSelectionNode[] {
    return this.childPartnersNodes.map((node) => toTreeSelectionNode(node));
  }

  async switchPartner(): Promise<void> {
    this.switchPartnerCall = pending;
    const request = new UpdatePartnerRequest();
    request.setPartnerId(this.selectedChildPartnerNode.id);
    if (this.userId) {
      request.setUserId(this.userId);
    }
    try {
      await userServiceClient.updatePartner(request, {});
      this.switchPartnerCall = success(void 0);
    } catch (error) {
      this.switchPartnerCall = failure(userErrorFrom(error));
    }

    if (this.switchPartnerCall.succeeded) {
      if (this.userId) {
        this.$router.push({
          path: paths.admin.users,
        });
      } else {
        window.location.reload();
      }
    }
  }
}
