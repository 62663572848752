
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { paths } from "@/router/routes";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { spaceServiceClient, uiServiceClient } from "@/config/service-clients";
import SpaceDetails from "./SpaceDetails.vue";
import SpaceDevices from "./SpaceDevices.vue";
import SpaceUsers from "./SpaceUsers.vue";
import SpaceInvites from "./SpaceInvites.vue";
import DeleteSpaceDialog from "../../../../components/delete-space/DeleteSpaceDialog.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

import { GetAvailableFeaturesRequest } from "zaehlerfreunde-central/ui_service_pb";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import SpaceTariffs from "./SpaceTariffs.vue";

@Component({
  components: {
    SpaceDetails,
    SpaceDevices,
    SpaceUsers,
    SpaceInvites,
    DeleteSpaceDialog,
    SpaceTariffs,
  },
})
export default class SpaceProfile extends Vue {
  deleteSpaceCall: RemoteCall<UserError> = initialized;
  selectedTab: number = 0;
  spaceInfo: RemoteData<UserError, Space> = initialized;
  userIds: string[] = [];
  showDeleteSpaceDialog: boolean = false;
  features: RemoteData<UserError, Feature[]> = initialized;

  spaceId: string | null = null;
  itemsPerPage = 10;
  paths = paths;

  get spaceCanHavetariff(): boolean {
    return this.features.list.some(
      (f) => f === Feature.ENERGY_TARIFF || f === Feature.WATER_TARIFF || f === Feature.GAS_TARIFF
    );
  }

  @Watch("selectedTab")
  onSelectedTabChanged() {
    this.$router.replace({
      path: this.$route.path,
      query: {
        tab: this.selectedTab.toString(),
      },
    });
  }

  mounted(): void {
    const tab = this.$route.query["tab"] as string;

    if (tab) {
      this.selectedTab = parseInt(tab);
    }

    this.loadSpaceData();
    this.getAvailableFeaturesForSpace();
  }
  goBackToSpaces() {
    this.$router.go(-1);
  }

  async loadSpaceData(): Promise<void> {
    this.spaceId = this.$route.params.spaceId;
    const request = new GetSpaceRequest();
    request.setSpaceId(this.spaceId);
    try {
      const response = await spaceServiceClient.getSpace(request, {});
      const space = response.getSpace();
      if (space) {
        this.spaceInfo = success(space);

        if (this.spaceInfo.data?.getAssociatedUsersIdsList()) {
          this.userIds = this.spaceInfo.data?.getAssociatedUsersIdsList();
        }
      }
    } catch (error) {
      this.spaceInfo = failure(userErrorFrom(error));
    }
  }

  async getAvailableFeaturesForSpace() {
    try {
      this.features = pending;

      const request = new GetAvailableFeaturesRequest();
      if (this.spaceId) {
        request.setSpaceId(this.spaceId);
      }

      const response = await uiServiceClient.getAvailableFeatures(request, {});
      this.features = success(response.getFeaturesList());
    } catch (error) {
      this.features = failure(userErrorFrom(error));
    }
  }

  updateList(newUsersList: string[]): void {
    this.userIds = newUsersList;
  }

  spaceUpdate() {
    this.loadSpaceData();
  }
}
