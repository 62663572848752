
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import spaces from "@/store/modules/spaces";
import {
  DeviceRegistration,
  ExternalRegistrationDetails,
  WattlineRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { getMediumIcon } from "@/utils/device-utils";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {},
})
export default class ExternalDeviceConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  meloId: string = "";
  serialNumber: string = "";
  externalDeviceId: string = "";

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  onContinueClicked(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(Device.Provider.EXTERNAL);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(spaces.adminSelectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new ExternalRegistrationDetails();
    details.setExternalDeviceIdsList([this.externalDeviceId]);

    if (this.meloId) {
      details.setMeteringLocationId(this.meloId);
    }
    if (this.serialNumber) {
      details.setSerialNumber(this.serialNumber);
    }

    registration.setExternal(details);

    devices.registerDevice(registration);
  }

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }
}
