// source: services/central/ui_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_datetime_pb = require('../../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var types_time_data_pb = require('../../types/time_data_pb.js');
goog.object.extend(proto, types_time_data_pb);
var types_device_reading_pb = require('../../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
var types_dashboard_layout_pb = require('../../types/dashboard_layout_pb.js');
goog.object.extend(proto, types_dashboard_layout_pb);
var types_features_pb = require('../../types/features_pb.js');
goog.object.extend(proto, types_features_pb);
var types_automations_pb = require('../../types/automations_pb.js');
goog.object.extend(proto, types_automations_pb);
var types_partners_pb = require('../../types/partners_pb.js');
goog.object.extend(proto, types_partners_pb);
goog.exportSymbol('proto.services.central.AdvancePaymentWidgetData', null, global);
goog.exportSymbol('proto.services.central.AdvancePaymentWidgetData.ChartData', null, global);
goog.exportSymbol('proto.services.central.BarChartData', null, global);
goog.exportSymbol('proto.services.central.BarChartData.Dataset', null, global);
goog.exportSymbol('proto.services.central.ChartAggregate', null, global);
goog.exportSymbol('proto.services.central.ChartConfig', null, global);
goog.exportSymbol('proto.services.central.ChartData', null, global);
goog.exportSymbol('proto.services.central.ChartData.DataCase', null, global);
goog.exportSymbol('proto.services.central.ChartData.Tab', null, global);
goog.exportSymbol('proto.services.central.ChartData.Tab.Type', null, global);
goog.exportSymbol('proto.services.central.ChartType', null, global);
goog.exportSymbol('proto.services.central.CurrentMonthWidgetData', null, global);
goog.exportSymbol('proto.services.central.CurrentMonthWidgetData.Delta', null, global);
goog.exportSymbol('proto.services.central.CurrentMonthWidgetData.Delta.Tendency', null, global);
goog.exportSymbol('proto.services.central.CurrentMonthWidgetData.Value', null, global);
goog.exportSymbol('proto.services.central.DeleteDashboardItemRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteDashboardItemResponse', null, global);
goog.exportSymbol('proto.services.central.DeviceReadingsTableRequest', null, global);
goog.exportSymbol('proto.services.central.GetAdvancePaymentWidgetDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetAdvancePaymentWidgetDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDescriptionsRequest', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDescriptionsResponse', null, global);
goog.exportSymbol('proto.services.central.GetAutomationWidgetDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetAutomationWidgetDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetAvailableFeaturesRequest', null, global);
goog.exportSymbol('proto.services.central.GetAvailableFeaturesResponse', null, global);
goog.exportSymbol('proto.services.central.GetCurrentMonthWidgetDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetCurrentMonthWidgetDataRequest.DataType', null, global);
goog.exportSymbol('proto.services.central.GetCurrentMonthWidgetDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetDashboardChartDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetDashboardChartDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetDashboardLayoutRequest', null, global);
goog.exportSymbol('proto.services.central.GetDashboardLayoutResponse', null, global);
goog.exportSymbol('proto.services.central.GetMinimumAppVersionRequest', null, global);
goog.exportSymbol('proto.services.central.GetMinimumAppVersionResponse', null, global);
goog.exportSymbol('proto.services.central.GetSelectedSpaceRequest', null, global);
goog.exportSymbol('proto.services.central.GetSelectedSpaceResponse', null, global);
goog.exportSymbol('proto.services.central.GetSpaceComparisonWidgetDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand', null, global);
goog.exportSymbol('proto.services.central.GetSpaceComparisonWidgetDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataRequest', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataRequest.TabularRequestCase', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataResponse', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataResponse.Row', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataResponse.Row.RowData', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataResponse.Row.RowData.DataCase', null, global);
goog.exportSymbol('proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow', null, global);
goog.exportSymbol('proto.services.central.GetTreeSelectRequest', null, global);
goog.exportSymbol('proto.services.central.GetTreeSelectRequest.RequestCase', null, global);
goog.exportSymbol('proto.services.central.GetTreeSelectResponse', null, global);
goog.exportSymbol('proto.services.central.GetTreeSelectResponse.Node', null, global);
goog.exportSymbol('proto.services.central.LineChartData', null, global);
goog.exportSymbol('proto.services.central.LineChartData.DataPoint', null, global);
goog.exportSymbol('proto.services.central.LineChartData.Dataset', null, global);
goog.exportSymbol('proto.services.central.LineChartData.LineAnnotation', null, global);
goog.exportSymbol('proto.services.central.MixedChartData', null, global);
goog.exportSymbol('proto.services.central.MoveDashboardItemRequest', null, global);
goog.exportSymbol('proto.services.central.MoveDashboardItemResponse', null, global);
goog.exportSymbol('proto.services.central.PartnerTreeSelectRequest', null, global);
goog.exportSymbol('proto.services.central.ResetDashboardLayoutRequest', null, global);
goog.exportSymbol('proto.services.central.ResetDashboardLayoutResponse', null, global);
goog.exportSymbol('proto.services.central.SetSelectedSpaceRequest', null, global);
goog.exportSymbol('proto.services.central.SetSelectedSpaceResponse', null, global);
goog.exportSymbol('proto.services.central.SpaceComparisonWidgetData', null, global);
goog.exportSymbol('proto.services.central.SpaceComparisonWidgetData.ComparisonResult', null, global);
goog.exportSymbol('proto.services.central.SpaceComparisonWidgetData.ComparisonValue', null, global);
goog.exportSymbol('proto.services.central.SpaceComparisonWidgetData.TimePeriodTab', null, global);
goog.exportSymbol('proto.services.central.SpaceTreeSelectRequest', null, global);
goog.exportSymbol('proto.services.central.StackedBarChartData', null, global);
goog.exportSymbol('proto.services.central.StackedBarChartData.BarData', null, global);
goog.exportSymbol('proto.services.central.TabularData', null, global);
goog.exportSymbol('proto.services.central.TabularData.Row', null, global);
goog.exportSymbol('proto.services.central.TimeRange', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSelectedSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSelectedSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSelectedSpaceRequest.displayName = 'proto.services.central.GetSelectedSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSelectedSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSelectedSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSelectedSpaceResponse.displayName = 'proto.services.central.GetSelectedSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetSelectedSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetSelectedSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetSelectedSpaceRequest.displayName = 'proto.services.central.SetSelectedSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetSelectedSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetSelectedSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetSelectedSpaceResponse.displayName = 'proto.services.central.SetSelectedSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMinimumAppVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMinimumAppVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMinimumAppVersionRequest.displayName = 'proto.services.central.GetMinimumAppVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMinimumAppVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMinimumAppVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMinimumAppVersionResponse.displayName = 'proto.services.central.GetMinimumAppVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.CurrentMonthWidgetData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.CurrentMonthWidgetData.repeatedFields_, null);
};
goog.inherits(proto.services.central.CurrentMonthWidgetData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.CurrentMonthWidgetData.displayName = 'proto.services.central.CurrentMonthWidgetData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.CurrentMonthWidgetData.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.CurrentMonthWidgetData.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.CurrentMonthWidgetData.Value.displayName = 'proto.services.central.CurrentMonthWidgetData.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.CurrentMonthWidgetData.Delta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.CurrentMonthWidgetData.Delta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.CurrentMonthWidgetData.Delta.displayName = 'proto.services.central.CurrentMonthWidgetData.Delta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentMonthWidgetDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCurrentMonthWidgetDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentMonthWidgetDataRequest.displayName = 'proto.services.central.GetCurrentMonthWidgetDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentMonthWidgetDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCurrentMonthWidgetDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentMonthWidgetDataResponse.displayName = 'proto.services.central.GetCurrentMonthWidgetDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDashboardChartDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDashboardChartDataRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDashboardChartDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDashboardChartDataRequest.displayName = 'proto.services.central.GetDashboardChartDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDashboardChartDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDashboardChartDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDashboardChartDataResponse.displayName = 'proto.services.central.GetDashboardChartDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ChartData.repeatedFields_, proto.services.central.ChartData.oneofGroups_);
};
goog.inherits(proto.services.central.ChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ChartData.displayName = 'proto.services.central.ChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ChartData.Tab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ChartData.Tab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ChartData.Tab.displayName = 'proto.services.central.ChartData.Tab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ChartConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ChartConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ChartConfig.displayName = 'proto.services.central.ChartConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TimeRange.displayName = 'proto.services.central.TimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MixedChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MixedChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MixedChartData.displayName = 'proto.services.central.MixedChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LineChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.LineChartData.repeatedFields_, null);
};
goog.inherits(proto.services.central.LineChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LineChartData.displayName = 'proto.services.central.LineChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LineChartData.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.LineChartData.Dataset.repeatedFields_, null);
};
goog.inherits(proto.services.central.LineChartData.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LineChartData.Dataset.displayName = 'proto.services.central.LineChartData.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LineChartData.DataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LineChartData.DataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LineChartData.DataPoint.displayName = 'proto.services.central.LineChartData.DataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LineChartData.LineAnnotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LineChartData.LineAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LineChartData.LineAnnotation.displayName = 'proto.services.central.LineChartData.LineAnnotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.BarChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.BarChartData.repeatedFields_, null);
};
goog.inherits(proto.services.central.BarChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.BarChartData.displayName = 'proto.services.central.BarChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.BarChartData.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.BarChartData.Dataset.repeatedFields_, null);
};
goog.inherits(proto.services.central.BarChartData.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.BarChartData.Dataset.displayName = 'proto.services.central.BarChartData.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ChartAggregate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ChartAggregate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ChartAggregate.displayName = 'proto.services.central.ChartAggregate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TabularData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.TabularData.repeatedFields_, null);
};
goog.inherits(proto.services.central.TabularData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TabularData.displayName = 'proto.services.central.TabularData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TabularData.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.TabularData.Row.repeatedFields_, null);
};
goog.inherits(proto.services.central.TabularData.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TabularData.Row.displayName = 'proto.services.central.TabularData.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StackedBarChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.StackedBarChartData.repeatedFields_, null);
};
goog.inherits(proto.services.central.StackedBarChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StackedBarChartData.displayName = 'proto.services.central.StackedBarChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StackedBarChartData.BarData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.StackedBarChartData.BarData.repeatedFields_, null);
};
goog.inherits(proto.services.central.StackedBarChartData.BarData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StackedBarChartData.BarData.displayName = 'proto.services.central.StackedBarChartData.BarData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAdvancePaymentWidgetDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAdvancePaymentWidgetDataRequest.displayName = 'proto.services.central.GetAdvancePaymentWidgetDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAdvancePaymentWidgetDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAdvancePaymentWidgetDataResponse.displayName = 'proto.services.central.GetAdvancePaymentWidgetDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AdvancePaymentWidgetData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AdvancePaymentWidgetData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AdvancePaymentWidgetData.displayName = 'proto.services.central.AdvancePaymentWidgetData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AdvancePaymentWidgetData.ChartData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.AdvancePaymentWidgetData.ChartData.repeatedFields_, null);
};
goog.inherits(proto.services.central.AdvancePaymentWidgetData.ChartData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AdvancePaymentWidgetData.ChartData.displayName = 'proto.services.central.AdvancePaymentWidgetData.ChartData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSpaceComparisonWidgetDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSpaceComparisonWidgetDataRequest.displayName = 'proto.services.central.GetSpaceComparisonWidgetDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSpaceComparisonWidgetDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSpaceComparisonWidgetDataResponse.displayName = 'proto.services.central.GetSpaceComparisonWidgetDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SpaceComparisonWidgetData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.SpaceComparisonWidgetData.repeatedFields_, null);
};
goog.inherits(proto.services.central.SpaceComparisonWidgetData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SpaceComparisonWidgetData.displayName = 'proto.services.central.SpaceComparisonWidgetData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SpaceComparisonWidgetData.ComparisonValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SpaceComparisonWidgetData.ComparisonValue.displayName = 'proto.services.central.SpaceComparisonWidgetData.ComparisonValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SpaceComparisonWidgetData.TimePeriodTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.displayName = 'proto.services.central.SpaceComparisonWidgetData.TimePeriodTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTabularDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetTabularDataRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetTabularDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTabularDataRequest.displayName = 'proto.services.central.GetTabularDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeviceReadingsTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeviceReadingsTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeviceReadingsTableRequest.displayName = 'proto.services.central.DeviceReadingsTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTabularDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTabularDataResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTabularDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTabularDataResponse.displayName = 'proto.services.central.GetTabularDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTabularDataResponse.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTabularDataResponse.Row.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTabularDataResponse.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTabularDataResponse.Row.displayName = 'proto.services.central.GetTabularDataResponse.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTabularDataResponse.Row.RowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetTabularDataResponse.Row.RowData.oneofGroups_);
};
goog.inherits(proto.services.central.GetTabularDataResponse.Row.RowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTabularDataResponse.Row.RowData.displayName = 'proto.services.central.GetTabularDataResponse.Row.RowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.displayName = 'proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDescriptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAutomationDescriptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDescriptionsRequest.displayName = 'proto.services.central.GetAutomationDescriptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDescriptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAutomationDescriptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAutomationDescriptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDescriptionsResponse.displayName = 'proto.services.central.GetAutomationDescriptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDashboardLayoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDashboardLayoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDashboardLayoutRequest.displayName = 'proto.services.central.GetDashboardLayoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDashboardLayoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDashboardLayoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDashboardLayoutResponse.displayName = 'proto.services.central.GetDashboardLayoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ResetDashboardLayoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ResetDashboardLayoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ResetDashboardLayoutRequest.displayName = 'proto.services.central.ResetDashboardLayoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ResetDashboardLayoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ResetDashboardLayoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ResetDashboardLayoutResponse.displayName = 'proto.services.central.ResetDashboardLayoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MoveDashboardItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MoveDashboardItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MoveDashboardItemRequest.displayName = 'proto.services.central.MoveDashboardItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MoveDashboardItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MoveDashboardItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MoveDashboardItemResponse.displayName = 'proto.services.central.MoveDashboardItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteDashboardItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteDashboardItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteDashboardItemRequest.displayName = 'proto.services.central.DeleteDashboardItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteDashboardItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteDashboardItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteDashboardItemResponse.displayName = 'proto.services.central.DeleteDashboardItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAvailableFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAvailableFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAvailableFeaturesRequest.displayName = 'proto.services.central.GetAvailableFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAvailableFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAvailableFeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAvailableFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAvailableFeaturesResponse.displayName = 'proto.services.central.GetAvailableFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTreeSelectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetTreeSelectRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetTreeSelectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTreeSelectRequest.displayName = 'proto.services.central.GetTreeSelectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.PartnerTreeSelectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.PartnerTreeSelectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.PartnerTreeSelectRequest.displayName = 'proto.services.central.PartnerTreeSelectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SpaceTreeSelectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SpaceTreeSelectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SpaceTreeSelectRequest.displayName = 'proto.services.central.SpaceTreeSelectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTreeSelectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTreeSelectResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTreeSelectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTreeSelectResponse.displayName = 'proto.services.central.GetTreeSelectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTreeSelectResponse.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTreeSelectResponse.Node.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTreeSelectResponse.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTreeSelectResponse.Node.displayName = 'proto.services.central.GetTreeSelectResponse.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationWidgetDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAutomationWidgetDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationWidgetDataRequest.displayName = 'proto.services.central.GetAutomationWidgetDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationWidgetDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAutomationWidgetDataResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAutomationWidgetDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationWidgetDataResponse.displayName = 'proto.services.central.GetAutomationWidgetDataResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSelectedSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSelectedSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSelectedSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSelectedSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSelectedSpaceRequest}
 */
proto.services.central.GetSelectedSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSelectedSpaceRequest;
  return proto.services.central.GetSelectedSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSelectedSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSelectedSpaceRequest}
 */
proto.services.central.GetSelectedSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSelectedSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSelectedSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSelectedSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSelectedSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSelectedSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSelectedSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSelectedSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSelectedSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSelectedSpaceResponse}
 */
proto.services.central.GetSelectedSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSelectedSpaceResponse;
  return proto.services.central.GetSelectedSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSelectedSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSelectedSpaceResponse}
 */
proto.services.central.GetSelectedSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSelectedSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSelectedSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSelectedSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSelectedSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetSelectedSpaceResponse.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSelectedSpaceResponse} returns this
 */
proto.services.central.GetSelectedSpaceResponse.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetSelectedSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetSelectedSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetSelectedSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetSelectedSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetSelectedSpaceRequest}
 */
proto.services.central.SetSelectedSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetSelectedSpaceRequest;
  return proto.services.central.SetSelectedSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetSelectedSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetSelectedSpaceRequest}
 */
proto.services.central.SetSelectedSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetSelectedSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetSelectedSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetSelectedSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetSelectedSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SetSelectedSpaceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetSelectedSpaceRequest} returns this
 */
proto.services.central.SetSelectedSpaceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetSelectedSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetSelectedSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetSelectedSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetSelectedSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetSelectedSpaceResponse}
 */
proto.services.central.SetSelectedSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetSelectedSpaceResponse;
  return proto.services.central.SetSelectedSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetSelectedSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetSelectedSpaceResponse}
 */
proto.services.central.SetSelectedSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetSelectedSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetSelectedSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetSelectedSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetSelectedSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMinimumAppVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMinimumAppVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMinimumAppVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMinimumAppVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMinimumAppVersionRequest}
 */
proto.services.central.GetMinimumAppVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMinimumAppVersionRequest;
  return proto.services.central.GetMinimumAppVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMinimumAppVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMinimumAppVersionRequest}
 */
proto.services.central.GetMinimumAppVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMinimumAppVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMinimumAppVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMinimumAppVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMinimumAppVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMinimumAppVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMinimumAppVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMinimumAppVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMinimumAppVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    minimumAppVersion: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMinimumAppVersionResponse}
 */
proto.services.central.GetMinimumAppVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMinimumAppVersionResponse;
  return proto.services.central.GetMinimumAppVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMinimumAppVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMinimumAppVersionResponse}
 */
proto.services.central.GetMinimumAppVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinimumAppVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMinimumAppVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMinimumAppVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMinimumAppVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMinimumAppVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinimumAppVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string minimum_app_version = 1;
 * @return {string}
 */
proto.services.central.GetMinimumAppVersionResponse.prototype.getMinimumAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMinimumAppVersionResponse} returns this
 */
proto.services.central.GetMinimumAppVersionResponse.prototype.setMinimumAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.CurrentMonthWidgetData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.CurrentMonthWidgetData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.CurrentMonthWidgetData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.CurrentMonthWidgetData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.services.central.CurrentMonthWidgetData.Value.toObject, includeInstance),
    delta: (f = msg.getDelta()) && proto.services.central.CurrentMonthWidgetData.Delta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.CurrentMonthWidgetData}
 */
proto.services.central.CurrentMonthWidgetData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.CurrentMonthWidgetData;
  return proto.services.central.CurrentMonthWidgetData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.CurrentMonthWidgetData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.CurrentMonthWidgetData}
 */
proto.services.central.CurrentMonthWidgetData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.services.central.CurrentMonthWidgetData.Value;
      reader.readMessage(value,proto.services.central.CurrentMonthWidgetData.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = new proto.services.central.CurrentMonthWidgetData.Delta;
      reader.readMessage(value,proto.services.central.CurrentMonthWidgetData.Delta.deserializeBinaryFromReader);
      msg.setDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.CurrentMonthWidgetData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.CurrentMonthWidgetData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.CurrentMonthWidgetData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.CurrentMonthWidgetData.Value.serializeBinaryToWriter
    );
  }
  f = message.getDelta();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.CurrentMonthWidgetData.Delta.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.CurrentMonthWidgetData.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.CurrentMonthWidgetData.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valueFormatted: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tooltip: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.CurrentMonthWidgetData.Value}
 */
proto.services.central.CurrentMonthWidgetData.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.CurrentMonthWidgetData.Value;
  return proto.services.central.CurrentMonthWidgetData.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.CurrentMonthWidgetData.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.CurrentMonthWidgetData.Value}
 */
proto.services.central.CurrentMonthWidgetData.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFormatted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTooltip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.CurrentMonthWidgetData.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.CurrentMonthWidgetData.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getValueFormatted();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string value_formatted = 5;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.getValueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.setValueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tooltip = 4;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.getTooltip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.setTooltip = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.CurrentMonthWidgetData.Value} returns this
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.clearTooltip = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.CurrentMonthWidgetData.Value.prototype.hasTooltip = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.CurrentMonthWidgetData.Delta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.CurrentMonthWidgetData.Delta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.Delta.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tendency: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tooltip: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta}
 */
proto.services.central.CurrentMonthWidgetData.Delta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.CurrentMonthWidgetData.Delta;
  return proto.services.central.CurrentMonthWidgetData.Delta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.CurrentMonthWidgetData.Delta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta}
 */
proto.services.central.CurrentMonthWidgetData.Delta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {!proto.services.central.CurrentMonthWidgetData.Delta.Tendency} */ (reader.readEnum());
      msg.setTendency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTooltip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.CurrentMonthWidgetData.Delta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.CurrentMonthWidgetData.Delta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CurrentMonthWidgetData.Delta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTendency();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.CurrentMonthWidgetData.Delta.Tendency = {
  GOOD: 0,
  BAD: 1
};

/**
 * optional string icon = 1;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Tendency tendency = 4;
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta.Tendency}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.getTendency = function() {
  return /** @type {!proto.services.central.CurrentMonthWidgetData.Delta.Tendency} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.central.CurrentMonthWidgetData.Delta.Tendency} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.setTendency = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string tooltip = 5;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.getTooltip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.setTooltip = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.CurrentMonthWidgetData.Delta} returns this
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.clearTooltip = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.CurrentMonthWidgetData.Delta.prototype.hasTooltip = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.services.central.CurrentMonthWidgetData.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
 */
proto.services.central.CurrentMonthWidgetData.prototype.setInfo = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
 */
proto.services.central.CurrentMonthWidgetData.prototype.clearInfo = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.CurrentMonthWidgetData.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Value values = 2;
 * @return {!Array<!proto.services.central.CurrentMonthWidgetData.Value>}
 */
proto.services.central.CurrentMonthWidgetData.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.services.central.CurrentMonthWidgetData.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.CurrentMonthWidgetData.Value, 2));
};


/**
 * @param {!Array<!proto.services.central.CurrentMonthWidgetData.Value>} value
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
*/
proto.services.central.CurrentMonthWidgetData.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.CurrentMonthWidgetData.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.CurrentMonthWidgetData.Value}
 */
proto.services.central.CurrentMonthWidgetData.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.CurrentMonthWidgetData.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
 */
proto.services.central.CurrentMonthWidgetData.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional Delta delta = 3;
 * @return {?proto.services.central.CurrentMonthWidgetData.Delta}
 */
proto.services.central.CurrentMonthWidgetData.prototype.getDelta = function() {
  return /** @type{?proto.services.central.CurrentMonthWidgetData.Delta} */ (
    jspb.Message.getWrapperField(this, proto.services.central.CurrentMonthWidgetData.Delta, 3));
};


/**
 * @param {?proto.services.central.CurrentMonthWidgetData.Delta|undefined} value
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
*/
proto.services.central.CurrentMonthWidgetData.prototype.setDelta = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.CurrentMonthWidgetData} returns this
 */
proto.services.central.CurrentMonthWidgetData.prototype.clearDelta = function() {
  return this.setDelta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.CurrentMonthWidgetData.prototype.hasDelta = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentMonthWidgetDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentMonthWidgetDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentMonthWidgetDataRequest;
  return proto.services.central.GetCurrentMonthWidgetDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentMonthWidgetDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.services.central.GetCurrentMonthWidgetDataRequest.DataType} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentMonthWidgetDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentMonthWidgetDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.DataType = {
  CONSUMPTION: 0,
  COST: 1
};

/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest} returns this
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest} returns this
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DataType data_type = 3;
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest.DataType}
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.getDataType = function() {
  return /** @type {!proto.services.central.GetCurrentMonthWidgetDataRequest.DataType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.central.GetCurrentMonthWidgetDataRequest.DataType} value
 * @return {!proto.services.central.GetCurrentMonthWidgetDataRequest} returns this
 */
proto.services.central.GetCurrentMonthWidgetDataRequest.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentMonthWidgetDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentMonthWidgetDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.services.central.CurrentMonthWidgetData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentMonthWidgetDataResponse}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentMonthWidgetDataResponse;
  return proto.services.central.GetCurrentMonthWidgetDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentMonthWidgetDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentMonthWidgetDataResponse}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.CurrentMonthWidgetData;
      reader.readMessage(value,proto.services.central.CurrentMonthWidgetData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentMonthWidgetDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentMonthWidgetDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.CurrentMonthWidgetData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CurrentMonthWidgetData data = 1;
 * @return {?proto.services.central.CurrentMonthWidgetData}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.getData = function() {
  return /** @type{?proto.services.central.CurrentMonthWidgetData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.CurrentMonthWidgetData, 1));
};


/**
 * @param {?proto.services.central.CurrentMonthWidgetData|undefined} value
 * @return {!proto.services.central.GetCurrentMonthWidgetDataResponse} returns this
*/
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCurrentMonthWidgetDataResponse} returns this
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCurrentMonthWidgetDataResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDashboardChartDataRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDashboardChartDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDashboardChartDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardChartDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    tab: jspb.Message.getFieldWithDefault(msg, 6, 0),
    forceTimeWindow: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isMobile: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.services.central.ChartConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDashboardChartDataRequest}
 */
proto.services.central.GetDashboardChartDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDashboardChartDataRequest;
  return proto.services.central.GetDashboardChartDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDashboardChartDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDashboardChartDataRequest}
 */
proto.services.central.GetDashboardChartDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 6:
      var value = /** @type {!proto.services.central.ChartData.Tab.Type} */ (reader.readEnum());
      msg.setTab(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceTimeWindow(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMobile(value);
      break;
    case 9:
      var value = new proto.services.central.ChartConfig;
      reader.readMessage(value,proto.services.central.ChartConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDashboardChartDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDashboardChartDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardChartDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getTab();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getForceTimeWindow();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.services.central.ChartConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.DateTime start_date = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
*/
proto.services.central.GetDashboardChartDataRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_date = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
*/
proto.services.central.GetDashboardChartDataRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ChartData.Tab.Type tab = 6;
 * @return {!proto.services.central.ChartData.Tab.Type}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getTab = function() {
  return /** @type {!proto.services.central.ChartData.Tab.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.services.central.ChartData.Tab.Type} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.setTab = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool force_time_window = 7;
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getForceTimeWindow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.setForceTimeWindow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_mobile = 8;
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getIsMobile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.setIsMobile = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.clearIsMobile = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.hasIsMobile = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ChartConfig configs = 9;
 * @return {!Array<!proto.services.central.ChartConfig>}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.services.central.ChartConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ChartConfig, 9));
};


/**
 * @param {!Array<!proto.services.central.ChartConfig>} value
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
*/
proto.services.central.GetDashboardChartDataRequest.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.services.central.ChartConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ChartConfig}
 */
proto.services.central.GetDashboardChartDataRequest.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.services.central.ChartConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDashboardChartDataRequest} returns this
 */
proto.services.central.GetDashboardChartDataRequest.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDashboardChartDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDashboardChartDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDashboardChartDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardChartDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chartData: (f = msg.getChartData()) && proto.services.central.ChartData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDashboardChartDataResponse}
 */
proto.services.central.GetDashboardChartDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDashboardChartDataResponse;
  return proto.services.central.GetDashboardChartDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDashboardChartDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDashboardChartDataResponse}
 */
proto.services.central.GetDashboardChartDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ChartData;
      reader.readMessage(value,proto.services.central.ChartData.deserializeBinaryFromReader);
      msg.setChartData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDashboardChartDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDashboardChartDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDashboardChartDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardChartDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChartData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.ChartData.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChartData chart_data = 1;
 * @return {?proto.services.central.ChartData}
 */
proto.services.central.GetDashboardChartDataResponse.prototype.getChartData = function() {
  return /** @type{?proto.services.central.ChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.ChartData, 1));
};


/**
 * @param {?proto.services.central.ChartData|undefined} value
 * @return {!proto.services.central.GetDashboardChartDataResponse} returns this
*/
proto.services.central.GetDashboardChartDataResponse.prototype.setChartData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDashboardChartDataResponse} returns this
 */
proto.services.central.GetDashboardChartDataResponse.prototype.clearChartData = function() {
  return this.setChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardChartDataResponse.prototype.hasChartData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ChartData.repeatedFields_ = [4,5,11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.ChartData.oneofGroups_ = [[2,3,6,12]];

/**
 * @enum {number}
 */
proto.services.central.ChartData.DataCase = {
  DATA_NOT_SET: 0,
  LINE_CHART_DATA: 2,
  BAR_CHART_DATA: 3,
  TABULAR_DATA: 6,
  MIXED_CHART_DATA: 12
};

/**
 * @return {proto.services.central.ChartData.DataCase}
 */
proto.services.central.ChartData.prototype.getDataCase = function() {
  return /** @type {proto.services.central.ChartData.DataCase} */(jspb.Message.computeOneofCase(this, proto.services.central.ChartData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    chartType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lineChartData: (f = msg.getLineChartData()) && proto.services.central.LineChartData.toObject(includeInstance, f),
    barChartData: (f = msg.getBarChartData()) && proto.services.central.BarChartData.toObject(includeInstance, f),
    tabularData: (f = msg.getTabularData()) && proto.services.central.TabularData.toObject(includeInstance, f),
    mixedChartData: (f = msg.getMixedChartData()) && proto.services.central.MixedChartData.toObject(includeInstance, f),
    aggregatesList: jspb.Message.toObjectList(msg.getAggregatesList(),
    proto.services.central.ChartAggregate.toObject, includeInstance),
    tabsList: jspb.Message.toObjectList(msg.getTabsList(),
    proto.services.central.ChartData.Tab.toObject, includeInstance),
    isDifferentDateThanRequested: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    availableTimeRange: (f = msg.getAvailableTimeRange()) && proto.services.central.TimeRange.toObject(includeInstance, f),
    hint: jspb.Message.getFieldWithDefault(msg, 9, ""),
    selectedDate: (f = msg.getSelectedDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.services.central.ChartConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ChartData}
 */
proto.services.central.ChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ChartData;
  return proto.services.central.ChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ChartData}
 */
proto.services.central.ChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.services.central.ChartType} */ (reader.readEnum());
      msg.setChartType(value);
      break;
    case 2:
      var value = new proto.services.central.LineChartData;
      reader.readMessage(value,proto.services.central.LineChartData.deserializeBinaryFromReader);
      msg.setLineChartData(value);
      break;
    case 3:
      var value = new proto.services.central.BarChartData;
      reader.readMessage(value,proto.services.central.BarChartData.deserializeBinaryFromReader);
      msg.setBarChartData(value);
      break;
    case 6:
      var value = new proto.services.central.TabularData;
      reader.readMessage(value,proto.services.central.TabularData.deserializeBinaryFromReader);
      msg.setTabularData(value);
      break;
    case 12:
      var value = new proto.services.central.MixedChartData;
      reader.readMessage(value,proto.services.central.MixedChartData.deserializeBinaryFromReader);
      msg.setMixedChartData(value);
      break;
    case 4:
      var value = new proto.services.central.ChartAggregate;
      reader.readMessage(value,proto.services.central.ChartAggregate.deserializeBinaryFromReader);
      msg.addAggregates(value);
      break;
    case 5:
      var value = new proto.services.central.ChartData.Tab;
      reader.readMessage(value,proto.services.central.ChartData.Tab.deserializeBinaryFromReader);
      msg.addTabs(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDifferentDateThanRequested(value);
      break;
    case 8:
      var value = new proto.services.central.TimeRange;
      reader.readMessage(value,proto.services.central.TimeRange.deserializeBinaryFromReader);
      msg.setAvailableTimeRange(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 10:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setSelectedDate(value);
      break;
    case 11:
      var value = new proto.services.central.ChartConfig;
      reader.readMessage(value,proto.services.central.ChartConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChartType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLineChartData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.LineChartData.serializeBinaryToWriter
    );
  }
  f = message.getBarChartData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.BarChartData.serializeBinaryToWriter
    );
  }
  f = message.getTabularData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.central.TabularData.serializeBinaryToWriter
    );
  }
  f = message.getMixedChartData();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.services.central.MixedChartData.serializeBinaryToWriter
    );
  }
  f = message.getAggregatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.services.central.ChartAggregate.serializeBinaryToWriter
    );
  }
  f = message.getTabsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.services.central.ChartData.Tab.serializeBinaryToWriter
    );
  }
  f = message.getIsDifferentDateThanRequested();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAvailableTimeRange();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.services.central.TimeRange.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSelectedDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.services.central.ChartConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ChartData.Tab.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ChartData.Tab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ChartData.Tab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartData.Tab.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ChartData.Tab}
 */
proto.services.central.ChartData.Tab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ChartData.Tab;
  return proto.services.central.ChartData.Tab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ChartData.Tab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ChartData.Tab}
 */
proto.services.central.ChartData.Tab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.services.central.ChartData.Tab.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ChartData.Tab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ChartData.Tab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ChartData.Tab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartData.Tab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.ChartData.Tab.Type = {
  CONSUMPTION: 0,
  COST: 1,
  EMISSION: 2,
  MARKET_DATA: 3,
  METER_READINGS: 4,
  STATE_OF_CHARGE: 6,
  CONSUMPTION_PER_EMPLOYEE: 7,
  CONSUMPTION_PER_SQM: 8,
  LOAD_DURATION: 9
};

/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.ChartData.Tab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartData.Tab} returns this
 */
proto.services.central.ChartData.Tab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.services.central.ChartData.Tab.Type}
 */
proto.services.central.ChartData.Tab.prototype.getType = function() {
  return /** @type {!proto.services.central.ChartData.Tab.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.services.central.ChartData.Tab.Type} value
 * @return {!proto.services.central.ChartData.Tab} returns this
 */
proto.services.central.ChartData.Tab.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ChartType chart_type = 1;
 * @return {!proto.services.central.ChartType}
 */
proto.services.central.ChartData.prototype.getChartType = function() {
  return /** @type {!proto.services.central.ChartType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.services.central.ChartType} value
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.setChartType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional LineChartData line_chart_data = 2;
 * @return {?proto.services.central.LineChartData}
 */
proto.services.central.ChartData.prototype.getLineChartData = function() {
  return /** @type{?proto.services.central.LineChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.LineChartData, 2));
};


/**
 * @param {?proto.services.central.LineChartData|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setLineChartData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.ChartData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearLineChartData = function() {
  return this.setLineChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasLineChartData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BarChartData bar_chart_data = 3;
 * @return {?proto.services.central.BarChartData}
 */
proto.services.central.ChartData.prototype.getBarChartData = function() {
  return /** @type{?proto.services.central.BarChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.BarChartData, 3));
};


/**
 * @param {?proto.services.central.BarChartData|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setBarChartData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.ChartData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearBarChartData = function() {
  return this.setBarChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasBarChartData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TabularData tabular_data = 6;
 * @return {?proto.services.central.TabularData}
 */
proto.services.central.ChartData.prototype.getTabularData = function() {
  return /** @type{?proto.services.central.TabularData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.TabularData, 6));
};


/**
 * @param {?proto.services.central.TabularData|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setTabularData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.services.central.ChartData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearTabularData = function() {
  return this.setTabularData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasTabularData = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MixedChartData mixed_chart_data = 12;
 * @return {?proto.services.central.MixedChartData}
 */
proto.services.central.ChartData.prototype.getMixedChartData = function() {
  return /** @type{?proto.services.central.MixedChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.MixedChartData, 12));
};


/**
 * @param {?proto.services.central.MixedChartData|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setMixedChartData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.services.central.ChartData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearMixedChartData = function() {
  return this.setMixedChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasMixedChartData = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated ChartAggregate aggregates = 4;
 * @return {!Array<!proto.services.central.ChartAggregate>}
 */
proto.services.central.ChartData.prototype.getAggregatesList = function() {
  return /** @type{!Array<!proto.services.central.ChartAggregate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ChartAggregate, 4));
};


/**
 * @param {!Array<!proto.services.central.ChartAggregate>} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setAggregatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.services.central.ChartAggregate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ChartAggregate}
 */
proto.services.central.ChartData.prototype.addAggregates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.services.central.ChartAggregate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearAggregatesList = function() {
  return this.setAggregatesList([]);
};


/**
 * repeated Tab tabs = 5;
 * @return {!Array<!proto.services.central.ChartData.Tab>}
 */
proto.services.central.ChartData.prototype.getTabsList = function() {
  return /** @type{!Array<!proto.services.central.ChartData.Tab>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ChartData.Tab, 5));
};


/**
 * @param {!Array<!proto.services.central.ChartData.Tab>} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setTabsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.services.central.ChartData.Tab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ChartData.Tab}
 */
proto.services.central.ChartData.prototype.addTabs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.services.central.ChartData.Tab, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearTabsList = function() {
  return this.setTabsList([]);
};


/**
 * optional bool is_different_date_than_requested = 7;
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.getIsDifferentDateThanRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.setIsDifferentDateThanRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional TimeRange available_time_range = 8;
 * @return {?proto.services.central.TimeRange}
 */
proto.services.central.ChartData.prototype.getAvailableTimeRange = function() {
  return /** @type{?proto.services.central.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.services.central.TimeRange, 8));
};


/**
 * @param {?proto.services.central.TimeRange|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setAvailableTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearAvailableTimeRange = function() {
  return this.setAvailableTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasAvailableTimeRange = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string hint = 9;
 * @return {string}
 */
proto.services.central.ChartData.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.setHint = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearHint = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasHint = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.DateTime selected_date = 10;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ChartData.prototype.getSelectedDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 10));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setSelectedDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearSelectedDate = function() {
  return this.setSelectedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ChartData.prototype.hasSelectedDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated ChartConfig configs = 11;
 * @return {!Array<!proto.services.central.ChartConfig>}
 */
proto.services.central.ChartData.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.services.central.ChartConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ChartConfig, 11));
};


/**
 * @param {!Array<!proto.services.central.ChartConfig>} value
 * @return {!proto.services.central.ChartData} returns this
*/
proto.services.central.ChartData.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.services.central.ChartConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ChartConfig}
 */
proto.services.central.ChartData.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.services.central.ChartConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ChartData} returns this
 */
proto.services.central.ChartData.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ChartConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ChartConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ChartConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ChartConfig}
 */
proto.services.central.ChartConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ChartConfig;
  return proto.services.central.ChartConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ChartConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ChartConfig}
 */
proto.services.central.ChartConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ChartConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ChartConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ChartConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.ChartConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartConfig} returns this
 */
proto.services.central.ChartConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.services.central.ChartConfig.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartConfig} returns this
 */
proto.services.central.ChartConfig.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool value = 3;
 * @return {boolean}
 */
proto.services.central.ChartConfig.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ChartConfig} returns this
 */
proto.services.central.ChartConfig.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TimeRange}
 */
proto.services.central.TimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TimeRange;
  return proto.services.central.TimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TimeRange}
 */
proto.services.central.TimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.services.central.TimeRange.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.TimeRange} returns this
 */
proto.services.central.TimeRange.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.services.central.TimeRange.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.TimeRange} returns this
 */
proto.services.central.TimeRange.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MixedChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MixedChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MixedChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MixedChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineChartData: (f = msg.getLineChartData()) && proto.services.central.LineChartData.toObject(includeInstance, f),
    barChartData: (f = msg.getBarChartData()) && proto.services.central.BarChartData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MixedChartData}
 */
proto.services.central.MixedChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MixedChartData;
  return proto.services.central.MixedChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MixedChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MixedChartData}
 */
proto.services.central.MixedChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.LineChartData;
      reader.readMessage(value,proto.services.central.LineChartData.deserializeBinaryFromReader);
      msg.setLineChartData(value);
      break;
    case 2:
      var value = new proto.services.central.BarChartData;
      reader.readMessage(value,proto.services.central.BarChartData.deserializeBinaryFromReader);
      msg.setBarChartData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MixedChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MixedChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MixedChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MixedChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineChartData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.LineChartData.serializeBinaryToWriter
    );
  }
  f = message.getBarChartData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.BarChartData.serializeBinaryToWriter
    );
  }
};


/**
 * optional LineChartData line_chart_data = 1;
 * @return {?proto.services.central.LineChartData}
 */
proto.services.central.MixedChartData.prototype.getLineChartData = function() {
  return /** @type{?proto.services.central.LineChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.LineChartData, 1));
};


/**
 * @param {?proto.services.central.LineChartData|undefined} value
 * @return {!proto.services.central.MixedChartData} returns this
*/
proto.services.central.MixedChartData.prototype.setLineChartData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.MixedChartData} returns this
 */
proto.services.central.MixedChartData.prototype.clearLineChartData = function() {
  return this.setLineChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MixedChartData.prototype.hasLineChartData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BarChartData bar_chart_data = 2;
 * @return {?proto.services.central.BarChartData}
 */
proto.services.central.MixedChartData.prototype.getBarChartData = function() {
  return /** @type{?proto.services.central.BarChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.BarChartData, 2));
};


/**
 * @param {?proto.services.central.BarChartData|undefined} value
 * @return {!proto.services.central.MixedChartData} returns this
*/
proto.services.central.MixedChartData.prototype.setBarChartData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.MixedChartData} returns this
 */
proto.services.central.MixedChartData.prototype.clearBarChartData = function() {
  return this.setBarChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MixedChartData.prototype.hasBarChartData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.LineChartData.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LineChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LineChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LineChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    yAxisLabel: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datasetsList: jspb.Message.toObjectList(msg.getDatasetsList(),
    proto.services.central.LineChartData.Dataset.toObject, includeInstance),
    maxy: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    miny: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lineAnnotionsList: jspb.Message.toObjectList(msg.getLineAnnotionsList(),
    proto.services.central.LineChartData.LineAnnotation.toObject, includeInstance),
    isXAxisDatetime: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LineChartData}
 */
proto.services.central.LineChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LineChartData;
  return proto.services.central.LineChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LineChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LineChartData}
 */
proto.services.central.LineChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYAxisLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 3:
      var value = new proto.services.central.LineChartData.Dataset;
      reader.readMessage(value,proto.services.central.LineChartData.Dataset.deserializeBinaryFromReader);
      msg.addDatasets(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiny(value);
      break;
    case 6:
      var value = new proto.services.central.LineChartData.LineAnnotation;
      reader.readMessage(value,proto.services.central.LineChartData.LineAnnotation.deserializeBinaryFromReader);
      msg.addLineAnnotions(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsXAxisDatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LineChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LineChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LineChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYAxisLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.services.central.LineChartData.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getMaxy();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMiny();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLineAnnotionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.services.central.LineChartData.LineAnnotation.serializeBinaryToWriter
    );
  }
  f = message.getIsXAxisDatetime();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.LineChartData.Dataset.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LineChartData.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LineChartData.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LineChartData.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lineColor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fillColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dataPointsList: jspb.Message.toObjectList(msg.getDataPointsList(),
    proto.services.central.LineChartData.DataPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LineChartData.Dataset}
 */
proto.services.central.LineChartData.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LineChartData.Dataset;
  return proto.services.central.LineChartData.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LineChartData.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LineChartData.Dataset}
 */
proto.services.central.LineChartData.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineColor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFillColor(value);
      break;
    case 3:
      var value = new proto.services.central.LineChartData.DataPoint;
      reader.readMessage(value,proto.services.central.LineChartData.DataPoint.deserializeBinaryFromReader);
      msg.addDataPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LineChartData.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LineChartData.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LineChartData.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLineColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFillColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDataPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.services.central.LineChartData.DataPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.LineChartData.Dataset.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData.Dataset} returns this
 */
proto.services.central.LineChartData.Dataset.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_color = 2;
 * @return {string}
 */
proto.services.central.LineChartData.Dataset.prototype.getLineColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData.Dataset} returns this
 */
proto.services.central.LineChartData.Dataset.prototype.setLineColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fill_color = 4;
 * @return {string}
 */
proto.services.central.LineChartData.Dataset.prototype.getFillColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData.Dataset} returns this
 */
proto.services.central.LineChartData.Dataset.prototype.setFillColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DataPoint data_points = 3;
 * @return {!Array<!proto.services.central.LineChartData.DataPoint>}
 */
proto.services.central.LineChartData.Dataset.prototype.getDataPointsList = function() {
  return /** @type{!Array<!proto.services.central.LineChartData.DataPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.LineChartData.DataPoint, 3));
};


/**
 * @param {!Array<!proto.services.central.LineChartData.DataPoint>} value
 * @return {!proto.services.central.LineChartData.Dataset} returns this
*/
proto.services.central.LineChartData.Dataset.prototype.setDataPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.services.central.LineChartData.DataPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.LineChartData.DataPoint}
 */
proto.services.central.LineChartData.Dataset.prototype.addDataPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.services.central.LineChartData.DataPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.LineChartData.Dataset} returns this
 */
proto.services.central.LineChartData.Dataset.prototype.clearDataPointsList = function() {
  return this.setDataPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LineChartData.DataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LineChartData.DataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LineChartData.DataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.DataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LineChartData.DataPoint}
 */
proto.services.central.LineChartData.DataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LineChartData.DataPoint;
  return proto.services.central.LineChartData.DataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LineChartData.DataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LineChartData.DataPoint}
 */
proto.services.central.LineChartData.DataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LineChartData.DataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LineChartData.DataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LineChartData.DataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.DataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 x = 1;
 * @return {number}
 */
proto.services.central.LineChartData.DataPoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LineChartData.DataPoint} returns this
 */
proto.services.central.LineChartData.DataPoint.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.services.central.LineChartData.DataPoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LineChartData.DataPoint} returns this
 */
proto.services.central.LineChartData.DataPoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LineChartData.LineAnnotation.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LineChartData.LineAnnotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LineChartData.LineAnnotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.LineAnnotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LineChartData.LineAnnotation}
 */
proto.services.central.LineChartData.LineAnnotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LineChartData.LineAnnotation;
  return proto.services.central.LineChartData.LineAnnotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LineChartData.LineAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LineChartData.LineAnnotation}
 */
proto.services.central.LineChartData.LineAnnotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LineChartData.LineAnnotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LineChartData.LineAnnotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LineChartData.LineAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LineChartData.LineAnnotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double y = 1;
 * @return {number}
 */
proto.services.central.LineChartData.LineAnnotation.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LineChartData.LineAnnotation} returns this
 */
proto.services.central.LineChartData.LineAnnotation.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.services.central.LineChartData.LineAnnotation.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData.LineAnnotation} returns this
 */
proto.services.central.LineChartData.LineAnnotation.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.services.central.LineChartData.LineAnnotation.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData.LineAnnotation} returns this
 */
proto.services.central.LineChartData.LineAnnotation.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string y_axis_label = 1;
 * @return {string}
 */
proto.services.central.LineChartData.prototype.getYAxisLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.setYAxisLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.services.central.LineChartData.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Dataset datasets = 3;
 * @return {!Array<!proto.services.central.LineChartData.Dataset>}
 */
proto.services.central.LineChartData.prototype.getDatasetsList = function() {
  return /** @type{!Array<!proto.services.central.LineChartData.Dataset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.LineChartData.Dataset, 3));
};


/**
 * @param {!Array<!proto.services.central.LineChartData.Dataset>} value
 * @return {!proto.services.central.LineChartData} returns this
*/
proto.services.central.LineChartData.prototype.setDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.services.central.LineChartData.Dataset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.LineChartData.Dataset}
 */
proto.services.central.LineChartData.prototype.addDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.services.central.LineChartData.Dataset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.clearDatasetsList = function() {
  return this.setDatasetsList([]);
};


/**
 * optional double maxY = 4;
 * @return {number}
 */
proto.services.central.LineChartData.prototype.getMaxy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.setMaxy = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double minY = 5;
 * @return {number}
 */
proto.services.central.LineChartData.prototype.getMiny = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.setMiny = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated LineAnnotation line_annotions = 6;
 * @return {!Array<!proto.services.central.LineChartData.LineAnnotation>}
 */
proto.services.central.LineChartData.prototype.getLineAnnotionsList = function() {
  return /** @type{!Array<!proto.services.central.LineChartData.LineAnnotation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.LineChartData.LineAnnotation, 6));
};


/**
 * @param {!Array<!proto.services.central.LineChartData.LineAnnotation>} value
 * @return {!proto.services.central.LineChartData} returns this
*/
proto.services.central.LineChartData.prototype.setLineAnnotionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.services.central.LineChartData.LineAnnotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.LineChartData.LineAnnotation}
 */
proto.services.central.LineChartData.prototype.addLineAnnotions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.services.central.LineChartData.LineAnnotation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.clearLineAnnotionsList = function() {
  return this.setLineAnnotionsList([]);
};


/**
 * optional bool is_x_axis_datetime = 7;
 * @return {boolean}
 */
proto.services.central.LineChartData.prototype.getIsXAxisDatetime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.LineChartData} returns this
 */
proto.services.central.LineChartData.prototype.setIsXAxisDatetime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.BarChartData.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.BarChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.BarChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.BarChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BarChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    yAxisLabel: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    datasetsList: jspb.Message.toObjectList(msg.getDatasetsList(),
    proto.services.central.BarChartData.Dataset.toObject, includeInstance),
    stackedBarChartData: (f = msg.getStackedBarChartData()) && proto.services.central.StackedBarChartData.toObject(includeInstance, f),
    maxy: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    miny: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.BarChartData}
 */
proto.services.central.BarChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.BarChartData;
  return proto.services.central.BarChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.BarChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.BarChartData}
 */
proto.services.central.BarChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYAxisLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 4:
      var value = new proto.services.central.BarChartData.Dataset;
      reader.readMessage(value,proto.services.central.BarChartData.Dataset.deserializeBinaryFromReader);
      msg.addDatasets(value);
      break;
    case 5:
      var value = new proto.services.central.StackedBarChartData;
      reader.readMessage(value,proto.services.central.StackedBarChartData.deserializeBinaryFromReader);
      msg.setStackedBarChartData(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxy(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiny(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.BarChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.BarChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.BarChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BarChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYAxisLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.services.central.BarChartData.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getStackedBarChartData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.services.central.StackedBarChartData.serializeBinaryToWriter
    );
  }
  f = message.getMaxy();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMiny();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.BarChartData.Dataset.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.BarChartData.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.BarChartData.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.BarChartData.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BarChartData.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    stackId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.BarChartData.Dataset}
 */
proto.services.central.BarChartData.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.BarChartData.Dataset;
  return proto.services.central.BarChartData.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.BarChartData.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.BarChartData.Dataset}
 */
proto.services.central.BarChartData.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addData(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStackId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.BarChartData.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.BarChartData.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.BarChartData.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BarChartData.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getStackId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.BarChartData.Dataset.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.services.central.BarChartData.Dataset.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated double data = 3;
 * @return {!Array<number>}
 */
proto.services.central.BarChartData.Dataset.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional string stack_id = 4;
 * @return {string}
 */
proto.services.central.BarChartData.Dataset.prototype.getStackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BarChartData.Dataset} returns this
 */
proto.services.central.BarChartData.Dataset.prototype.setStackId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string y_axis_label = 1;
 * @return {string}
 */
proto.services.central.BarChartData.prototype.getYAxisLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.setYAxisLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.services.central.BarChartData.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string labels = 3;
 * @return {!Array<string>}
 */
proto.services.central.BarChartData.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated Dataset datasets = 4;
 * @return {!Array<!proto.services.central.BarChartData.Dataset>}
 */
proto.services.central.BarChartData.prototype.getDatasetsList = function() {
  return /** @type{!Array<!proto.services.central.BarChartData.Dataset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.BarChartData.Dataset, 4));
};


/**
 * @param {!Array<!proto.services.central.BarChartData.Dataset>} value
 * @return {!proto.services.central.BarChartData} returns this
*/
proto.services.central.BarChartData.prototype.setDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.services.central.BarChartData.Dataset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.BarChartData.Dataset}
 */
proto.services.central.BarChartData.prototype.addDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.services.central.BarChartData.Dataset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.clearDatasetsList = function() {
  return this.setDatasetsList([]);
};


/**
 * optional StackedBarChartData stacked_bar_chart_data = 5;
 * @return {?proto.services.central.StackedBarChartData}
 */
proto.services.central.BarChartData.prototype.getStackedBarChartData = function() {
  return /** @type{?proto.services.central.StackedBarChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.StackedBarChartData, 5));
};


/**
 * @param {?proto.services.central.StackedBarChartData|undefined} value
 * @return {!proto.services.central.BarChartData} returns this
*/
proto.services.central.BarChartData.prototype.setStackedBarChartData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.clearStackedBarChartData = function() {
  return this.setStackedBarChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.BarChartData.prototype.hasStackedBarChartData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double maxY = 6;
 * @return {number}
 */
proto.services.central.BarChartData.prototype.getMaxy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.setMaxy = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double minY = 7;
 * @return {number}
 */
proto.services.central.BarChartData.prototype.getMiny = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.BarChartData} returns this
 */
proto.services.central.BarChartData.prototype.setMiny = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ChartAggregate.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ChartAggregate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ChartAggregate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartAggregate.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valueFormatted: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    color: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tooltip: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ChartAggregate}
 */
proto.services.central.ChartAggregate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ChartAggregate;
  return proto.services.central.ChartAggregate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ChartAggregate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ChartAggregate}
 */
proto.services.central.ChartAggregate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFormatted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTooltip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ChartAggregate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ChartAggregate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ChartAggregate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ChartAggregate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getValueFormatted();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTooltip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.ChartAggregate.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.services.central.ChartAggregate.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string value_formatted = 6;
 * @return {string}
 */
proto.services.central.ChartAggregate.prototype.getValueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setValueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.services.central.ChartAggregate.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string color = 4;
 * @return {string}
 */
proto.services.central.ChartAggregate.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tooltip = 5;
 * @return {string}
 */
proto.services.central.ChartAggregate.prototype.getTooltip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ChartAggregate} returns this
 */
proto.services.central.ChartAggregate.prototype.setTooltip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.TabularData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TabularData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TabularData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TabularData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TabularData.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.services.central.TabularData.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TabularData}
 */
proto.services.central.TabularData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TabularData;
  return proto.services.central.TabularData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TabularData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TabularData}
 */
proto.services.central.TabularData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addHeaders(value);
      break;
    case 2:
      var value = new proto.services.central.TabularData.Row;
      reader.readMessage(value,proto.services.central.TabularData.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TabularData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TabularData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TabularData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TabularData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.TabularData.Row.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.TabularData.Row.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TabularData.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TabularData.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TabularData.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TabularData.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TabularData.Row}
 */
proto.services.central.TabularData.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TabularData.Row;
  return proto.services.central.TabularData.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TabularData.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TabularData.Row}
 */
proto.services.central.TabularData.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TabularData.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TabularData.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TabularData.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TabularData.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string columns = 1;
 * @return {!Array<string>}
 */
proto.services.central.TabularData.Row.prototype.getColumnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.TabularData.Row} returns this
 */
proto.services.central.TabularData.Row.prototype.setColumnsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.TabularData.Row} returns this
 */
proto.services.central.TabularData.Row.prototype.addColumns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.TabularData.Row} returns this
 */
proto.services.central.TabularData.Row.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * repeated string headers = 1;
 * @return {!Array<string>}
 */
proto.services.central.TabularData.prototype.getHeadersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.TabularData} returns this
 */
proto.services.central.TabularData.prototype.setHeadersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.TabularData} returns this
 */
proto.services.central.TabularData.prototype.addHeaders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.TabularData} returns this
 */
proto.services.central.TabularData.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.services.central.TabularData.Row>}
 */
proto.services.central.TabularData.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.services.central.TabularData.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.TabularData.Row, 2));
};


/**
 * @param {!Array<!proto.services.central.TabularData.Row>} value
 * @return {!proto.services.central.TabularData} returns this
*/
proto.services.central.TabularData.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.TabularData.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.TabularData.Row}
 */
proto.services.central.TabularData.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.TabularData.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.TabularData} returns this
 */
proto.services.central.TabularData.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.StackedBarChartData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StackedBarChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StackedBarChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StackedBarChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StackedBarChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    barsDataList: jspb.Message.toObjectList(msg.getBarsDataList(),
    proto.services.central.StackedBarChartData.BarData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StackedBarChartData}
 */
proto.services.central.StackedBarChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StackedBarChartData;
  return proto.services.central.StackedBarChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StackedBarChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StackedBarChartData}
 */
proto.services.central.StackedBarChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.StackedBarChartData.BarData;
      reader.readMessage(value,proto.services.central.StackedBarChartData.BarData.deserializeBinaryFromReader);
      msg.addBarsData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StackedBarChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StackedBarChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StackedBarChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StackedBarChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBarsDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.StackedBarChartData.BarData.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.StackedBarChartData.BarData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StackedBarChartData.BarData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StackedBarChartData.BarData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StackedBarChartData.BarData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StackedBarChartData.BarData.toObject = function(includeInstance, msg) {
  var f, obj = {
    positiveValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    negativeValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StackedBarChartData.BarData}
 */
proto.services.central.StackedBarChartData.BarData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StackedBarChartData.BarData;
  return proto.services.central.StackedBarChartData.BarData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StackedBarChartData.BarData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StackedBarChartData.BarData}
 */
proto.services.central.StackedBarChartData.BarData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPositiveValues(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNegativeValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StackedBarChartData.BarData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StackedBarChartData.BarData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StackedBarChartData.BarData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StackedBarChartData.BarData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositiveValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getNegativeValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
};


/**
 * repeated double positive_values = 1;
 * @return {!Array<number>}
 */
proto.services.central.StackedBarChartData.BarData.prototype.getPositiveValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.setPositiveValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.addPositiveValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.clearPositiveValuesList = function() {
  return this.setPositiveValuesList([]);
};


/**
 * repeated double negative_values = 2;
 * @return {!Array<number>}
 */
proto.services.central.StackedBarChartData.BarData.prototype.getNegativeValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.setNegativeValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.addNegativeValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StackedBarChartData.BarData} returns this
 */
proto.services.central.StackedBarChartData.BarData.prototype.clearNegativeValuesList = function() {
  return this.setNegativeValuesList([]);
};


/**
 * repeated BarData bars_data = 1;
 * @return {!Array<!proto.services.central.StackedBarChartData.BarData>}
 */
proto.services.central.StackedBarChartData.prototype.getBarsDataList = function() {
  return /** @type{!Array<!proto.services.central.StackedBarChartData.BarData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.StackedBarChartData.BarData, 1));
};


/**
 * @param {!Array<!proto.services.central.StackedBarChartData.BarData>} value
 * @return {!proto.services.central.StackedBarChartData} returns this
*/
proto.services.central.StackedBarChartData.prototype.setBarsDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.StackedBarChartData.BarData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.StackedBarChartData.BarData}
 */
proto.services.central.StackedBarChartData.prototype.addBarsData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.StackedBarChartData.BarData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StackedBarChartData} returns this
 */
proto.services.central.StackedBarChartData.prototype.clearBarsDataList = function() {
  return this.setBarsDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAdvancePaymentWidgetDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataRequest}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAdvancePaymentWidgetDataRequest;
  return proto.services.central.GetAdvancePaymentWidgetDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataRequest}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAdvancePaymentWidgetDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataRequest} returns this
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataRequest} returns this
 */
proto.services.central.GetAdvancePaymentWidgetDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAdvancePaymentWidgetDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.services.central.AdvancePaymentWidgetData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataResponse}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAdvancePaymentWidgetDataResponse;
  return proto.services.central.GetAdvancePaymentWidgetDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataResponse}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.AdvancePaymentWidgetData;
      reader.readMessage(value,proto.services.central.AdvancePaymentWidgetData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAdvancePaymentWidgetDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAdvancePaymentWidgetDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.AdvancePaymentWidgetData.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdvancePaymentWidgetData data = 1;
 * @return {?proto.services.central.AdvancePaymentWidgetData}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.getData = function() {
  return /** @type{?proto.services.central.AdvancePaymentWidgetData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AdvancePaymentWidgetData, 1));
};


/**
 * @param {?proto.services.central.AdvancePaymentWidgetData|undefined} value
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataResponse} returns this
*/
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAdvancePaymentWidgetDataResponse} returns this
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAdvancePaymentWidgetDataResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AdvancePaymentWidgetData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AdvancePaymentWidgetData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePaymentWidgetData.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentMonthlyAdvancePayment: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentMonthLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    costPrediction: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    infoText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sumOverPeriod: jspb.Message.getFieldWithDefault(msg, 6, ""),
    chartData: (f = msg.getChartData()) && proto.services.central.AdvancePaymentWidgetData.ChartData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AdvancePaymentWidgetData}
 */
proto.services.central.AdvancePaymentWidgetData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AdvancePaymentWidgetData;
  return proto.services.central.AdvancePaymentWidgetData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AdvancePaymentWidgetData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AdvancePaymentWidgetData}
 */
proto.services.central.AdvancePaymentWidgetData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentMonthlyAdvancePayment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentMonthLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPrediction(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfoText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSumOverPeriod(value);
      break;
    case 7:
      var value = new proto.services.central.AdvancePaymentWidgetData.ChartData;
      reader.readMessage(value,proto.services.central.AdvancePaymentWidgetData.ChartData.deserializeBinaryFromReader);
      msg.setChartData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AdvancePaymentWidgetData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AdvancePaymentWidgetData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePaymentWidgetData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentMonthlyAdvancePayment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentMonthLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCostPrediction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInfoText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSumOverPeriod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChartData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.services.central.AdvancePaymentWidgetData.ChartData.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AdvancePaymentWidgetData.ChartData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AdvancePaymentWidgetData.ChartData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    barColorsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AdvancePaymentWidgetData.ChartData;
  return proto.services.central.AdvancePaymentWidgetData.ChartData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AdvancePaymentWidgetData.ChartData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBarColors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AdvancePaymentWidgetData.ChartData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AdvancePaymentWidgetData.ChartData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBarColorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated double values = 1;
 * @return {!Array<number>}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated string bar_colors = 3;
 * @return {!Array<string>}
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.getBarColorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.setBarColorsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.addBarColors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.AdvancePaymentWidgetData.ChartData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.ChartData.prototype.clearBarColorsList = function() {
  return this.setBarColorsList([]);
};


/**
 * optional string current_monthly_advance_payment = 1;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getCurrentMonthlyAdvancePayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setCurrentMonthlyAdvancePayment = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_month_label = 2;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getCurrentMonthLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setCurrentMonthLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cost_prediction = 3;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getCostPrediction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setCostPrediction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string info_text = 5;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getInfoText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setInfoText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sum_over_period = 6;
 * @return {string}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getSumOverPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.setSumOverPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ChartData chart_data = 7;
 * @return {?proto.services.central.AdvancePaymentWidgetData.ChartData}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.getChartData = function() {
  return /** @type{?proto.services.central.AdvancePaymentWidgetData.ChartData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AdvancePaymentWidgetData.ChartData, 7));
};


/**
 * @param {?proto.services.central.AdvancePaymentWidgetData.ChartData|undefined} value
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
*/
proto.services.central.AdvancePaymentWidgetData.prototype.setChartData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AdvancePaymentWidgetData} returns this
 */
proto.services.central.AdvancePaymentWidgetData.prototype.clearChartData = function() {
  return this.setChartData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AdvancePaymentWidgetData.prototype.hasChartData = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSpaceComparisonWidgetDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceComparand: jspb.Message.getFieldWithDefault(msg, 2, 0),
    targetComparand: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timePeriod: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSpaceComparisonWidgetDataRequest;
  return proto.services.central.GetSpaceComparisonWidgetDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} */ (reader.readEnum());
      msg.setSourceComparand(value);
      break;
    case 3:
      var value = /** @type {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} */ (reader.readEnum());
      msg.setTargetComparand(value);
      break;
    case 4:
      var value = /** @type {!proto.types.TimePeriod} */ (reader.readEnum());
      msg.setTimePeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSpaceComparisonWidgetDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceComparand();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTargetComparand();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimePeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand = {
  SPACE_CONSUMPTION: 0,
  AVERAGE_CONSUMPTION_OF_PEER_SPACES: 1
};

/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest} returns this
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Comparand source_comparand = 2;
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.getSourceComparand = function() {
  return /** @type {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} value
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest} returns this
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.setSourceComparand = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Comparand target_comparand = 3;
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.getTargetComparand = function() {
  return /** @type {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataRequest.Comparand} value
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest} returns this
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.setTargetComparand = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional types.TimePeriod time_period = 4;
 * @return {!proto.types.TimePeriod}
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.getTimePeriod = function() {
  return /** @type {!proto.types.TimePeriod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.TimePeriod} value
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataRequest} returns this
 */
proto.services.central.GetSpaceComparisonWidgetDataRequest.prototype.setTimePeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSpaceComparisonWidgetDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.services.central.SpaceComparisonWidgetData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataResponse}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSpaceComparisonWidgetDataResponse;
  return proto.services.central.GetSpaceComparisonWidgetDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataResponse}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.SpaceComparisonWidgetData;
      reader.readMessage(value,proto.services.central.SpaceComparisonWidgetData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSpaceComparisonWidgetDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSpaceComparisonWidgetDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.SpaceComparisonWidgetData.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceComparisonWidgetData data = 1;
 * @return {?proto.services.central.SpaceComparisonWidgetData}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.getData = function() {
  return /** @type{?proto.services.central.SpaceComparisonWidgetData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.SpaceComparisonWidgetData, 1));
};


/**
 * @param {?proto.services.central.SpaceComparisonWidgetData|undefined} value
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataResponse} returns this
*/
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetSpaceComparisonWidgetDataResponse} returns this
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetSpaceComparisonWidgetDataResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.SpaceComparisonWidgetData.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SpaceComparisonWidgetData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SpaceComparisonWidgetData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comparisonValuesList: jspb.Message.toObjectList(msg.getComparisonValuesList(),
    proto.services.central.SpaceComparisonWidgetData.ComparisonValue.toObject, includeInstance),
    comparisonResult: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timePeriodTabsList: jspb.Message.toObjectList(msg.getTimePeriodTabsList(),
    proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SpaceComparisonWidgetData}
 */
proto.services.central.SpaceComparisonWidgetData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SpaceComparisonWidgetData;
  return proto.services.central.SpaceComparisonWidgetData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SpaceComparisonWidgetData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SpaceComparisonWidgetData}
 */
proto.services.central.SpaceComparisonWidgetData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new proto.services.central.SpaceComparisonWidgetData.ComparisonValue;
      reader.readMessage(value,proto.services.central.SpaceComparisonWidgetData.ComparisonValue.deserializeBinaryFromReader);
      msg.addComparisonValues(value);
      break;
    case 3:
      var value = /** @type {!proto.services.central.SpaceComparisonWidgetData.ComparisonResult} */ (reader.readEnum());
      msg.setComparisonResult(value);
      break;
    case 4:
      var value = new proto.services.central.SpaceComparisonWidgetData.TimePeriodTab;
      reader.readMessage(value,proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.deserializeBinaryFromReader);
      msg.addTimePeriodTabs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SpaceComparisonWidgetData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SpaceComparisonWidgetData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComparisonValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.SpaceComparisonWidgetData.ComparisonValue.serializeBinaryToWriter
    );
  }
  f = message.getComparisonResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimePeriodTabsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonResult = {
  HIGHER: 0,
  LOWER: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SpaceComparisonWidgetData.ComparisonValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valueFormatted: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    color: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SpaceComparisonWidgetData.ComparisonValue;
  return proto.services.central.SpaceComparisonWidgetData.ComparisonValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFormatted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SpaceComparisonWidgetData.ComparisonValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getValueFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} returns this
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} returns this
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string value_formatted = 3;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.getValueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} returns this
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.setValueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} returns this
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue} returns this
 */
proto.services.central.SpaceComparisonWidgetData.ComparisonValue.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SpaceComparisonWidgetData.TimePeriodTab;
  return proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.types.TimePeriod} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab} returns this
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.TimePeriod value = 2;
 * @return {!proto.types.TimePeriod}
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.getValue = function() {
  return /** @type {!proto.types.TimePeriod} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.TimePeriod} value
 * @return {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab} returns this
 */
proto.services.central.SpaceComparisonWidgetData.TimePeriodTab.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
 */
proto.services.central.SpaceComparisonWidgetData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ComparisonValue comparison_values = 2;
 * @return {!Array<!proto.services.central.SpaceComparisonWidgetData.ComparisonValue>}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.getComparisonValuesList = function() {
  return /** @type{!Array<!proto.services.central.SpaceComparisonWidgetData.ComparisonValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.SpaceComparisonWidgetData.ComparisonValue, 2));
};


/**
 * @param {!Array<!proto.services.central.SpaceComparisonWidgetData.ComparisonValue>} value
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
*/
proto.services.central.SpaceComparisonWidgetData.prototype.setComparisonValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonValue}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.addComparisonValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.SpaceComparisonWidgetData.ComparisonValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
 */
proto.services.central.SpaceComparisonWidgetData.prototype.clearComparisonValuesList = function() {
  return this.setComparisonValuesList([]);
};


/**
 * optional ComparisonResult comparison_result = 3;
 * @return {!proto.services.central.SpaceComparisonWidgetData.ComparisonResult}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.getComparisonResult = function() {
  return /** @type {!proto.services.central.SpaceComparisonWidgetData.ComparisonResult} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.central.SpaceComparisonWidgetData.ComparisonResult} value
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
 */
proto.services.central.SpaceComparisonWidgetData.prototype.setComparisonResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated TimePeriodTab time_period_tabs = 4;
 * @return {!Array<!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab>}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.getTimePeriodTabsList = function() {
  return /** @type{!Array<!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.SpaceComparisonWidgetData.TimePeriodTab, 4));
};


/**
 * @param {!Array<!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab>} value
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
*/
proto.services.central.SpaceComparisonWidgetData.prototype.setTimePeriodTabsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.SpaceComparisonWidgetData.TimePeriodTab}
 */
proto.services.central.SpaceComparisonWidgetData.prototype.addTimePeriodTabs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.services.central.SpaceComparisonWidgetData.TimePeriodTab, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.SpaceComparisonWidgetData} returns this
 */
proto.services.central.SpaceComparisonWidgetData.prototype.clearTimePeriodTabsList = function() {
  return this.setTimePeriodTabsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetTabularDataRequest.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.services.central.GetTabularDataRequest.TabularRequestCase = {
  TABULAR_REQUEST_NOT_SET: 0,
  DEVICE_READINGS_TABLE: 1
};

/**
 * @return {proto.services.central.GetTabularDataRequest.TabularRequestCase}
 */
proto.services.central.GetTabularDataRequest.prototype.getTabularRequestCase = function() {
  return /** @type {proto.services.central.GetTabularDataRequest.TabularRequestCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetTabularDataRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTabularDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTabularDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTabularDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceReadingsTable: (f = msg.getDeviceReadingsTable()) && proto.services.central.DeviceReadingsTableRequest.toObject(includeInstance, f),
    isEditable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTabularDataRequest}
 */
proto.services.central.GetTabularDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTabularDataRequest;
  return proto.services.central.GetTabularDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTabularDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTabularDataRequest}
 */
proto.services.central.GetTabularDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.DeviceReadingsTableRequest;
      reader.readMessage(value,proto.services.central.DeviceReadingsTableRequest.deserializeBinaryFromReader);
      msg.setDeviceReadingsTable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTabularDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTabularDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTabularDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceReadingsTable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.DeviceReadingsTableRequest.serializeBinaryToWriter
    );
  }
  f = message.getIsEditable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional DeviceReadingsTableRequest device_readings_table = 1;
 * @return {?proto.services.central.DeviceReadingsTableRequest}
 */
proto.services.central.GetTabularDataRequest.prototype.getDeviceReadingsTable = function() {
  return /** @type{?proto.services.central.DeviceReadingsTableRequest} */ (
    jspb.Message.getWrapperField(this, proto.services.central.DeviceReadingsTableRequest, 1));
};


/**
 * @param {?proto.services.central.DeviceReadingsTableRequest|undefined} value
 * @return {!proto.services.central.GetTabularDataRequest} returns this
*/
proto.services.central.GetTabularDataRequest.prototype.setDeviceReadingsTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetTabularDataRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTabularDataRequest} returns this
 */
proto.services.central.GetTabularDataRequest.prototype.clearDeviceReadingsTable = function() {
  return this.setDeviceReadingsTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTabularDataRequest.prototype.hasDeviceReadingsTable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_editable = 2;
 * @return {boolean}
 */
proto.services.central.GetTabularDataRequest.prototype.getIsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetTabularDataRequest} returns this
 */
proto.services.central.GetTabularDataRequest.prototype.setIsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeviceReadingsTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeviceReadingsTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReadingsTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeviceReadingsTableRequest}
 */
proto.services.central.DeviceReadingsTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeviceReadingsTableRequest;
  return proto.services.central.DeviceReadingsTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeviceReadingsTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeviceReadingsTableRequest}
 */
proto.services.central.DeviceReadingsTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeviceReadingsTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeviceReadingsTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReadingsTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
 */
proto.services.central.DeviceReadingsTableRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
 */
proto.services.central.DeviceReadingsTableRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.DateTime start_date = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
*/
proto.services.central.DeviceReadingsTableRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
 */
proto.services.central.DeviceReadingsTableRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_date = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
*/
proto.services.central.DeviceReadingsTableRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeviceReadingsTableRequest} returns this
 */
proto.services.central.DeviceReadingsTableRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReadingsTableRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTabularDataResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTabularDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTabularDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTabularDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.services.central.GetTabularDataResponse.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTabularDataResponse}
 */
proto.services.central.GetTabularDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTabularDataResponse;
  return proto.services.central.GetTabularDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTabularDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTabularDataResponse}
 */
proto.services.central.GetTabularDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addHeaders(value);
      break;
    case 2:
      var value = new proto.services.central.GetTabularDataResponse.Row;
      reader.readMessage(value,proto.services.central.GetTabularDataResponse.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTabularDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTabularDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTabularDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.GetTabularDataResponse.Row.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTabularDataResponse.Row.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTabularDataResponse.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTabularDataResponse.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTabularDataResponse.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rowData: (f = msg.getRowData()) && proto.services.central.GetTabularDataResponse.Row.RowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTabularDataResponse.Row}
 */
proto.services.central.GetTabularDataResponse.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTabularDataResponse.Row;
  return proto.services.central.GetTabularDataResponse.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTabularDataResponse.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTabularDataResponse.Row}
 */
proto.services.central.GetTabularDataResponse.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addColumns(value);
      break;
    case 2:
      var value = new proto.services.central.GetTabularDataResponse.Row.RowData;
      reader.readMessage(value,proto.services.central.GetTabularDataResponse.Row.RowData.deserializeBinaryFromReader);
      msg.setRowData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTabularDataResponse.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTabularDataResponse.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTabularDataResponse.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRowData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.GetTabularDataResponse.Row.RowData.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetTabularDataResponse.Row.RowData.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DataCase = {
  DATA_NOT_SET: 0,
  DEVICE_READING_ROW: 1
};

/**
 * @return {proto.services.central.GetTabularDataResponse.Row.RowData.DataCase}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.getDataCase = function() {
  return /** @type {proto.services.central.GetTabularDataResponse.Row.RowData.DataCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetTabularDataResponse.Row.RowData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTabularDataResponse.Row.RowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.RowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceReadingRow: (f = msg.getDeviceReadingRow()) && proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTabularDataResponse.Row.RowData;
  return proto.services.central.GetTabularDataResponse.Row.RowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow;
      reader.readMessage(value,proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.deserializeBinaryFromReader);
      msg.setDeviceReadingRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTabularDataResponse.Row.RowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.RowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceReadingRow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    timeStamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow;
  return proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStamp(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTimeStamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} returns this
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 time_stamp = 2;
 * @return {number}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.getTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} returns this
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.setTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional types.Unit unit = 3;
 * @return {!proto.types.Unit}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} returns this
 */
proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DeviceReadingRow device_reading_row = 1;
 * @return {?proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.getDeviceReadingRow = function() {
  return /** @type{?proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow, 1));
};


/**
 * @param {?proto.services.central.GetTabularDataResponse.Row.RowData.DeviceReadingRow|undefined} value
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData} returns this
*/
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.setDeviceReadingRow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetTabularDataResponse.Row.RowData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTabularDataResponse.Row.RowData} returns this
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.clearDeviceReadingRow = function() {
  return this.setDeviceReadingRow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTabularDataResponse.Row.RowData.prototype.hasDeviceReadingRow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string columns = 1;
 * @return {!Array<string>}
 */
proto.services.central.GetTabularDataResponse.Row.prototype.getColumnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetTabularDataResponse.Row} returns this
 */
proto.services.central.GetTabularDataResponse.Row.prototype.setColumnsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTabularDataResponse.Row} returns this
 */
proto.services.central.GetTabularDataResponse.Row.prototype.addColumns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTabularDataResponse.Row} returns this
 */
proto.services.central.GetTabularDataResponse.Row.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * optional RowData row_data = 2;
 * @return {?proto.services.central.GetTabularDataResponse.Row.RowData}
 */
proto.services.central.GetTabularDataResponse.Row.prototype.getRowData = function() {
  return /** @type{?proto.services.central.GetTabularDataResponse.Row.RowData} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GetTabularDataResponse.Row.RowData, 2));
};


/**
 * @param {?proto.services.central.GetTabularDataResponse.Row.RowData|undefined} value
 * @return {!proto.services.central.GetTabularDataResponse.Row} returns this
*/
proto.services.central.GetTabularDataResponse.Row.prototype.setRowData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTabularDataResponse.Row} returns this
 */
proto.services.central.GetTabularDataResponse.Row.prototype.clearRowData = function() {
  return this.setRowData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTabularDataResponse.Row.prototype.hasRowData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string headers = 1;
 * @return {!Array<string>}
 */
proto.services.central.GetTabularDataResponse.prototype.getHeadersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetTabularDataResponse} returns this
 */
proto.services.central.GetTabularDataResponse.prototype.setHeadersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTabularDataResponse} returns this
 */
proto.services.central.GetTabularDataResponse.prototype.addHeaders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTabularDataResponse} returns this
 */
proto.services.central.GetTabularDataResponse.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.services.central.GetTabularDataResponse.Row>}
 */
proto.services.central.GetTabularDataResponse.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.services.central.GetTabularDataResponse.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.GetTabularDataResponse.Row, 2));
};


/**
 * @param {!Array<!proto.services.central.GetTabularDataResponse.Row>} value
 * @return {!proto.services.central.GetTabularDataResponse} returns this
*/
proto.services.central.GetTabularDataResponse.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.GetTabularDataResponse.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTabularDataResponse.Row}
 */
proto.services.central.GetTabularDataResponse.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.GetTabularDataResponse.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTabularDataResponse} returns this
 */
proto.services.central.GetTabularDataResponse.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDescriptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDescriptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDescriptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDescriptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDescriptionsRequest}
 */
proto.services.central.GetAutomationDescriptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDescriptionsRequest;
  return proto.services.central.GetAutomationDescriptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDescriptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDescriptionsRequest}
 */
proto.services.central.GetAutomationDescriptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDescriptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDescriptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDescriptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDescriptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAutomationDescriptionsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationDescriptionsRequest} returns this
 */
proto.services.central.GetAutomationDescriptionsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAutomationDescriptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDescriptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDescriptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDescriptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDescriptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationsList: jspb.Message.toObjectList(msg.getAutomationsList(),
    types_automations_pb.AutomationDescription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDescriptionsResponse}
 */
proto.services.central.GetAutomationDescriptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDescriptionsResponse;
  return proto.services.central.GetAutomationDescriptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDescriptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDescriptionsResponse}
 */
proto.services.central.GetAutomationDescriptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.AutomationDescription;
      reader.readMessage(value,types_automations_pb.AutomationDescription.deserializeBinaryFromReader);
      msg.addAutomations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDescriptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDescriptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDescriptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDescriptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_automations_pb.AutomationDescription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.AutomationDescription automations = 1;
 * @return {!Array<!proto.types.AutomationDescription>}
 */
proto.services.central.GetAutomationDescriptionsResponse.prototype.getAutomationsList = function() {
  return /** @type{!Array<!proto.types.AutomationDescription>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_automations_pb.AutomationDescription, 1));
};


/**
 * @param {!Array<!proto.types.AutomationDescription>} value
 * @return {!proto.services.central.GetAutomationDescriptionsResponse} returns this
*/
proto.services.central.GetAutomationDescriptionsResponse.prototype.setAutomationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.AutomationDescription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.AutomationDescription}
 */
proto.services.central.GetAutomationDescriptionsResponse.prototype.addAutomations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.AutomationDescription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationDescriptionsResponse} returns this
 */
proto.services.central.GetAutomationDescriptionsResponse.prototype.clearAutomationsList = function() {
  return this.setAutomationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDashboardLayoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDashboardLayoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDashboardLayoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardLayoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDashboardLayoutRequest}
 */
proto.services.central.GetDashboardLayoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDashboardLayoutRequest;
  return proto.services.central.GetDashboardLayoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDashboardLayoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDashboardLayoutRequest}
 */
proto.services.central.GetDashboardLayoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDashboardLayoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDashboardLayoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDashboardLayoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardLayoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetDashboardLayoutRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDashboardLayoutRequest} returns this
 */
proto.services.central.GetDashboardLayoutRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDashboardLayoutRequest} returns this
 */
proto.services.central.GetDashboardLayoutRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardLayoutRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDashboardLayoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDashboardLayoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDashboardLayoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardLayoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    layout: (f = msg.getLayout()) && types_dashboard_layout_pb.DashboardLayout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDashboardLayoutResponse}
 */
proto.services.central.GetDashboardLayoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDashboardLayoutResponse;
  return proto.services.central.GetDashboardLayoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDashboardLayoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDashboardLayoutResponse}
 */
proto.services.central.GetDashboardLayoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_dashboard_layout_pb.DashboardLayout;
      reader.readMessage(value,types_dashboard_layout_pb.DashboardLayout.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDashboardLayoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDashboardLayoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDashboardLayoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDashboardLayoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_dashboard_layout_pb.DashboardLayout.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DashboardLayout layout = 1;
 * @return {?proto.types.DashboardLayout}
 */
proto.services.central.GetDashboardLayoutResponse.prototype.getLayout = function() {
  return /** @type{?proto.types.DashboardLayout} */ (
    jspb.Message.getWrapperField(this, types_dashboard_layout_pb.DashboardLayout, 1));
};


/**
 * @param {?proto.types.DashboardLayout|undefined} value
 * @return {!proto.services.central.GetDashboardLayoutResponse} returns this
*/
proto.services.central.GetDashboardLayoutResponse.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDashboardLayoutResponse} returns this
 */
proto.services.central.GetDashboardLayoutResponse.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDashboardLayoutResponse.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ResetDashboardLayoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ResetDashboardLayoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ResetDashboardLayoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ResetDashboardLayoutRequest}
 */
proto.services.central.ResetDashboardLayoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ResetDashboardLayoutRequest;
  return proto.services.central.ResetDashboardLayoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ResetDashboardLayoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ResetDashboardLayoutRequest}
 */
proto.services.central.ResetDashboardLayoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ResetDashboardLayoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ResetDashboardLayoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ResetDashboardLayoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ResetDashboardLayoutRequest} returns this
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ResetDashboardLayoutRequest} returns this
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ResetDashboardLayoutRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ResetDashboardLayoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ResetDashboardLayoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ResetDashboardLayoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ResetDashboardLayoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newLayout: (f = msg.getNewLayout()) && types_dashboard_layout_pb.DashboardLayout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ResetDashboardLayoutResponse}
 */
proto.services.central.ResetDashboardLayoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ResetDashboardLayoutResponse;
  return proto.services.central.ResetDashboardLayoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ResetDashboardLayoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ResetDashboardLayoutResponse}
 */
proto.services.central.ResetDashboardLayoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_dashboard_layout_pb.DashboardLayout;
      reader.readMessage(value,types_dashboard_layout_pb.DashboardLayout.deserializeBinaryFromReader);
      msg.setNewLayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ResetDashboardLayoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ResetDashboardLayoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ResetDashboardLayoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ResetDashboardLayoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewLayout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_dashboard_layout_pb.DashboardLayout.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DashboardLayout new_layout = 1;
 * @return {?proto.types.DashboardLayout}
 */
proto.services.central.ResetDashboardLayoutResponse.prototype.getNewLayout = function() {
  return /** @type{?proto.types.DashboardLayout} */ (
    jspb.Message.getWrapperField(this, types_dashboard_layout_pb.DashboardLayout, 1));
};


/**
 * @param {?proto.types.DashboardLayout|undefined} value
 * @return {!proto.services.central.ResetDashboardLayoutResponse} returns this
*/
proto.services.central.ResetDashboardLayoutResponse.prototype.setNewLayout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ResetDashboardLayoutResponse} returns this
 */
proto.services.central.ResetDashboardLayoutResponse.prototype.clearNewLayout = function() {
  return this.setNewLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ResetDashboardLayoutResponse.prototype.hasNewLayout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MoveDashboardItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MoveDashboardItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MoveDashboardItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MoveDashboardItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    item: jspb.Message.getFieldWithDefault(msg, 2, 0),
    newPosition: (f = msg.getNewPosition()) && types_dashboard_layout_pb.DashboardItemPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MoveDashboardItemRequest}
 */
proto.services.central.MoveDashboardItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MoveDashboardItemRequest;
  return proto.services.central.MoveDashboardItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MoveDashboardItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MoveDashboardItemRequest}
 */
proto.services.central.MoveDashboardItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.DashboardItem.Kind} */ (reader.readEnum());
      msg.setItem(value);
      break;
    case 3:
      var value = new types_dashboard_layout_pb.DashboardItemPosition;
      reader.readMessage(value,types_dashboard_layout_pb.DashboardItemPosition.deserializeBinaryFromReader);
      msg.setNewPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MoveDashboardItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MoveDashboardItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MoveDashboardItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MoveDashboardItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNewPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_dashboard_layout_pb.DashboardItemPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.MoveDashboardItemRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MoveDashboardItemRequest} returns this
 */
proto.services.central.MoveDashboardItemRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.MoveDashboardItemRequest} returns this
 */
proto.services.central.MoveDashboardItemRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MoveDashboardItemRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional types.DashboardItem.Kind item = 2;
 * @return {!proto.types.DashboardItem.Kind}
 */
proto.services.central.MoveDashboardItemRequest.prototype.getItem = function() {
  return /** @type {!proto.types.DashboardItem.Kind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.DashboardItem.Kind} value
 * @return {!proto.services.central.MoveDashboardItemRequest} returns this
 */
proto.services.central.MoveDashboardItemRequest.prototype.setItem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional types.DashboardItemPosition new_position = 3;
 * @return {?proto.types.DashboardItemPosition}
 */
proto.services.central.MoveDashboardItemRequest.prototype.getNewPosition = function() {
  return /** @type{?proto.types.DashboardItemPosition} */ (
    jspb.Message.getWrapperField(this, types_dashboard_layout_pb.DashboardItemPosition, 3));
};


/**
 * @param {?proto.types.DashboardItemPosition|undefined} value
 * @return {!proto.services.central.MoveDashboardItemRequest} returns this
*/
proto.services.central.MoveDashboardItemRequest.prototype.setNewPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.MoveDashboardItemRequest} returns this
 */
proto.services.central.MoveDashboardItemRequest.prototype.clearNewPosition = function() {
  return this.setNewPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MoveDashboardItemRequest.prototype.hasNewPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MoveDashboardItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MoveDashboardItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MoveDashboardItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MoveDashboardItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newLayout: (f = msg.getNewLayout()) && types_dashboard_layout_pb.DashboardLayout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MoveDashboardItemResponse}
 */
proto.services.central.MoveDashboardItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MoveDashboardItemResponse;
  return proto.services.central.MoveDashboardItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MoveDashboardItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MoveDashboardItemResponse}
 */
proto.services.central.MoveDashboardItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_dashboard_layout_pb.DashboardLayout;
      reader.readMessage(value,types_dashboard_layout_pb.DashboardLayout.deserializeBinaryFromReader);
      msg.setNewLayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MoveDashboardItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MoveDashboardItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MoveDashboardItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MoveDashboardItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewLayout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_dashboard_layout_pb.DashboardLayout.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DashboardLayout new_layout = 1;
 * @return {?proto.types.DashboardLayout}
 */
proto.services.central.MoveDashboardItemResponse.prototype.getNewLayout = function() {
  return /** @type{?proto.types.DashboardLayout} */ (
    jspb.Message.getWrapperField(this, types_dashboard_layout_pb.DashboardLayout, 1));
};


/**
 * @param {?proto.types.DashboardLayout|undefined} value
 * @return {!proto.services.central.MoveDashboardItemResponse} returns this
*/
proto.services.central.MoveDashboardItemResponse.prototype.setNewLayout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.MoveDashboardItemResponse} returns this
 */
proto.services.central.MoveDashboardItemResponse.prototype.clearNewLayout = function() {
  return this.setNewLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MoveDashboardItemResponse.prototype.hasNewLayout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteDashboardItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteDashboardItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteDashboardItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDashboardItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteDashboardItemRequest}
 */
proto.services.central.DeleteDashboardItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteDashboardItemRequest;
  return proto.services.central.DeleteDashboardItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteDashboardItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteDashboardItemRequest}
 */
proto.services.central.DeleteDashboardItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.DashboardItem.Kind} */ (reader.readEnum());
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteDashboardItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteDashboardItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteDashboardItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDashboardItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional types.DashboardItem.Kind item = 1;
 * @return {!proto.types.DashboardItem.Kind}
 */
proto.services.central.DeleteDashboardItemRequest.prototype.getItem = function() {
  return /** @type {!proto.types.DashboardItem.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.DashboardItem.Kind} value
 * @return {!proto.services.central.DeleteDashboardItemRequest} returns this
 */
proto.services.central.DeleteDashboardItemRequest.prototype.setItem = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.DeleteDashboardItemRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteDashboardItemRequest} returns this
 */
proto.services.central.DeleteDashboardItemRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteDashboardItemRequest} returns this
 */
proto.services.central.DeleteDashboardItemRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteDashboardItemRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteDashboardItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteDashboardItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteDashboardItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDashboardItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newLayout: (f = msg.getNewLayout()) && types_dashboard_layout_pb.DashboardLayout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteDashboardItemResponse}
 */
proto.services.central.DeleteDashboardItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteDashboardItemResponse;
  return proto.services.central.DeleteDashboardItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteDashboardItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteDashboardItemResponse}
 */
proto.services.central.DeleteDashboardItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_dashboard_layout_pb.DashboardLayout;
      reader.readMessage(value,types_dashboard_layout_pb.DashboardLayout.deserializeBinaryFromReader);
      msg.setNewLayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteDashboardItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteDashboardItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteDashboardItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDashboardItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewLayout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_dashboard_layout_pb.DashboardLayout.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DashboardLayout new_layout = 1;
 * @return {?proto.types.DashboardLayout}
 */
proto.services.central.DeleteDashboardItemResponse.prototype.getNewLayout = function() {
  return /** @type{?proto.types.DashboardLayout} */ (
    jspb.Message.getWrapperField(this, types_dashboard_layout_pb.DashboardLayout, 1));
};


/**
 * @param {?proto.types.DashboardLayout|undefined} value
 * @return {!proto.services.central.DeleteDashboardItemResponse} returns this
*/
proto.services.central.DeleteDashboardItemResponse.prototype.setNewLayout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeleteDashboardItemResponse} returns this
 */
proto.services.central.DeleteDashboardItemResponse.prototype.clearNewLayout = function() {
  return this.setNewLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteDashboardItemResponse.prototype.hasNewLayout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAvailableFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAvailableFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAvailableFeaturesRequest}
 */
proto.services.central.GetAvailableFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAvailableFeaturesRequest;
  return proto.services.central.GetAvailableFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAvailableFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAvailableFeaturesRequest}
 */
proto.services.central.GetAvailableFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAvailableFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAvailableFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAvailableFeaturesRequest} returns this
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAvailableFeaturesRequest} returns this
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAvailableFeaturesRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAvailableFeaturesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAvailableFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAvailableFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuresList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAvailableFeaturesResponse}
 */
proto.services.central.GetAvailableFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAvailableFeaturesResponse;
  return proto.services.central.GetAvailableFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAvailableFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAvailableFeaturesResponse}
 */
proto.services.central.GetAvailableFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.types.Feature>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFeatures(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAvailableFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAvailableFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated types.Feature features = 1;
 * @return {!Array<!proto.types.Feature>}
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.getFeaturesList = function() {
  return /** @type {!Array<!proto.types.Feature>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.types.Feature>} value
 * @return {!proto.services.central.GetAvailableFeaturesResponse} returns this
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.setFeaturesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.types.Feature} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetAvailableFeaturesResponse} returns this
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.addFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAvailableFeaturesResponse} returns this
 */
proto.services.central.GetAvailableFeaturesResponse.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetTreeSelectRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.GetTreeSelectRequest.RequestCase = {
  REQUEST_NOT_SET: 0,
  PARTNER_REQUEST: 1,
  SPACE_REQUEST: 2
};

/**
 * @return {proto.services.central.GetTreeSelectRequest.RequestCase}
 */
proto.services.central.GetTreeSelectRequest.prototype.getRequestCase = function() {
  return /** @type {proto.services.central.GetTreeSelectRequest.RequestCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetTreeSelectRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTreeSelectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTreeSelectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTreeSelectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerRequest: (f = msg.getPartnerRequest()) && proto.services.central.PartnerTreeSelectRequest.toObject(includeInstance, f),
    spaceRequest: (f = msg.getSpaceRequest()) && proto.services.central.SpaceTreeSelectRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTreeSelectRequest}
 */
proto.services.central.GetTreeSelectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTreeSelectRequest;
  return proto.services.central.GetTreeSelectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTreeSelectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTreeSelectRequest}
 */
proto.services.central.GetTreeSelectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.PartnerTreeSelectRequest;
      reader.readMessage(value,proto.services.central.PartnerTreeSelectRequest.deserializeBinaryFromReader);
      msg.setPartnerRequest(value);
      break;
    case 2:
      var value = new proto.services.central.SpaceTreeSelectRequest;
      reader.readMessage(value,proto.services.central.SpaceTreeSelectRequest.deserializeBinaryFromReader);
      msg.setSpaceRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTreeSelectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTreeSelectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTreeSelectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.PartnerTreeSelectRequest.serializeBinaryToWriter
    );
  }
  f = message.getSpaceRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.SpaceTreeSelectRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional PartnerTreeSelectRequest partner_request = 1;
 * @return {?proto.services.central.PartnerTreeSelectRequest}
 */
proto.services.central.GetTreeSelectRequest.prototype.getPartnerRequest = function() {
  return /** @type{?proto.services.central.PartnerTreeSelectRequest} */ (
    jspb.Message.getWrapperField(this, proto.services.central.PartnerTreeSelectRequest, 1));
};


/**
 * @param {?proto.services.central.PartnerTreeSelectRequest|undefined} value
 * @return {!proto.services.central.GetTreeSelectRequest} returns this
*/
proto.services.central.GetTreeSelectRequest.prototype.setPartnerRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetTreeSelectRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTreeSelectRequest} returns this
 */
proto.services.central.GetTreeSelectRequest.prototype.clearPartnerRequest = function() {
  return this.setPartnerRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTreeSelectRequest.prototype.hasPartnerRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SpaceTreeSelectRequest space_request = 2;
 * @return {?proto.services.central.SpaceTreeSelectRequest}
 */
proto.services.central.GetTreeSelectRequest.prototype.getSpaceRequest = function() {
  return /** @type{?proto.services.central.SpaceTreeSelectRequest} */ (
    jspb.Message.getWrapperField(this, proto.services.central.SpaceTreeSelectRequest, 2));
};


/**
 * @param {?proto.services.central.SpaceTreeSelectRequest|undefined} value
 * @return {!proto.services.central.GetTreeSelectRequest} returns this
*/
proto.services.central.GetTreeSelectRequest.prototype.setSpaceRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.GetTreeSelectRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTreeSelectRequest} returns this
 */
proto.services.central.GetTreeSelectRequest.prototype.clearSpaceRequest = function() {
  return this.setSpaceRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTreeSelectRequest.prototype.hasSpaceRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.PartnerTreeSelectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.PartnerTreeSelectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.PartnerTreeSelectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.PartnerTreeSelectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchQuery: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.PartnerTreeSelectRequest}
 */
proto.services.central.PartnerTreeSelectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.PartnerTreeSelectRequest;
  return proto.services.central.PartnerTreeSelectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.PartnerTreeSelectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.PartnerTreeSelectRequest}
 */
proto.services.central.PartnerTreeSelectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.PartnerTreeSelectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.PartnerTreeSelectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.PartnerTreeSelectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.PartnerTreeSelectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string partner_id = 1;
 * @return {string}
 */
proto.services.central.PartnerTreeSelectRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.PartnerTreeSelectRequest} returns this
 */
proto.services.central.PartnerTreeSelectRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search_query = 2;
 * @return {string}
 */
proto.services.central.PartnerTreeSelectRequest.prototype.getSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.PartnerTreeSelectRequest} returns this
 */
proto.services.central.PartnerTreeSelectRequest.prototype.setSearchQuery = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.PartnerTreeSelectRequest} returns this
 */
proto.services.central.PartnerTreeSelectRequest.prototype.clearSearchQuery = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.PartnerTreeSelectRequest.prototype.hasSearchQuery = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SpaceTreeSelectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SpaceTreeSelectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceTreeSelectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchQuery: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SpaceTreeSelectRequest}
 */
proto.services.central.SpaceTreeSelectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SpaceTreeSelectRequest;
  return proto.services.central.SpaceTreeSelectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SpaceTreeSelectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SpaceTreeSelectRequest}
 */
proto.services.central.SpaceTreeSelectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SpaceTreeSelectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SpaceTreeSelectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SpaceTreeSelectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceTreeSelectRequest} returns this
 */
proto.services.central.SpaceTreeSelectRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner_id = 2;
 * @return {string}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceTreeSelectRequest} returns this
 */
proto.services.central.SpaceTreeSelectRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search_query = 3;
 * @return {string}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.getSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SpaceTreeSelectRequest} returns this
 */
proto.services.central.SpaceTreeSelectRequest.prototype.setSearchQuery = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.SpaceTreeSelectRequest} returns this
 */
proto.services.central.SpaceTreeSelectRequest.prototype.clearSearchQuery = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.SpaceTreeSelectRequest.prototype.hasSearchQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTreeSelectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTreeSelectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTreeSelectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTreeSelectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.services.central.GetTreeSelectResponse.Node.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTreeSelectResponse}
 */
proto.services.central.GetTreeSelectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTreeSelectResponse;
  return proto.services.central.GetTreeSelectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTreeSelectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTreeSelectResponse}
 */
proto.services.central.GetTreeSelectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.GetTreeSelectResponse.Node;
      reader.readMessage(value,proto.services.central.GetTreeSelectResponse.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTreeSelectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTreeSelectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTreeSelectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.GetTreeSelectResponse.Node.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTreeSelectResponse.Node.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTreeSelectResponse.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTreeSelectResponse.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectResponse.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.services.central.GetTreeSelectResponse.Node.toObject, includeInstance),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTreeSelectResponse.Node}
 */
proto.services.central.GetTreeSelectResponse.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTreeSelectResponse.Node;
  return proto.services.central.GetTreeSelectResponse.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTreeSelectResponse.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTreeSelectResponse.Node}
 */
proto.services.central.GetTreeSelectResponse.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.services.central.GetTreeSelectResponse.Node;
      reader.readMessage(value,proto.services.central.GetTreeSelectResponse.Node.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTreeSelectResponse.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTreeSelectResponse.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTreeSelectResponse.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.services.central.GetTreeSelectResponse.Node.serializeBinaryToWriter
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTreeSelectResponse.Node} returns this
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTreeSelectResponse.Node} returns this
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Node children = 3;
 * @return {!Array<!proto.services.central.GetTreeSelectResponse.Node>}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.services.central.GetTreeSelectResponse.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.GetTreeSelectResponse.Node, 3));
};


/**
 * @param {!Array<!proto.services.central.GetTreeSelectResponse.Node>} value
 * @return {!proto.services.central.GetTreeSelectResponse.Node} returns this
*/
proto.services.central.GetTreeSelectResponse.Node.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.services.central.GetTreeSelectResponse.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTreeSelectResponse.Node}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.services.central.GetTreeSelectResponse.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTreeSelectResponse.Node} returns this
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional bool is_disabled = 4;
 * @return {boolean}
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetTreeSelectResponse.Node} returns this
 */
proto.services.central.GetTreeSelectResponse.Node.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated Node nodes = 1;
 * @return {!Array<!proto.services.central.GetTreeSelectResponse.Node>}
 */
proto.services.central.GetTreeSelectResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.services.central.GetTreeSelectResponse.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.GetTreeSelectResponse.Node, 1));
};


/**
 * @param {!Array<!proto.services.central.GetTreeSelectResponse.Node>} value
 * @return {!proto.services.central.GetTreeSelectResponse} returns this
*/
proto.services.central.GetTreeSelectResponse.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.GetTreeSelectResponse.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTreeSelectResponse.Node}
 */
proto.services.central.GetTreeSelectResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.GetTreeSelectResponse.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTreeSelectResponse} returns this
 */
proto.services.central.GetTreeSelectResponse.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationWidgetDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationWidgetDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationWidgetDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationWidgetDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationWidgetDataRequest}
 */
proto.services.central.GetAutomationWidgetDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationWidgetDataRequest;
  return proto.services.central.GetAutomationWidgetDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationWidgetDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationWidgetDataRequest}
 */
proto.services.central.GetAutomationWidgetDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationWidgetDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationWidgetDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationWidgetDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationWidgetDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAutomationWidgetDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationWidgetDataRequest} returns this
 */
proto.services.central.GetAutomationWidgetDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAutomationWidgetDataResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationWidgetDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationWidgetDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationWidgetDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeAutomationsCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    recentActionsCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recentFailedActionsCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dispatchedActionsList: jspb.Message.toObjectList(msg.getDispatchedActionsList(),
    types_automations_pb.DispatchedAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationWidgetDataResponse}
 */
proto.services.central.GetAutomationWidgetDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationWidgetDataResponse;
  return proto.services.central.GetAutomationWidgetDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationWidgetDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationWidgetDataResponse}
 */
proto.services.central.GetAutomationWidgetDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveAutomationsCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecentActionsCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecentFailedActionsCount(value);
      break;
    case 4:
      var value = new types_automations_pb.DispatchedAction;
      reader.readMessage(value,types_automations_pb.DispatchedAction.deserializeBinaryFromReader);
      msg.addDispatchedActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationWidgetDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationWidgetDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationWidgetDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveAutomationsCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRecentActionsCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRecentFailedActionsCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDispatchedActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      types_automations_pb.DispatchedAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 active_automations_count = 1;
 * @return {number}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.getActiveAutomationsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAutomationWidgetDataResponse} returns this
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.setActiveAutomationsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 recent_actions_count = 2;
 * @return {number}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.getRecentActionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAutomationWidgetDataResponse} returns this
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.setRecentActionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 recent_failed_actions_count = 3;
 * @return {number}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.getRecentFailedActionsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAutomationWidgetDataResponse} returns this
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.setRecentFailedActionsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated types.DispatchedAction dispatched_actions = 4;
 * @return {!Array<!proto.types.DispatchedAction>}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.getDispatchedActionsList = function() {
  return /** @type{!Array<!proto.types.DispatchedAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_automations_pb.DispatchedAction, 4));
};


/**
 * @param {!Array<!proto.types.DispatchedAction>} value
 * @return {!proto.services.central.GetAutomationWidgetDataResponse} returns this
*/
proto.services.central.GetAutomationWidgetDataResponse.prototype.setDispatchedActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.types.DispatchedAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DispatchedAction}
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.addDispatchedActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.types.DispatchedAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationWidgetDataResponse} returns this
 */
proto.services.central.GetAutomationWidgetDataResponse.prototype.clearDispatchedActionsList = function() {
  return this.setDispatchedActionsList([]);
};


/**
 * @enum {number}
 */
proto.services.central.ChartType = {
  LINE: 0,
  BAR: 1,
  TABULAR: 2,
  MIXED: 3
};

goog.object.extend(exports, proto.services.central);
