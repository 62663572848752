
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AddDeviceToSpaceRequest } from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient, spaceServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { GetSpacesRequest, Pagination } from "zaehlerfreunde-central/space_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface SpaceItem {
  id: string;
  name: string;
}

@Component
export default class AddDeviceToSpaceDialog extends Vue {
  @Prop() device: Device;

  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  spaces: RemoteData<UserError, SpaceItem[]> = initialized;
  addDeviceToSpaceCall: RemoteCall<UserError> = initialized;

  selectedSpaceId: string | null = null;
  getSpacesTimeoutHandle: number | null = null;
  searchFilter: string = "";

  mounted(): void {
    this.getSpaces();
  }

  @Watch("searchFilter")
  onSearchFilterChanged(): void {
    clearTimeout(this.getSpacesTimeoutHandle ?? undefined);
    this.getSpacesTimeoutHandle = setTimeout(this.getSpaces, 500);
  }

  async onAddDeviceToSpaceClicked(): Promise<void> {
    const spaceId = this.selectedSpaceId;

    if (!spaceId) {
      return;
    }

    try {
      this.addDeviceToSpaceCall = pending;
      const request = new AddDeviceToSpaceRequest();
      request.setDeviceId(this.$route.params.deviceId);
      request.setSpaceId(spaceId);

      const response = await deviceServiceClient.addDeviceToSpace(request, {});
      this.addDeviceToSpaceCall = success(void response);
      this.$emit("onAddDeviceToSpace");
    } catch (error) {
      this.addDeviceToSpaceCall = failure(userErrorFrom(error));
    }
  }

  async getSpaces(): Promise<void> {
    try {
      this.spaces = pending;

      const request = new GetSpacesRequest();
      request.setFilter(this.searchFilter);
      request.setPartnerSpaces(true);
      request.setPagination(new Pagination().setPage(0).setPageSize(20));

      if (this.partner.data) {
        request.setPartnerId(this.partner.data.getId());
      }

      const response = await spaceServiceClient.getSpaces(request, {});

      this.spaces = success(
        response.getSpacesList().map((s) => ({
          id: s.getId(),
          name: s.getName(),
        }))
      );
    } catch (error) {
      this.spaces = failure(userErrorFrom(error));
    }
  }
}
