
import { adminModule } from "@/store/modules/admin";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetUsersRequest } from "zaehlerfreunde-central/user_service_pb";
import { spaceServiceClient, userServiceClient } from "@/config/service-clients";
import { GetSpaceRequest, Pagination } from "zaehlerfreunde-central/space_service_pb";
import UsersTable, { UserRow } from "@/components/users-table/UsersTable.vue";
import { User } from "zaehlerfreunde-proto-types/user_pb";

import RemoveUserDialog from "@/components/remove-user-dialog/RemoveUserDialog.vue";
import AddUserToSpaceDialog from "./AddUserToSpaceDialog.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SearchParams } from "@/components/devices-table/DevicesTable.vue";

@Component({
  components: {
    UsersTable,
    RemoveUserDialog,
    AddUserToSpaceDialog,
  },
})
export default class SpaceUsers extends Vue {
  @adminModule.State userPages: RemoteData<UserError, number>;
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  @Prop() userIds: string[];
  @Prop() spaceInfo: RemoteData<UserError, Space>;

  spaceId: string = this.$route.params.spaceId;
  removeUserDialog: boolean = false;
  selectedUser: User | null = null;

  usersList: RemoteData<UserError, User[]> = initialized;

  addUserDialog: boolean = false;

  page = 1;

  itemControls = [
    {
      icon: "mdi-link-variant-off",
      event: "remove-user",
    },
  ];
  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "add-user",
    },
  ];

  mounted(): void {
    this.loadUsers();
  }
  userAdded(): void {
    this.addUserDialog = false;
    this.updateUsersList();
  }

  @Watch("userIds")
  async loadUsers(): Promise<void> {
    if (this.userIds.length != 0) {
      this.usersList = pending;
      const request = new GetUsersRequest();
      request.setUserIdsList(this.userIds);
      request.setPagination(new Pagination().setPage(0).setPageSize(1000));
      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      try {
        const response = await userServiceClient.getUsers(request, {});
        this.usersList = success(response.getUsersList());
      } catch (error) {
        this.usersList = failure(userErrorFrom(error));
      }
    } else {
      this.usersList = success([]);
    }
  }
  openRemoveUserDialog(row: UserRow): void {
    this.selectedUser = row.user;
    this.removeUserDialog = true;
  }

  async updateUsersList(): Promise<void> {
    this.removeUserDialog = false;
    const request = new GetSpaceRequest();
    request.setSpaceId(this.spaceId);

    const response = await spaceServiceClient.getSpace(request, {});
    const space = response.getSpace();
    if (space) {
      this.$emit("update-user-list", space.getAssociatedUsersIdsList());
    }
  }
}
