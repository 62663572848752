/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_space_service_pb from '../../services/central/space_service_pb';


export class SpaceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetSpaces = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpaces',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpacesRequest,
    services_central_space_service_pb.GetSpacesResponse,
    (request: services_central_space_service_pb.GetSpacesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpacesResponse.deserializeBinary
  );

  getSpaces(
    request: services_central_space_service_pb.GetSpacesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpacesResponse>;

  getSpaces(
    request: services_central_space_service_pb.GetSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpacesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpacesResponse>;

  getSpaces(
    request: services_central_space_service_pb.GetSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpaces',
        request,
        metadata || {},
        this.methodInfoGetSpaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpaces',
    request,
    metadata || {},
    this.methodInfoGetSpaces);
  }

  methodInfoGetSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpaceRequest,
    services_central_space_service_pb.GetSpaceResponse,
    (request: services_central_space_service_pb.GetSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpaceResponse.deserializeBinary
  );

  getSpace(
    request: services_central_space_service_pb.GetSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpaceResponse>;

  getSpace(
    request: services_central_space_service_pb.GetSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpaceResponse>;

  getSpace(
    request: services_central_space_service_pb.GetSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpace',
        request,
        metadata || {},
        this.methodInfoGetSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpace',
    request,
    metadata || {},
    this.methodInfoGetSpace);
  }

  methodInfoDeleteSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/DeleteSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.DeleteSpaceRequest,
    services_central_space_service_pb.DeleteSpaceResponse,
    (request: services_central_space_service_pb.DeleteSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.DeleteSpaceResponse.deserializeBinary
  );

  deleteSpace(
    request: services_central_space_service_pb.DeleteSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.DeleteSpaceResponse>;

  deleteSpace(
    request: services_central_space_service_pb.DeleteSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.DeleteSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.DeleteSpaceResponse>;

  deleteSpace(
    request: services_central_space_service_pb.DeleteSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.DeleteSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/DeleteSpace',
        request,
        metadata || {},
        this.methodInfoDeleteSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/DeleteSpace',
    request,
    metadata || {},
    this.methodInfoDeleteSpace);
  }

  methodInfoCreateSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/CreateSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.CreateSpaceRequest,
    services_central_space_service_pb.CreateSpaceResponse,
    (request: services_central_space_service_pb.CreateSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.CreateSpaceResponse.deserializeBinary
  );

  createSpace(
    request: services_central_space_service_pb.CreateSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.CreateSpaceResponse>;

  createSpace(
    request: services_central_space_service_pb.CreateSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.CreateSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.CreateSpaceResponse>;

  createSpace(
    request: services_central_space_service_pb.CreateSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.CreateSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/CreateSpace',
        request,
        metadata || {},
        this.methodInfoCreateSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/CreateSpace',
    request,
    metadata || {},
    this.methodInfoCreateSpace);
  }

  methodInfoUpdateSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/UpdateSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.UpdateSpaceRequest,
    services_central_space_service_pb.UpdateSpaceResponse,
    (request: services_central_space_service_pb.UpdateSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.UpdateSpaceResponse.deserializeBinary
  );

  updateSpace(
    request: services_central_space_service_pb.UpdateSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.UpdateSpaceResponse>;

  updateSpace(
    request: services_central_space_service_pb.UpdateSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.UpdateSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.UpdateSpaceResponse>;

  updateSpace(
    request: services_central_space_service_pb.UpdateSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.UpdateSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/UpdateSpace',
        request,
        metadata || {},
        this.methodInfoUpdateSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/UpdateSpace',
    request,
    metadata || {},
    this.methodInfoUpdateSpace);
  }

  methodInfoGetSpaceCategories = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpaceCategories',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpaceCategoriesRequest,
    services_central_space_service_pb.GetSpaceCategoriesResponse,
    (request: services_central_space_service_pb.GetSpaceCategoriesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpaceCategoriesResponse.deserializeBinary
  );

  getSpaceCategories(
    request: services_central_space_service_pb.GetSpaceCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpaceCategoriesResponse>;

  getSpaceCategories(
    request: services_central_space_service_pb.GetSpaceCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceCategoriesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpaceCategoriesResponse>;

  getSpaceCategories(
    request: services_central_space_service_pb.GetSpaceCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpaceCategories',
        request,
        metadata || {},
        this.methodInfoGetSpaceCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpaceCategories',
    request,
    metadata || {},
    this.methodInfoGetSpaceCategories);
  }

  methodInfoInviteUserToSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/InviteUserToSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.InviteUserToSpaceRequest,
    services_central_space_service_pb.InviteUserToSpaceResponse,
    (request: services_central_space_service_pb.InviteUserToSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.InviteUserToSpaceResponse.deserializeBinary
  );

  inviteUserToSpace(
    request: services_central_space_service_pb.InviteUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.InviteUserToSpaceResponse>;

  inviteUserToSpace(
    request: services_central_space_service_pb.InviteUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.InviteUserToSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.InviteUserToSpaceResponse>;

  inviteUserToSpace(
    request: services_central_space_service_pb.InviteUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.InviteUserToSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/InviteUserToSpace',
        request,
        metadata || {},
        this.methodInfoInviteUserToSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/InviteUserToSpace',
    request,
    metadata || {},
    this.methodInfoInviteUserToSpace);
  }

  methodInfoRemoveUserFromSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/RemoveUserFromSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.RemoveUserFromSpaceRequest,
    services_central_space_service_pb.RemoveUserFromSpaceResponse,
    (request: services_central_space_service_pb.RemoveUserFromSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.RemoveUserFromSpaceResponse.deserializeBinary
  );

  removeUserFromSpace(
    request: services_central_space_service_pb.RemoveUserFromSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.RemoveUserFromSpaceResponse>;

  removeUserFromSpace(
    request: services_central_space_service_pb.RemoveUserFromSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.RemoveUserFromSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.RemoveUserFromSpaceResponse>;

  removeUserFromSpace(
    request: services_central_space_service_pb.RemoveUserFromSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.RemoveUserFromSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/RemoveUserFromSpace',
        request,
        metadata || {},
        this.methodInfoRemoveUserFromSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/RemoveUserFromSpace',
    request,
    metadata || {},
    this.methodInfoRemoveUserFromSpace);
  }

  methodInfoRemoveAllUsersFromSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/RemoveAllUsersFromSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.RemoveAllUsersFromSpaceRequest,
    services_central_space_service_pb.RemoveAllUsersFromSpaceResponse,
    (request: services_central_space_service_pb.RemoveAllUsersFromSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.RemoveAllUsersFromSpaceResponse.deserializeBinary
  );

  removeAllUsersFromSpace(
    request: services_central_space_service_pb.RemoveAllUsersFromSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.RemoveAllUsersFromSpaceResponse>;

  removeAllUsersFromSpace(
    request: services_central_space_service_pb.RemoveAllUsersFromSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.RemoveAllUsersFromSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.RemoveAllUsersFromSpaceResponse>;

  removeAllUsersFromSpace(
    request: services_central_space_service_pb.RemoveAllUsersFromSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.RemoveAllUsersFromSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/RemoveAllUsersFromSpace',
        request,
        metadata || {},
        this.methodInfoRemoveAllUsersFromSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/RemoveAllUsersFromSpace',
    request,
    metadata || {},
    this.methodInfoRemoveAllUsersFromSpace);
  }

  methodInfoGetConsumptionTarget = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetConsumptionTarget',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetConsumptionTargetRequest,
    services_central_space_service_pb.GetConsumptionTargetResponse,
    (request: services_central_space_service_pb.GetConsumptionTargetRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetConsumptionTargetResponse.deserializeBinary
  );

  getConsumptionTarget(
    request: services_central_space_service_pb.GetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetConsumptionTargetResponse>;

  getConsumptionTarget(
    request: services_central_space_service_pb.GetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetConsumptionTargetResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetConsumptionTargetResponse>;

  getConsumptionTarget(
    request: services_central_space_service_pb.GetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetConsumptionTargetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetConsumptionTarget',
        request,
        metadata || {},
        this.methodInfoGetConsumptionTarget,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetConsumptionTarget',
    request,
    metadata || {},
    this.methodInfoGetConsumptionTarget);
  }

  methodInfoSetConsumptionTarget = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/SetConsumptionTarget',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.SetConsumptionTargetRequest,
    services_central_space_service_pb.SetConsumptionTargetResponse,
    (request: services_central_space_service_pb.SetConsumptionTargetRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.SetConsumptionTargetResponse.deserializeBinary
  );

  setConsumptionTarget(
    request: services_central_space_service_pb.SetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.SetConsumptionTargetResponse>;

  setConsumptionTarget(
    request: services_central_space_service_pb.SetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetConsumptionTargetResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.SetConsumptionTargetResponse>;

  setConsumptionTarget(
    request: services_central_space_service_pb.SetConsumptionTargetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetConsumptionTargetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/SetConsumptionTarget',
        request,
        metadata || {},
        this.methodInfoSetConsumptionTarget,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/SetConsumptionTarget',
    request,
    metadata || {},
    this.methodInfoSetConsumptionTarget);
  }

  methodInfoGetAllCountries = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetAllCountries',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetAllCountriesRequest,
    services_central_space_service_pb.GetAllCountriesResponse,
    (request: services_central_space_service_pb.GetAllCountriesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetAllCountriesResponse.deserializeBinary
  );

  getAllCountries(
    request: services_central_space_service_pb.GetAllCountriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetAllCountriesResponse>;

  getAllCountries(
    request: services_central_space_service_pb.GetAllCountriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetAllCountriesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetAllCountriesResponse>;

  getAllCountries(
    request: services_central_space_service_pb.GetAllCountriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetAllCountriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetAllCountries',
        request,
        metadata || {},
        this.methodInfoGetAllCountries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetAllCountries',
    request,
    metadata || {},
    this.methodInfoGetAllCountries);
  }

  methodInfoGetMarketPremiumConfigs = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetMarketPremiumConfigs',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetMarketPremiumConfigsRequest,
    services_central_space_service_pb.GetMarketPremiumConfigsResponse,
    (request: services_central_space_service_pb.GetMarketPremiumConfigsRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetMarketPremiumConfigsResponse.deserializeBinary
  );

  getMarketPremiumConfigs(
    request: services_central_space_service_pb.GetMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetMarketPremiumConfigsResponse>;

  getMarketPremiumConfigs(
    request: services_central_space_service_pb.GetMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetMarketPremiumConfigsResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetMarketPremiumConfigsResponse>;

  getMarketPremiumConfigs(
    request: services_central_space_service_pb.GetMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetMarketPremiumConfigsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetMarketPremiumConfigs',
        request,
        metadata || {},
        this.methodInfoGetMarketPremiumConfigs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetMarketPremiumConfigs',
    request,
    metadata || {},
    this.methodInfoGetMarketPremiumConfigs);
  }

  methodInfoUpdateMarketPremiumConfigs = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/UpdateMarketPremiumConfigs',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.UpdateMarketPremiumConfigsRequest,
    services_central_space_service_pb.UpdateMarketPremiumConfigsResponse,
    (request: services_central_space_service_pb.UpdateMarketPremiumConfigsRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.UpdateMarketPremiumConfigsResponse.deserializeBinary
  );

  updateMarketPremiumConfigs(
    request: services_central_space_service_pb.UpdateMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.UpdateMarketPremiumConfigsResponse>;

  updateMarketPremiumConfigs(
    request: services_central_space_service_pb.UpdateMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.UpdateMarketPremiumConfigsResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.UpdateMarketPremiumConfigsResponse>;

  updateMarketPremiumConfigs(
    request: services_central_space_service_pb.UpdateMarketPremiumConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.UpdateMarketPremiumConfigsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/UpdateMarketPremiumConfigs',
        request,
        metadata || {},
        this.methodInfoUpdateMarketPremiumConfigs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/UpdateMarketPremiumConfigs',
    request,
    metadata || {},
    this.methodInfoUpdateMarketPremiumConfigs);
  }

  methodInfoGetSpaceAccessRoles = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpaceAccessRoles',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpaceAccessRolesRequest,
    services_central_space_service_pb.GetSpaceAccessRolesResponse,
    (request: services_central_space_service_pb.GetSpaceAccessRolesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpaceAccessRolesResponse.deserializeBinary
  );

  getSpaceAccessRoles(
    request: services_central_space_service_pb.GetSpaceAccessRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpaceAccessRolesResponse>;

  getSpaceAccessRoles(
    request: services_central_space_service_pb.GetSpaceAccessRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceAccessRolesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpaceAccessRolesResponse>;

  getSpaceAccessRoles(
    request: services_central_space_service_pb.GetSpaceAccessRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceAccessRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpaceAccessRoles',
        request,
        metadata || {},
        this.methodInfoGetSpaceAccessRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpaceAccessRoles',
    request,
    metadata || {},
    this.methodInfoGetSpaceAccessRoles);
  }

  methodInfoGetChildSpaces = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetChildSpaces',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetChildSpacesRequest,
    services_central_space_service_pb.GetChildSpacesResponse,
    (request: services_central_space_service_pb.GetChildSpacesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetChildSpacesResponse.deserializeBinary
  );

  getChildSpaces(
    request: services_central_space_service_pb.GetChildSpacesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetChildSpacesResponse>;

  getChildSpaces(
    request: services_central_space_service_pb.GetChildSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetChildSpacesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetChildSpacesResponse>;

  getChildSpaces(
    request: services_central_space_service_pb.GetChildSpacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetChildSpacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetChildSpaces',
        request,
        metadata || {},
        this.methodInfoGetChildSpaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetChildSpaces',
    request,
    metadata || {},
    this.methodInfoGetChildSpaces);
  }

  methodInfoAddUserToSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/AddUserToSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.AddUserToSpaceRequest,
    services_central_space_service_pb.AddUserToSpaceResponse,
    (request: services_central_space_service_pb.AddUserToSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.AddUserToSpaceResponse.deserializeBinary
  );

  addUserToSpace(
    request: services_central_space_service_pb.AddUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.AddUserToSpaceResponse>;

  addUserToSpace(
    request: services_central_space_service_pb.AddUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.AddUserToSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.AddUserToSpaceResponse>;

  addUserToSpace(
    request: services_central_space_service_pb.AddUserToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.AddUserToSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/AddUserToSpace',
        request,
        metadata || {},
        this.methodInfoAddUserToSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/AddUserToSpace',
    request,
    metadata || {},
    this.methodInfoAddUserToSpace);
  }

  methodInfoGetSpaceKPIs = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpaceKPIs',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpaceKPIsRequest,
    services_central_space_service_pb.GetSpaceKPIsResponse,
    (request: services_central_space_service_pb.GetSpaceKPIsRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpaceKPIsResponse.deserializeBinary
  );

  getSpaceKPIs(
    request: services_central_space_service_pb.GetSpaceKPIsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpaceKPIsResponse>;

  getSpaceKPIs(
    request: services_central_space_service_pb.GetSpaceKPIsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceKPIsResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpaceKPIsResponse>;

  getSpaceKPIs(
    request: services_central_space_service_pb.GetSpaceKPIsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpaceKPIsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpaceKPIs',
        request,
        metadata || {},
        this.methodInfoGetSpaceKPIs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpaceKPIs',
    request,
    metadata || {},
    this.methodInfoGetSpaceKPIs);
  }

  methodInfoGetSpacePermissions = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetSpacePermissions',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetSpacePermissionsRequest,
    services_central_space_service_pb.GetSpacePermissionsResponse,
    (request: services_central_space_service_pb.GetSpacePermissionsRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetSpacePermissionsResponse.deserializeBinary
  );

  getSpacePermissions(
    request: services_central_space_service_pb.GetSpacePermissionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetSpacePermissionsResponse>;

  getSpacePermissions(
    request: services_central_space_service_pb.GetSpacePermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpacePermissionsResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetSpacePermissionsResponse>;

  getSpacePermissions(
    request: services_central_space_service_pb.GetSpacePermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetSpacePermissionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetSpacePermissions',
        request,
        metadata || {},
        this.methodInfoGetSpacePermissions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetSpacePermissions',
    request,
    metadata || {},
    this.methodInfoGetSpacePermissions);
  }

  methodInfoIsDirectionAvailable = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/IsDirectionAvailable',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.IsDirectionAvailableRequest,
    services_central_space_service_pb.IsDirectionAvailableResponse,
    (request: services_central_space_service_pb.IsDirectionAvailableRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.IsDirectionAvailableResponse.deserializeBinary
  );

  isDirectionAvailable(
    request: services_central_space_service_pb.IsDirectionAvailableRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.IsDirectionAvailableResponse>;

  isDirectionAvailable(
    request: services_central_space_service_pb.IsDirectionAvailableRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.IsDirectionAvailableResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.IsDirectionAvailableResponse>;

  isDirectionAvailable(
    request: services_central_space_service_pb.IsDirectionAvailableRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.IsDirectionAvailableResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/IsDirectionAvailable',
        request,
        metadata || {},
        this.methodInfoIsDirectionAvailable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/IsDirectionAvailable',
    request,
    metadata || {},
    this.methodInfoIsDirectionAvailable);
  }

  methodInfoGetReferenceValues = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetReferenceValues',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetReferenceValuesRequest,
    services_central_space_service_pb.GetReferenceValuesResponse,
    (request: services_central_space_service_pb.GetReferenceValuesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetReferenceValuesResponse.deserializeBinary
  );

  getReferenceValues(
    request: services_central_space_service_pb.GetReferenceValuesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetReferenceValuesResponse>;

  getReferenceValues(
    request: services_central_space_service_pb.GetReferenceValuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetReferenceValuesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetReferenceValuesResponse>;

  getReferenceValues(
    request: services_central_space_service_pb.GetReferenceValuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetReferenceValuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetReferenceValues',
        request,
        metadata || {},
        this.methodInfoGetReferenceValues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetReferenceValues',
    request,
    metadata || {},
    this.methodInfoGetReferenceValues);
  }

  methodInfoSetReferenceValue = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/SetReferenceValue',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.SetReferenceValueRequest,
    services_central_space_service_pb.SetReferenceValueResponse,
    (request: services_central_space_service_pb.SetReferenceValueRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.SetReferenceValueResponse.deserializeBinary
  );

  setReferenceValue(
    request: services_central_space_service_pb.SetReferenceValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.SetReferenceValueResponse>;

  setReferenceValue(
    request: services_central_space_service_pb.SetReferenceValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetReferenceValueResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.SetReferenceValueResponse>;

  setReferenceValue(
    request: services_central_space_service_pb.SetReferenceValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetReferenceValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/SetReferenceValue',
        request,
        metadata || {},
        this.methodInfoSetReferenceValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/SetReferenceValue',
    request,
    metadata || {},
    this.methodInfoSetReferenceValue);
  }

  methodInfoGetReferenceValueTypes = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetReferenceValueTypes',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetReferenceValueTypesRequest,
    services_central_space_service_pb.GetReferenceValueTypesResponse,
    (request: services_central_space_service_pb.GetReferenceValueTypesRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetReferenceValueTypesResponse.deserializeBinary
  );

  getReferenceValueTypes(
    request: services_central_space_service_pb.GetReferenceValueTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetReferenceValueTypesResponse>;

  getReferenceValueTypes(
    request: services_central_space_service_pb.GetReferenceValueTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetReferenceValueTypesResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetReferenceValueTypesResponse>;

  getReferenceValueTypes(
    request: services_central_space_service_pb.GetReferenceValueTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetReferenceValueTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetReferenceValueTypes',
        request,
        metadata || {},
        this.methodInfoGetReferenceValueTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetReferenceValueTypes',
    request,
    metadata || {},
    this.methodInfoGetReferenceValueTypes);
  }

  methodInfoDeleteReferenceValue = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/DeleteReferenceValue',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.DeleteReferenceValueRequest,
    services_central_space_service_pb.DeleteReferenceValueResponse,
    (request: services_central_space_service_pb.DeleteReferenceValueRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.DeleteReferenceValueResponse.deserializeBinary
  );

  deleteReferenceValue(
    request: services_central_space_service_pb.DeleteReferenceValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.DeleteReferenceValueResponse>;

  deleteReferenceValue(
    request: services_central_space_service_pb.DeleteReferenceValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.DeleteReferenceValueResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.DeleteReferenceValueResponse>;

  deleteReferenceValue(
    request: services_central_space_service_pb.DeleteReferenceValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.DeleteReferenceValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/DeleteReferenceValue',
        request,
        metadata || {},
        this.methodInfoDeleteReferenceValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/DeleteReferenceValue',
    request,
    metadata || {},
    this.methodInfoDeleteReferenceValue);
  }

  methodInfoGetGasEnergyValue = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetGasEnergyValue',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetGasEnergyValueRequest,
    services_central_space_service_pb.GetGasEnergyValueResponse,
    (request: services_central_space_service_pb.GetGasEnergyValueRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetGasEnergyValueResponse.deserializeBinary
  );

  getGasEnergyValue(
    request: services_central_space_service_pb.GetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetGasEnergyValueResponse>;

  getGasEnergyValue(
    request: services_central_space_service_pb.GetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetGasEnergyValueResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetGasEnergyValueResponse>;

  getGasEnergyValue(
    request: services_central_space_service_pb.GetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetGasEnergyValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetGasEnergyValue',
        request,
        metadata || {},
        this.methodInfoGetGasEnergyValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetGasEnergyValue',
    request,
    metadata || {},
    this.methodInfoGetGasEnergyValue);
  }

  methodInfoSetGasEnergyValue = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/SetGasEnergyValue',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.SetGasEnergyValueRequest,
    services_central_space_service_pb.SetGasEnergyValueResponse,
    (request: services_central_space_service_pb.SetGasEnergyValueRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.SetGasEnergyValueResponse.deserializeBinary
  );

  setGasEnergyValue(
    request: services_central_space_service_pb.SetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.SetGasEnergyValueResponse>;

  setGasEnergyValue(
    request: services_central_space_service_pb.SetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetGasEnergyValueResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.SetGasEnergyValueResponse>;

  setGasEnergyValue(
    request: services_central_space_service_pb.SetGasEnergyValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.SetGasEnergyValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/SetGasEnergyValue',
        request,
        metadata || {},
        this.methodInfoSetGasEnergyValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/SetGasEnergyValue',
    request,
    metadata || {},
    this.methodInfoSetGasEnergyValue);
  }

  methodInfoGetCustomerIdentifier = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/GetCustomerIdentifier',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.GetCustomerIdentifierRequest,
    services_central_space_service_pb.GetCustomerIdentifierResponse,
    (request: services_central_space_service_pb.GetCustomerIdentifierRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.GetCustomerIdentifierResponse.deserializeBinary
  );

  getCustomerIdentifier(
    request: services_central_space_service_pb.GetCustomerIdentifierRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.GetCustomerIdentifierResponse>;

  getCustomerIdentifier(
    request: services_central_space_service_pb.GetCustomerIdentifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetCustomerIdentifierResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.GetCustomerIdentifierResponse>;

  getCustomerIdentifier(
    request: services_central_space_service_pb.GetCustomerIdentifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.GetCustomerIdentifierResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/GetCustomerIdentifier',
        request,
        metadata || {},
        this.methodInfoGetCustomerIdentifier,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/GetCustomerIdentifier',
    request,
    metadata || {},
    this.methodInfoGetCustomerIdentifier);
  }

  methodInfoMapCustomerToSpace = new grpcWeb.MethodDescriptor(
    '/services.central.SpaceService/MapCustomerToSpace',
    grpcWeb.MethodType.UNARY,
    services_central_space_service_pb.MapCustomerToSpaceRequest,
    services_central_space_service_pb.MapCustomerToSpaceResponse,
    (request: services_central_space_service_pb.MapCustomerToSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_space_service_pb.MapCustomerToSpaceResponse.deserializeBinary
  );

  mapCustomerToSpace(
    request: services_central_space_service_pb.MapCustomerToSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_space_service_pb.MapCustomerToSpaceResponse>;

  mapCustomerToSpace(
    request: services_central_space_service_pb.MapCustomerToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.MapCustomerToSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_space_service_pb.MapCustomerToSpaceResponse>;

  mapCustomerToSpace(
    request: services_central_space_service_pb.MapCustomerToSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_space_service_pb.MapCustomerToSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.SpaceService/MapCustomerToSpace',
        request,
        metadata || {},
        this.methodInfoMapCustomerToSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.SpaceService/MapCustomerToSpace',
    request,
    metadata || {},
    this.methodInfoMapCustomerToSpace);
  }

}

