import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { SpaceData } from "../utils/space-data";
import { failure, initialized, map, pending, RemoteCall, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";

import spaces from "./spaces";
import { namespace } from "vuex-class";
import { deviceServiceClient } from "@/config/service-clients";
import {
  RegisterDeviceRequest,
  GetDevicesInSpaceRequest,
  ConnectPowerfoxAccountRequest,
  GetMainDeviceRequest,
  GetBluemeteringApiKeyRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceRegistration } from "zaehlerfreunde-proto-types/device_registration_pb";
import partner from "./partner";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "devices",
})
class DevicesModule extends VuexModule {
  mainDeviceBySpaceId: SpaceData<RemoteData<UserError, Device | null>> = {};
  registerDeviceCall: RemoteCall<UserError> = initialized;

  bluemeteringApiKey: RemoteData<UserError, string | null> = initialized;

  devices: RemoteData<UserError, Device[]> = initialized;

  get devicesWithoutChildSpaces(): RemoteData<UserError, Device[]> {
    return map(this.devices, (devices) => devices.filter((d) => !d.getBelongsToChildSpace()));
  }

  get mainDevice(): RemoteData<UserError, Device | null> {
    return this.mainDeviceBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async registerDevice(registration: DeviceRegistration) {
    try {
      this.setRegisterDeviceCall(pending);
      const request = new RegisterDeviceRequest();
      if (partner.partner.data) registration.setPartnerId(partner.partner.data.getId());
      request.setRegistration(registration);
      await deviceServiceClient.registerDevice(request, {});
      this.setRegisterDeviceCall(success(void 0));
    } catch (error) {
      this.setRegisterDeviceCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getDevices() {
    const request = new GetDevicesInSpaceRequest();
    request.setSpaceId(spaces.selectedSpaceId);
    request.setIncludeChildSpaces(true);

    try {
      this.setDevices(pending);

      const response = await deviceServiceClient.getDevicesInSpace(request, {});
      this.setDevices(success(response.getDevicesList()));
    } catch (error) {
      this.setDevices(failure(userErrorFrom(error)));
    }
  }

  @Action
  async connectPowerfoxAccount(request: ConnectPowerfoxAccountRequest) {
    try {
      this.setRegisterDeviceCall(pending);

      await deviceServiceClient.connectPowerfoxAccount(request, {});
      this.setRegisterDeviceCall(success(void 0));

      setTimeout(() => this.getDevices(), 1000);
    } catch (error) {
      this.setRegisterDeviceCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getMainDevice() {
    const request = new GetMainDeviceRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      this.setMainDevice(pending);
      const response = await deviceServiceClient.getMainDevice(request, {});
      this.setMainDevice(success(response.getDevice() ?? null));
    } catch (error) {
      this.setMainDevice(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getBluemeteringApiKey() {
    try {
      this.setBluemeteringApiKey(pending);

      const request = new GetBluemeteringApiKeyRequest();
      const response = await deviceServiceClient.getBluemeteringApiKey(request, {});

      this.setBluemeteringApiKey(success(response.getApiKey() ?? null));
    } catch (error) {
      this.setBluemeteringApiKey(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setDevices(devices: RemoteData<UserError, Device[]>) {
    this.devices = devices;
  }

  @Mutation
  setRegisterDeviceCall(call: RemoteCall<UserError>) {
    this.registerDeviceCall = call;
  }

  @Mutation
  setMainDevice(mainDevice: RemoteData<UserError, Device | null>) {
    this.mainDeviceBySpaceId = {
      ...this.mainDeviceBySpaceId,
      [spaces.selectedSpaceId]: mainDevice,
    };
  }

  @Mutation
  setBluemeteringApiKey(v: RemoteData<UserError, string | null>) {
    this.bluemeteringApiKey = v;
  }
}

export const devicesModule = namespace("devices");
export default getModule(DevicesModule);
