
import { readingsServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { ForwardingRule } from "zaehlerfreunde-proto-types/forwarding_pb";
import { GetForwardingRulesRequest } from "zaehlerfreunde-central/reading_service_pb";
import CreateForwardingRule from "./forwarding-rule/CreateForwardingRule.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface Header {
  text: string;
  value: string;
}

interface Item {
  id: string;
  deviceProviders: string;
  format: string;
  target: string;
  type: string;
  measurements: string;
}

@Component({
  components: {
    CreateForwardingRule,
  },
})
export default class Forwarding extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  showCreateRuleDialog: boolean = false;

  headers: Header[] = [
    {
      text: "Ziel",
      value: "target",
    },
    {
      text: "Typ",
      value: "type",
    },
    {
      text: "Geräte",
      value: "deviceProviders",
    },
    {
      text: "Format",
      value: "format",
    },
    {
      text: "Werte",
      value: "measurements",
    },
  ];

  rules: RemoteData<UserError, ForwardingRule[]> = initialized;

  get items(): Item[] {
    return this.rules.list.map((r: ForwardingRule) => ({
      id: r.getId(),
      deviceProviders: r.getDeviceProviderNamesList().join(", "),
      type: "MQTT",
      format: r.getDestination()?.getFormatName() ?? "",
      target: r.getDestination()?.getName() ?? "",
      measurements: r.getMeasurementNamesList()?.join(", "),
    }));
  }

  async mounted(): Promise<void> {
    this.getRules();
  }

  async getRules(): Promise<void> {
    try {
      this.rules = pending;

      const request = new GetForwardingRulesRequest();
      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      const response = await readingsServiceClient.getForwardingRules(request, {});
      this.rules = success(response.getRulesList());
    } catch (error) {
      this.rules = failure(userErrorFrom(error));
    }
  }

  onRuleSelected(item: Item): void {
    this.$router.push(`${paths.admin.forwarding}/${item.id}`);
  }
}
