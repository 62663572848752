
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { userServiceClient } from "@/config/service-clients";
import { Vue, Component, Watch } from "vue-property-decorator";
import { AddUserToAdminRoleRequest, GetUsersRequest } from "zaehlerfreunde-central/user_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import UsersTable from "@/components/users-table/UsersTable.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: {
    UsersTable,
  },
})
export default class AddAdminDialog extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  addAdminCall: RemoteCall<UserError> = initialized;
  partnerUsers: RemoteData<UserError, User[]> = initialized;

  search: string = "";
  lastSearch: string = "";
  selectedUser: User | null = null;

  beforeMount(): void {
    this.loadUsers();
  }

  userName(user: User): string {
    return user.getName();
  }

  userId(user: User): string {
    return user.getId();
  }

  userEmail(user: User): string {
    return user.getEmail();
  }

  @Watch("search")
  async loadUsers(): Promise<void> {
    const request = new GetUsersRequest();
    request.setPageSize(5);
    request.setPage(0);
    if (this.search != "") {
      request.setEmail(this.search);
    }

    if (this.partner.data) request.setPartnerId(this.partner.data.getId());

    try {
      this.partnerUsers = pending;
      const response = await userServiceClient.getUsers(request, {});
      this.partnerUsers = success(response.getUsersList());
    } catch (error) {
      this.partnerUsers = failure(userErrorFrom(error));
    }
  }

  async onProceedClicked(): Promise<void> {
    try {
      this.addAdminCall = pending;
      const request = new AddUserToAdminRoleRequest();
      request.setUserId(this.selectedUser?.getId() ?? "");

      await userServiceClient.addUserToAdminRole(request, {});
      this.addAdminCall = success(void 0);
      this.$emit("input", false);
      this.$emit("admin-added");
    } catch (error) {
      this.addAdminCall = failure(userErrorFrom(error));
    }
  }
}
