/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_reading_service_pb from '../../services/central/reading_service_pb';


export class ReadingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetLatestStatusHandles = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetLatestStatusHandles',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetLatestStatusHandlesRequest,
    services_central_reading_service_pb.GetLatestStatusHandlesResponse,
    (request: services_central_reading_service_pb.GetLatestStatusHandlesRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetLatestStatusHandlesResponse.deserializeBinary
  );

  getLatestStatusHandles(
    request: services_central_reading_service_pb.GetLatestStatusHandlesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetLatestStatusHandlesResponse>;

  getLatestStatusHandles(
    request: services_central_reading_service_pb.GetLatestStatusHandlesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestStatusHandlesResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetLatestStatusHandlesResponse>;

  getLatestStatusHandles(
    request: services_central_reading_service_pb.GetLatestStatusHandlesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestStatusHandlesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetLatestStatusHandles',
        request,
        metadata || {},
        this.methodInfoGetLatestStatusHandles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetLatestStatusHandles',
    request,
    metadata || {},
    this.methodInfoGetLatestStatusHandles);
  }

  methodInfoGetLatestStatus = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetLatestStatus',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetLatestStatusRequest,
    services_central_reading_service_pb.GetLatestStatusResponse,
    (request: services_central_reading_service_pb.GetLatestStatusRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetLatestStatusResponse.deserializeBinary
  );

  getLatestStatus(
    request: services_central_reading_service_pb.GetLatestStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetLatestStatusResponse>;

  getLatestStatus(
    request: services_central_reading_service_pb.GetLatestStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestStatusResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetLatestStatusResponse>;

  getLatestStatus(
    request: services_central_reading_service_pb.GetLatestStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetLatestStatus',
        request,
        metadata || {},
        this.methodInfoGetLatestStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetLatestStatus',
    request,
    metadata || {},
    this.methodInfoGetLatestStatus);
  }

  methodInfoGetAccumulatedValue = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetAccumulatedValue',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetAccumulatedValueRequest,
    services_central_reading_service_pb.GetAccumulatedValueResponse,
    (request: services_central_reading_service_pb.GetAccumulatedValueRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetAccumulatedValueResponse.deserializeBinary
  );

  getAccumulatedValue(
    request: services_central_reading_service_pb.GetAccumulatedValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetAccumulatedValueResponse>;

  getAccumulatedValue(
    request: services_central_reading_service_pb.GetAccumulatedValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAccumulatedValueResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetAccumulatedValueResponse>;

  getAccumulatedValue(
    request: services_central_reading_service_pb.GetAccumulatedValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAccumulatedValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetAccumulatedValue',
        request,
        metadata || {},
        this.methodInfoGetAccumulatedValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetAccumulatedValue',
    request,
    metadata || {},
    this.methodInfoGetAccumulatedValue);
  }

  methodInfoGetAmount = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetAmount',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetAmountRequest,
    services_central_reading_service_pb.GetAmountResponse,
    (request: services_central_reading_service_pb.GetAmountRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetAmountResponse.deserializeBinary
  );

  getAmount(
    request: services_central_reading_service_pb.GetAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetAmountResponse>;

  getAmount(
    request: services_central_reading_service_pb.GetAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAmountResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetAmountResponse>;

  getAmount(
    request: services_central_reading_service_pb.GetAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetAmount',
        request,
        metadata || {},
        this.methodInfoGetAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetAmount',
    request,
    metadata || {},
    this.methodInfoGetAmount);
  }

  methodInfoGetCost = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetCost',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetCostRequest,
    services_central_reading_service_pb.GetCostResponse,
    (request: services_central_reading_service_pb.GetCostRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetCostResponse.deserializeBinary
  );

  getCost(
    request: services_central_reading_service_pb.GetCostRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetCostResponse>;

  getCost(
    request: services_central_reading_service_pb.GetCostRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetCostResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetCostResponse>;

  getCost(
    request: services_central_reading_service_pb.GetCostRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetCostResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetCost',
        request,
        metadata || {},
        this.methodInfoGetCost,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetCost',
    request,
    metadata || {},
    this.methodInfoGetCost);
  }

  methodInfoGetAverage = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetAverage',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetAverageRequest,
    services_central_reading_service_pb.GetAverageResponse,
    (request: services_central_reading_service_pb.GetAverageRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetAverageResponse.deserializeBinary
  );

  getAverage(
    request: services_central_reading_service_pb.GetAverageRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetAverageResponse>;

  getAverage(
    request: services_central_reading_service_pb.GetAverageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAverageResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetAverageResponse>;

  getAverage(
    request: services_central_reading_service_pb.GetAverageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetAverageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetAverage',
        request,
        metadata || {},
        this.methodInfoGetAverage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetAverage',
    request,
    metadata || {},
    this.methodInfoGetAverage);
  }

  methodInfoGetLocalExternalEnergyMix = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetLocalExternalEnergyMix',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetLocalExternalEnergyMixRequest,
    services_central_reading_service_pb.GetLocalExternalEnergyMixResponse,
    (request: services_central_reading_service_pb.GetLocalExternalEnergyMixRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetLocalExternalEnergyMixResponse.deserializeBinary
  );

  getLocalExternalEnergyMix(
    request: services_central_reading_service_pb.GetLocalExternalEnergyMixRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetLocalExternalEnergyMixResponse>;

  getLocalExternalEnergyMix(
    request: services_central_reading_service_pb.GetLocalExternalEnergyMixRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLocalExternalEnergyMixResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetLocalExternalEnergyMixResponse>;

  getLocalExternalEnergyMix(
    request: services_central_reading_service_pb.GetLocalExternalEnergyMixRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLocalExternalEnergyMixResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetLocalExternalEnergyMix',
        request,
        metadata || {},
        this.methodInfoGetLocalExternalEnergyMix,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetLocalExternalEnergyMix',
    request,
    metadata || {},
    this.methodInfoGetLocalExternalEnergyMix);
  }

  methodInfoGetTimeSeries = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetTimeSeries',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetTimeSeriesRequest,
    services_central_reading_service_pb.GetTimeSeriesResponse,
    (request: services_central_reading_service_pb.GetTimeSeriesRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetTimeSeriesResponse.deserializeBinary
  );

  getTimeSeries(
    request: services_central_reading_service_pb.GetTimeSeriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetTimeSeriesResponse>;

  getTimeSeries(
    request: services_central_reading_service_pb.GetTimeSeriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetTimeSeriesResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetTimeSeriesResponse>;

  getTimeSeries(
    request: services_central_reading_service_pb.GetTimeSeriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetTimeSeriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetTimeSeries',
        request,
        metadata || {},
        this.methodInfoGetTimeSeries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetTimeSeries',
    request,
    metadata || {},
    this.methodInfoGetTimeSeries);
  }

  methodInfoExportReadingsByPartnerId = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/ExportReadingsByPartnerId',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.ExportReadingsByPartnerIdRequest,
    services_central_reading_service_pb.ExportReadingsByPartnerIdResponse,
    (request: services_central_reading_service_pb.ExportReadingsByPartnerIdRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.ExportReadingsByPartnerIdResponse.deserializeBinary
  );

  exportReadingsByPartnerId(
    request: services_central_reading_service_pb.ExportReadingsByPartnerIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.ExportReadingsByPartnerIdResponse>;

  exportReadingsByPartnerId(
    request: services_central_reading_service_pb.ExportReadingsByPartnerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportReadingsByPartnerIdResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.ExportReadingsByPartnerIdResponse>;

  exportReadingsByPartnerId(
    request: services_central_reading_service_pb.ExportReadingsByPartnerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportReadingsByPartnerIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/ExportReadingsByPartnerId',
        request,
        metadata || {},
        this.methodInfoExportReadingsByPartnerId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/ExportReadingsByPartnerId',
    request,
    metadata || {},
    this.methodInfoExportReadingsByPartnerId);
  }

  methodInfoExportDeviceData = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/ExportDeviceData',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.ExportDeviceDataRequest,
    services_central_reading_service_pb.ExportDeviceDataResponse,
    (request: services_central_reading_service_pb.ExportDeviceDataRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.ExportDeviceDataResponse.deserializeBinary
  );

  exportDeviceData(
    request: services_central_reading_service_pb.ExportDeviceDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.ExportDeviceDataResponse>;

  exportDeviceData(
    request: services_central_reading_service_pb.ExportDeviceDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportDeviceDataResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.ExportDeviceDataResponse>;

  exportDeviceData(
    request: services_central_reading_service_pb.ExportDeviceDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportDeviceDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/ExportDeviceData',
        request,
        metadata || {},
        this.methodInfoExportDeviceData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/ExportDeviceData',
    request,
    metadata || {},
    this.methodInfoExportDeviceData);
  }

  methodInfoExportMultipleDevicesData = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/ExportMultipleDevicesData',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.ExportMultipleDevicesDataRequest,
    services_central_reading_service_pb.ExportMultipleDevicesDataResponse,
    (request: services_central_reading_service_pb.ExportMultipleDevicesDataRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.ExportMultipleDevicesDataResponse.deserializeBinary
  );

  exportMultipleDevicesData(
    request: services_central_reading_service_pb.ExportMultipleDevicesDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.ExportMultipleDevicesDataResponse>;

  exportMultipleDevicesData(
    request: services_central_reading_service_pb.ExportMultipleDevicesDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportMultipleDevicesDataResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.ExportMultipleDevicesDataResponse>;

  exportMultipleDevicesData(
    request: services_central_reading_service_pb.ExportMultipleDevicesDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportMultipleDevicesDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/ExportMultipleDevicesData',
        request,
        metadata || {},
        this.methodInfoExportMultipleDevicesData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/ExportMultipleDevicesData',
    request,
    metadata || {},
    this.methodInfoExportMultipleDevicesData);
  }

  methodInfoExportReadings = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/ExportReadings',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.ExportReadingsRequest,
    services_central_reading_service_pb.ExportReadingsResponse,
    (request: services_central_reading_service_pb.ExportReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.ExportReadingsResponse.deserializeBinary
  );

  exportReadings(
    request: services_central_reading_service_pb.ExportReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.ExportReadingsResponse>;

  exportReadings(
    request: services_central_reading_service_pb.ExportReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.ExportReadingsResponse>;

  exportReadings(
    request: services_central_reading_service_pb.ExportReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.ExportReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/ExportReadings',
        request,
        metadata || {},
        this.methodInfoExportReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/ExportReadings',
    request,
    metadata || {},
    this.methodInfoExportReadings);
  }

  methodInfoGetLatestDeviceReadings = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetLatestDeviceReadings',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetLatestDeviceReadingsRequest,
    services_central_reading_service_pb.GetLatestDeviceReadingsResponse,
    (request: services_central_reading_service_pb.GetLatestDeviceReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetLatestDeviceReadingsResponse.deserializeBinary
  );

  getLatestDeviceReadings(
    request: services_central_reading_service_pb.GetLatestDeviceReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetLatestDeviceReadingsResponse>;

  getLatestDeviceReadings(
    request: services_central_reading_service_pb.GetLatestDeviceReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestDeviceReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetLatestDeviceReadingsResponse>;

  getLatestDeviceReadings(
    request: services_central_reading_service_pb.GetLatestDeviceReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetLatestDeviceReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetLatestDeviceReadings',
        request,
        metadata || {},
        this.methodInfoGetLatestDeviceReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetLatestDeviceReadings',
    request,
    metadata || {},
    this.methodInfoGetLatestDeviceReadings);
  }

  methodInfoGetForwardingRules = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetForwardingRules',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetForwardingRulesRequest,
    services_central_reading_service_pb.GetForwardingRulesResponse,
    (request: services_central_reading_service_pb.GetForwardingRulesRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetForwardingRulesResponse.deserializeBinary
  );

  getForwardingRules(
    request: services_central_reading_service_pb.GetForwardingRulesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetForwardingRulesResponse>;

  getForwardingRules(
    request: services_central_reading_service_pb.GetForwardingRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetForwardingRulesResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetForwardingRulesResponse>;

  getForwardingRules(
    request: services_central_reading_service_pb.GetForwardingRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetForwardingRulesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetForwardingRules',
        request,
        metadata || {},
        this.methodInfoGetForwardingRules,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetForwardingRules',
    request,
    metadata || {},
    this.methodInfoGetForwardingRules);
  }

  methodInfoAddForwardingRule = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/AddForwardingRule',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.AddForwardingRuleRequest,
    services_central_reading_service_pb.AddForwardingRuleResponse,
    (request: services_central_reading_service_pb.AddForwardingRuleRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.AddForwardingRuleResponse.deserializeBinary
  );

  addForwardingRule(
    request: services_central_reading_service_pb.AddForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.AddForwardingRuleResponse>;

  addForwardingRule(
    request: services_central_reading_service_pb.AddForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.AddForwardingRuleResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.AddForwardingRuleResponse>;

  addForwardingRule(
    request: services_central_reading_service_pb.AddForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.AddForwardingRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/AddForwardingRule',
        request,
        metadata || {},
        this.methodInfoAddForwardingRule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/AddForwardingRule',
    request,
    metadata || {},
    this.methodInfoAddForwardingRule);
  }

  methodInfoDeleteForwardingRule = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/DeleteForwardingRule',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.DeleteForwardingRuleRequest,
    services_central_reading_service_pb.DeleteForwardingRuleResponse,
    (request: services_central_reading_service_pb.DeleteForwardingRuleRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.DeleteForwardingRuleResponse.deserializeBinary
  );

  deleteForwardingRule(
    request: services_central_reading_service_pb.DeleteForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.DeleteForwardingRuleResponse>;

  deleteForwardingRule(
    request: services_central_reading_service_pb.DeleteForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.DeleteForwardingRuleResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.DeleteForwardingRuleResponse>;

  deleteForwardingRule(
    request: services_central_reading_service_pb.DeleteForwardingRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.DeleteForwardingRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/DeleteForwardingRule',
        request,
        metadata || {},
        this.methodInfoDeleteForwardingRule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/DeleteForwardingRule',
    request,
    metadata || {},
    this.methodInfoDeleteForwardingRule);
  }

  methodInfoGetMarketPremiumInfos = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetMarketPremiumInfos',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetMarketPremiumInfosRequest,
    services_central_reading_service_pb.GetMarketPremiumInfosResponse,
    (request: services_central_reading_service_pb.GetMarketPremiumInfosRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetMarketPremiumInfosResponse.deserializeBinary
  );

  getMarketPremiumInfos(
    request: services_central_reading_service_pb.GetMarketPremiumInfosRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetMarketPremiumInfosResponse>;

  getMarketPremiumInfos(
    request: services_central_reading_service_pb.GetMarketPremiumInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetMarketPremiumInfosResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetMarketPremiumInfosResponse>;

  getMarketPremiumInfos(
    request: services_central_reading_service_pb.GetMarketPremiumInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetMarketPremiumInfosResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetMarketPremiumInfos',
        request,
        metadata || {},
        this.methodInfoGetMarketPremiumInfos,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetMarketPremiumInfos',
    request,
    metadata || {},
    this.methodInfoGetMarketPremiumInfos);
  }

  methodInfoSetBatteryCapacity = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/SetBatteryCapacity',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.SetBatteryCapacityRequest,
    services_central_reading_service_pb.SetBatteryCapacityResponse,
    (request: services_central_reading_service_pb.SetBatteryCapacityRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.SetBatteryCapacityResponse.deserializeBinary
  );

  setBatteryCapacity(
    request: services_central_reading_service_pb.SetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.SetBatteryCapacityResponse>;

  setBatteryCapacity(
    request: services_central_reading_service_pb.SetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.SetBatteryCapacityResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.SetBatteryCapacityResponse>;

  setBatteryCapacity(
    request: services_central_reading_service_pb.SetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.SetBatteryCapacityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/SetBatteryCapacity',
        request,
        metadata || {},
        this.methodInfoSetBatteryCapacity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/SetBatteryCapacity',
    request,
    metadata || {},
    this.methodInfoSetBatteryCapacity);
  }

  methodInfoGetBatteryCapacity = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetBatteryCapacity',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetBatteryCapacityRequest,
    services_central_reading_service_pb.GetBatteryCapacityResponse,
    (request: services_central_reading_service_pb.GetBatteryCapacityRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetBatteryCapacityResponse.deserializeBinary
  );

  getBatteryCapacity(
    request: services_central_reading_service_pb.GetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetBatteryCapacityResponse>;

  getBatteryCapacity(
    request: services_central_reading_service_pb.GetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetBatteryCapacityResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetBatteryCapacityResponse>;

  getBatteryCapacity(
    request: services_central_reading_service_pb.GetBatteryCapacityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetBatteryCapacityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetBatteryCapacity',
        request,
        metadata || {},
        this.methodInfoGetBatteryCapacity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetBatteryCapacity',
    request,
    metadata || {},
    this.methodInfoGetBatteryCapacity);
  }

  methodInfoGetReadings = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/GetReadings',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.GetReadingsRequest,
    services_central_reading_service_pb.GetReadingsResponse,
    (request: services_central_reading_service_pb.GetReadingsRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.GetReadingsResponse.deserializeBinary
  );

  getReadings(
    request: services_central_reading_service_pb.GetReadingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.GetReadingsResponse>;

  getReadings(
    request: services_central_reading_service_pb.GetReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetReadingsResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.GetReadingsResponse>;

  getReadings(
    request: services_central_reading_service_pb.GetReadingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.GetReadingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/GetReadings',
        request,
        metadata || {},
        this.methodInfoGetReadings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/GetReadings',
    request,
    metadata || {},
    this.methodInfoGetReadings);
  }

  methodInfoStoreEnergySpotPrices = new grpcWeb.MethodDescriptor(
    '/services.central.ReadingService/StoreEnergySpotPrices',
    grpcWeb.MethodType.UNARY,
    services_central_reading_service_pb.StoreEnergySpotPricesRequest,
    services_central_reading_service_pb.StoreEnergySpotPricesResponse,
    (request: services_central_reading_service_pb.StoreEnergySpotPricesRequest) => {
      return request.serializeBinary();
    },
    services_central_reading_service_pb.StoreEnergySpotPricesResponse.deserializeBinary
  );

  storeEnergySpotPrices(
    request: services_central_reading_service_pb.StoreEnergySpotPricesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_reading_service_pb.StoreEnergySpotPricesResponse>;

  storeEnergySpotPrices(
    request: services_central_reading_service_pb.StoreEnergySpotPricesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.StoreEnergySpotPricesResponse) => void): grpcWeb.ClientReadableStream<services_central_reading_service_pb.StoreEnergySpotPricesResponse>;

  storeEnergySpotPrices(
    request: services_central_reading_service_pb.StoreEnergySpotPricesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_reading_service_pb.StoreEnergySpotPricesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.ReadingService/StoreEnergySpotPrices',
        request,
        metadata || {},
        this.methodInfoStoreEnergySpotPrices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.ReadingService/StoreEnergySpotPrices',
    request,
    metadata || {},
    this.methodInfoStoreEnergySpotPrices);
  }

}

