
import { Vue, Component } from "vue-property-decorator";
import CreateSpaceDialog from "@/components/create-space/CreateSpaceDialog.vue";
import { paths } from "@/router/routes";
import { subscriptionModule } from "@/store/modules/subscription";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Component({
  components: {
    CreateSpaceDialog,
  },
})
export default class CreateFirstSpaceReminder extends Vue {
  @subscriptionModule.Getter includedFeatures: Feature[];

  showCreateSpaceDialog = false;

  showDialog() {
    if (
      ![Feature.ONE_SPACE, Feature.UP_TO_TWO_SPACES, Feature.UNLIMITED_SPACES].some((f) =>
        this.includedFeatures.includes(f)
      )
    ) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "single-space" },
      });
    } else {
      this.showCreateSpaceDialog = true;
    }
  }
}
