// source: services/central/reading_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_time_data_pb = require('../../types/time_data_pb.js');
goog.object.extend(proto, types_time_data_pb);
var types_device_reading_pb = require('../../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
var types_forwarding_pb = require('../../types/forwarding_pb.js');
goog.object.extend(proto, types_forwarding_pb);
var types_latest_status_pb = require('../../types/latest_status_pb.js');
goog.object.extend(proto, types_latest_status_pb);
var types_market_premium_pb = require('../../types/market_premium_pb.js');
goog.object.extend(proto, types_market_premium_pb);
var google_type_datetime_pb = require('../../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var google_type_money_pb = require('../../google/type/money_pb.js');
goog.object.extend(proto, google_type_money_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var types_data_export_pb = require('../../types/data_export_pb.js');
goog.object.extend(proto, types_data_export_pb);
var types_spot_price_pb = require('../../types/spot_price_pb.js');
goog.object.extend(proto, types_spot_price_pb);
goog.exportSymbol('proto.services.central.AccumulatedValue', null, global);
goog.exportSymbol('proto.services.central.AddForwardingRuleRequest', null, global);
goog.exportSymbol('proto.services.central.AddForwardingRuleResponse', null, global);
goog.exportSymbol('proto.services.central.Amount', null, global);
goog.exportSymbol('proto.services.central.Average', null, global);
goog.exportSymbol('proto.services.central.AverageOfSpaces', null, global);
goog.exportSymbol('proto.services.central.AverageOverPeriodOfTime', null, global);
goog.exportSymbol('proto.services.central.DeleteForwardingRuleRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteForwardingRuleResponse', null, global);
goog.exportSymbol('proto.services.central.DeviceReading', null, global);
goog.exportSymbol('proto.services.central.DeviceReadingsFilter', null, global);
goog.exportSymbol('proto.services.central.DeviceStatus', null, global);
goog.exportSymbol('proto.services.central.ExportDeviceDataRequest', null, global);
goog.exportSymbol('proto.services.central.ExportDeviceDataResponse', null, global);
goog.exportSymbol('proto.services.central.ExportMultipleDevicesDataRequest', null, global);
goog.exportSymbol('proto.services.central.ExportMultipleDevicesDataResponse', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsByPartnerIdRequest', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsByPartnerIdResponse', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsRequest.DeviceIds', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsRequest.DevicesCase', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsRequest.TimeSpanCase', null, global);
goog.exportSymbol('proto.services.central.ExportReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.FileFormat', null, global);
goog.exportSymbol('proto.services.central.GetAccumulatedValueRequest', null, global);
goog.exportSymbol('proto.services.central.GetAccumulatedValueRequest.DeviceOrSpaceCase', null, global);
goog.exportSymbol('proto.services.central.GetAccumulatedValueResponse', null, global);
goog.exportSymbol('proto.services.central.GetAmountRequest', null, global);
goog.exportSymbol('proto.services.central.GetAmountResponse', null, global);
goog.exportSymbol('proto.services.central.GetAverageRequest', null, global);
goog.exportSymbol('proto.services.central.GetAverageRequest.AverageCase', null, global);
goog.exportSymbol('proto.services.central.GetAverageRequest.Type', null, global);
goog.exportSymbol('proto.services.central.GetAverageResponse', null, global);
goog.exportSymbol('proto.services.central.GetBatteryCapacityRequest', null, global);
goog.exportSymbol('proto.services.central.GetBatteryCapacityResponse', null, global);
goog.exportSymbol('proto.services.central.GetCostRequest', null, global);
goog.exportSymbol('proto.services.central.GetCostResponse', null, global);
goog.exportSymbol('proto.services.central.GetDeviceConsumptionValuesResponse', null, global);
goog.exportSymbol('proto.services.central.GetForwardingRulesRequest', null, global);
goog.exportSymbol('proto.services.central.GetForwardingRulesResponse', null, global);
goog.exportSymbol('proto.services.central.GetLatestDeviceReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.GetLatestDeviceReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.GetLatestStatusHandlesRequest', null, global);
goog.exportSymbol('proto.services.central.GetLatestStatusHandlesResponse', null, global);
goog.exportSymbol('proto.services.central.GetLatestStatusRequest', null, global);
goog.exportSymbol('proto.services.central.GetLatestStatusResponse', null, global);
goog.exportSymbol('proto.services.central.GetLocalExternalEnergyMixRequest', null, global);
goog.exportSymbol('proto.services.central.GetLocalExternalEnergyMixResponse', null, global);
goog.exportSymbol('proto.services.central.GetMarketPremiumInfosRequest', null, global);
goog.exportSymbol('proto.services.central.GetMarketPremiumInfosResponse', null, global);
goog.exportSymbol('proto.services.central.GetReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.GetReadingsRequest.ByDevice', null, global);
goog.exportSymbol('proto.services.central.GetReadingsRequest.BySpace', null, global);
goog.exportSymbol('proto.services.central.GetReadingsRequest.QueryCase', null, global);
goog.exportSymbol('proto.services.central.GetReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.GetTimeSeriesRequest', null, global);
goog.exportSymbol('proto.services.central.GetTimeSeriesResponse', null, global);
goog.exportSymbol('proto.services.central.LocalExternalEnergyMix', null, global);
goog.exportSymbol('proto.services.central.PartnerDevices', null, global);
goog.exportSymbol('proto.services.central.SetBatteryCapacityRequest', null, global);
goog.exportSymbol('proto.services.central.SetBatteryCapacityResponse', null, global);
goog.exportSymbol('proto.services.central.StoreEnergySpotPricesRequest', null, global);
goog.exportSymbol('proto.services.central.StoreEnergySpotPricesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeviceReadingsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeviceReadingsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeviceReadingsFilter.displayName = 'proto.services.central.DeviceReadingsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeviceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.DeviceStatus.repeatedFields_, null);
};
goog.inherits(proto.services.central.DeviceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeviceStatus.displayName = 'proto.services.central.DeviceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAmountRequest.displayName = 'proto.services.central.GetAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAmountResponse.displayName = 'proto.services.central.GetAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.Amount.displayName = 'proto.services.central.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAccumulatedValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetAccumulatedValueRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetAccumulatedValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAccumulatedValueRequest.displayName = 'proto.services.central.GetAccumulatedValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAccumulatedValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAccumulatedValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAccumulatedValueResponse.displayName = 'proto.services.central.GetAccumulatedValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AccumulatedValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AccumulatedValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AccumulatedValue.displayName = 'proto.services.central.AccumulatedValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCostRequest.displayName = 'proto.services.central.GetCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCostResponse.displayName = 'proto.services.central.GetCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAverageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetAverageRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetAverageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAverageRequest.displayName = 'proto.services.central.GetAverageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AverageOverPeriodOfTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AverageOverPeriodOfTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AverageOverPeriodOfTime.displayName = 'proto.services.central.AverageOverPeriodOfTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AverageOfSpaces = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AverageOfSpaces, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AverageOfSpaces.displayName = 'proto.services.central.AverageOfSpaces';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAverageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAverageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAverageResponse.displayName = 'proto.services.central.GetAverageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.Average = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.Average, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.Average.displayName = 'proto.services.central.Average';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceConsumptionValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDeviceConsumptionValuesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDeviceConsumptionValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceConsumptionValuesResponse.displayName = 'proto.services.central.GetDeviceConsumptionValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLocalExternalEnergyMixRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLocalExternalEnergyMixRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLocalExternalEnergyMixRequest.displayName = 'proto.services.central.GetLocalExternalEnergyMixRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLocalExternalEnergyMixResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLocalExternalEnergyMixResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLocalExternalEnergyMixResponse.displayName = 'proto.services.central.GetLocalExternalEnergyMixResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LocalExternalEnergyMix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LocalExternalEnergyMix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LocalExternalEnergyMix.displayName = 'proto.services.central.LocalExternalEnergyMix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTimeSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTimeSeriesRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTimeSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTimeSeriesRequest.displayName = 'proto.services.central.GetTimeSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTimeSeriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTimeSeriesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTimeSeriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTimeSeriesResponse.displayName = 'proto.services.central.GetTimeSeriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeviceReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeviceReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeviceReading.displayName = 'proto.services.central.DeviceReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.ExportReadingsRequest.oneofGroups_);
};
goog.inherits(proto.services.central.ExportReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportReadingsRequest.displayName = 'proto.services.central.ExportReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportReadingsRequest.DeviceIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ExportReadingsRequest.DeviceIds.repeatedFields_, null);
};
goog.inherits(proto.services.central.ExportReadingsRequest.DeviceIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportReadingsRequest.DeviceIds.displayName = 'proto.services.central.ExportReadingsRequest.DeviceIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportReadingsResponse.displayName = 'proto.services.central.ExportReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportDeviceDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ExportDeviceDataRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.ExportDeviceDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportDeviceDataRequest.displayName = 'proto.services.central.ExportDeviceDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportDeviceDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportDeviceDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportDeviceDataResponse.displayName = 'proto.services.central.ExportDeviceDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportMultipleDevicesDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ExportMultipleDevicesDataRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.ExportMultipleDevicesDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportMultipleDevicesDataRequest.displayName = 'proto.services.central.ExportMultipleDevicesDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportMultipleDevicesDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportMultipleDevicesDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportMultipleDevicesDataResponse.displayName = 'proto.services.central.ExportMultipleDevicesDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportReadingsByPartnerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportReadingsByPartnerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportReadingsByPartnerIdRequest.displayName = 'proto.services.central.ExportReadingsByPartnerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportReadingsByPartnerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportReadingsByPartnerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportReadingsByPartnerIdResponse.displayName = 'proto.services.central.ExportReadingsByPartnerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.PartnerDevices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.PartnerDevices.repeatedFields_, null);
};
goog.inherits(proto.services.central.PartnerDevices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.PartnerDevices.displayName = 'proto.services.central.PartnerDevices';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestDeviceReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLatestDeviceReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestDeviceReadingsRequest.displayName = 'proto.services.central.GetLatestDeviceReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestDeviceReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetLatestDeviceReadingsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetLatestDeviceReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestDeviceReadingsResponse.displayName = 'proto.services.central.GetLatestDeviceReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetForwardingRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetForwardingRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetForwardingRulesRequest.displayName = 'proto.services.central.GetForwardingRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetForwardingRulesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetForwardingRulesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetForwardingRulesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetForwardingRulesResponse.displayName = 'proto.services.central.GetForwardingRulesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddForwardingRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddForwardingRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddForwardingRuleRequest.displayName = 'proto.services.central.AddForwardingRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddForwardingRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddForwardingRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddForwardingRuleResponse.displayName = 'proto.services.central.AddForwardingRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteForwardingRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteForwardingRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteForwardingRuleRequest.displayName = 'proto.services.central.DeleteForwardingRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteForwardingRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteForwardingRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteForwardingRuleResponse.displayName = 'proto.services.central.DeleteForwardingRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestStatusHandlesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLatestStatusHandlesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestStatusHandlesRequest.displayName = 'proto.services.central.GetLatestStatusHandlesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestStatusHandlesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetLatestStatusHandlesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetLatestStatusHandlesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestStatusHandlesResponse.displayName = 'proto.services.central.GetLatestStatusHandlesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLatestStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestStatusRequest.displayName = 'proto.services.central.GetLatestStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLatestStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLatestStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLatestStatusResponse.displayName = 'proto.services.central.GetLatestStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMarketPremiumInfosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMarketPremiumInfosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMarketPremiumInfosRequest.displayName = 'proto.services.central.GetMarketPremiumInfosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMarketPremiumInfosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMarketPremiumInfosResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMarketPremiumInfosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMarketPremiumInfosResponse.displayName = 'proto.services.central.GetMarketPremiumInfosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetBatteryCapacityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetBatteryCapacityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetBatteryCapacityRequest.displayName = 'proto.services.central.SetBatteryCapacityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetBatteryCapacityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetBatteryCapacityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetBatteryCapacityResponse.displayName = 'proto.services.central.SetBatteryCapacityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBatteryCapacityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBatteryCapacityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBatteryCapacityRequest.displayName = 'proto.services.central.GetBatteryCapacityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBatteryCapacityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBatteryCapacityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBatteryCapacityResponse.displayName = 'proto.services.central.GetBatteryCapacityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetReadingsRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReadingsRequest.displayName = 'proto.services.central.GetReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReadingsRequest.ByDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReadingsRequest.ByDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReadingsRequest.ByDevice.displayName = 'proto.services.central.GetReadingsRequest.ByDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReadingsRequest.BySpace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReadingsRequest.BySpace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReadingsRequest.BySpace.displayName = 'proto.services.central.GetReadingsRequest.BySpace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetReadingsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReadingsResponse.displayName = 'proto.services.central.GetReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreEnergySpotPricesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.StoreEnergySpotPricesRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.StoreEnergySpotPricesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreEnergySpotPricesRequest.displayName = 'proto.services.central.StoreEnergySpotPricesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreEnergySpotPricesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.StoreEnergySpotPricesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreEnergySpotPricesResponse.displayName = 'proto.services.central.StoreEnergySpotPricesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeviceReadingsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeviceReadingsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeviceReadingsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReadingsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    direction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    measurement: jspb.Message.getFieldWithDefault(msg, 2, 0),
    channel: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeviceReadingsFilter}
 */
proto.services.central.DeviceReadingsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeviceReadingsFilter;
  return proto.services.central.DeviceReadingsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeviceReadingsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeviceReadingsFilter}
 */
proto.services.central.DeviceReadingsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeviceReadingsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeviceReadingsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeviceReadingsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReadingsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.types.Direction} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional types.Direction direction = 1;
 * @return {!proto.types.Direction}
 */
proto.services.central.DeviceReadingsFilter.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.DeviceReadingsFilter} returns this
 */
proto.services.central.DeviceReadingsFilter.prototype.setDirection = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceReadingsFilter} returns this
 */
proto.services.central.DeviceReadingsFilter.prototype.clearDirection = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReadingsFilter.prototype.hasDirection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional types.Measurement measurement = 2;
 * @return {!proto.types.Measurement}
 */
proto.services.central.DeviceReadingsFilter.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.services.central.DeviceReadingsFilter} returns this
 */
proto.services.central.DeviceReadingsFilter.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string channel = 3;
 * @return {string}
 */
proto.services.central.DeviceReadingsFilter.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceReadingsFilter} returns this
 */
proto.services.central.DeviceReadingsFilter.prototype.setChannel = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceReadingsFilter} returns this
 */
proto.services.central.DeviceReadingsFilter.prototype.clearChannel = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReadingsFilter.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.DeviceStatus.repeatedFields_ = [2,3,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeviceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeviceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeviceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && types_device_pb.Device.toObject(includeInstance, f),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    unitsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    valuesFormattedList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    details: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeviceStatus}
 */
proto.services.central.DeviceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeviceStatus;
  return proto.services.central.DeviceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeviceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeviceStatus}
 */
proto.services.central.DeviceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnits(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addValuesFormatted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeviceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeviceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeviceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getValuesFormattedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional types.Device device = 1;
 * @return {?proto.types.Device}
 */
proto.services.central.DeviceStatus.prototype.getDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.services.central.DeviceStatus} returns this
*/
proto.services.central.DeviceStatus.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceStatus.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.services.central.DeviceStatus.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated string units = 3;
 * @return {!Array<string>}
 */
proto.services.central.DeviceStatus.prototype.getUnitsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setUnitsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.addUnits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


/**
 * repeated double values = 4;
 * @return {!Array<number>}
 */
proto.services.central.DeviceStatus.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * repeated string values_formatted = 7;
 * @return {!Array<string>}
 */
proto.services.central.DeviceStatus.prototype.getValuesFormattedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setValuesFormattedList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.addValuesFormatted = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearValuesFormattedList = function() {
  return this.setValuesFormattedList([]);
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.services.central.DeviceStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string details = 6;
 * @return {string}
 */
proto.services.central.DeviceStatus.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.setDetails = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceStatus} returns this
 */
proto.services.central.DeviceStatus.prototype.clearDetails = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceStatus.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAmountRequest}
 */
proto.services.central.GetAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAmountRequest;
  return proto.services.central.GetAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAmountRequest}
 */
proto.services.central.GetAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAmountRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAmountRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.GetAmountRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.clearDeviceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAmountRequest.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.services.central.GetAmountRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.services.central.GetAmountRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional types.Direction direction = 5;
 * @return {!proto.types.Direction}
 */
proto.services.central.GetAmountRequest.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.GetAmountRequest} returns this
 */
proto.services.central.GetAmountRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: (f = msg.getAmount()) && proto.services.central.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAmountResponse}
 */
proto.services.central.GetAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAmountResponse;
  return proto.services.central.GetAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAmountResponse}
 */
proto.services.central.GetAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.Amount;
      reader.readMessage(value,proto.services.central.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Amount amount = 1;
 * @return {?proto.services.central.Amount}
 */
proto.services.central.GetAmountResponse.prototype.getAmount = function() {
  return /** @type{?proto.services.central.Amount} */ (
    jspb.Message.getWrapperField(this, proto.services.central.Amount, 1));
};


/**
 * @param {?proto.services.central.Amount|undefined} value
 * @return {!proto.services.central.GetAmountResponse} returns this
*/
proto.services.central.GetAmountResponse.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAmountResponse} returns this
 */
proto.services.central.GetAmountResponse.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAmountResponse.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.Amount}
 */
proto.services.central.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.Amount;
  return proto.services.central.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.Amount}
 */
proto.services.central.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.services.central.Amount.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.Amount} returns this
 */
proto.services.central.Amount.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.services.central.Amount.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.Amount} returns this
 */
proto.services.central.Amount.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.services.central.Amount.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.Amount} returns this
 */
proto.services.central.Amount.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.services.central.Amount.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.Amount} returns this
 */
proto.services.central.Amount.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetAccumulatedValueRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.GetAccumulatedValueRequest.DeviceOrSpaceCase = {
  DEVICE_OR_SPACE_NOT_SET: 0,
  SPACE_ID: 1,
  DEVICE_ID: 2
};

/**
 * @return {proto.services.central.GetAccumulatedValueRequest.DeviceOrSpaceCase}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getDeviceOrSpaceCase = function() {
  return /** @type {proto.services.central.GetAccumulatedValueRequest.DeviceOrSpaceCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetAccumulatedValueRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAccumulatedValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAccumulatedValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAccumulatedValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAccumulatedValueRequest}
 */
proto.services.central.GetAccumulatedValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAccumulatedValueRequest;
  return proto.services.central.GetAccumulatedValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAccumulatedValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAccumulatedValueRequest}
 */
proto.services.central.GetAccumulatedValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAccumulatedValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAccumulatedValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAccumulatedValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.services.central.GetAccumulatedValueRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setOneofField(this, 1, proto.services.central.GetAccumulatedValueRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.services.central.GetAccumulatedValueRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.clearDeviceId = function() {
  return jspb.Message.setOneofField(this, 2, proto.services.central.GetAccumulatedValueRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.DateTime start_date = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
*/
proto.services.central.GetAccumulatedValueRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_date = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
*/
proto.services.central.GetAccumulatedValueRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional types.Direction direction = 5;
 * @return {!proto.types.Direction}
 */
proto.services.central.GetAccumulatedValueRequest.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.GetAccumulatedValueRequest} returns this
 */
proto.services.central.GetAccumulatedValueRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAccumulatedValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAccumulatedValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAccumulatedValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accumulatedValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    firstReadingTimestamp: (f = msg.getFirstReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastReadingTimestamp: (f = msg.getLastReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.services.central.AccumulatedValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAccumulatedValueResponse}
 */
proto.services.central.GetAccumulatedValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAccumulatedValueResponse;
  return proto.services.central.GetAccumulatedValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAccumulatedValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAccumulatedValueResponse}
 */
proto.services.central.GetAccumulatedValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAccumulatedValue(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstReadingTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastReadingTimestamp(value);
      break;
    case 4:
      var value = new proto.services.central.AccumulatedValue;
      reader.readMessage(value,proto.services.central.AccumulatedValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAccumulatedValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAccumulatedValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAccumulatedValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccumulatedValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFirstReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.central.AccumulatedValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional double accumulated_value = 1;
 * @return {number}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.getAccumulatedValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
 */
proto.services.central.GetAccumulatedValueResponse.prototype.setAccumulatedValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp first_reading_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.getFirstReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
*/
proto.services.central.GetAccumulatedValueResponse.prototype.setFirstReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
 */
proto.services.central.GetAccumulatedValueResponse.prototype.clearFirstReadingTimestamp = function() {
  return this.setFirstReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.hasFirstReadingTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_reading_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.getLastReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
*/
proto.services.central.GetAccumulatedValueResponse.prototype.setLastReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
 */
proto.services.central.GetAccumulatedValueResponse.prototype.clearLastReadingTimestamp = function() {
  return this.setLastReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.hasLastReadingTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AccumulatedValue value = 4;
 * @return {?proto.services.central.AccumulatedValue}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.getValue = function() {
  return /** @type{?proto.services.central.AccumulatedValue} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AccumulatedValue, 4));
};


/**
 * @param {?proto.services.central.AccumulatedValue|undefined} value
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
*/
proto.services.central.GetAccumulatedValueResponse.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAccumulatedValueResponse} returns this
 */
proto.services.central.GetAccumulatedValueResponse.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAccumulatedValueResponse.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AccumulatedValue.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AccumulatedValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AccumulatedValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AccumulatedValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    firstReadingTimestamp: (f = msg.getFirstReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastReadingTimestamp: (f = msg.getLastReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AccumulatedValue}
 */
proto.services.central.AccumulatedValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AccumulatedValue;
  return proto.services.central.AccumulatedValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AccumulatedValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AccumulatedValue}
 */
proto.services.central.AccumulatedValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstReadingTimestamp(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastReadingTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AccumulatedValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AccumulatedValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AccumulatedValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AccumulatedValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFirstReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.services.central.AccumulatedValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AccumulatedValue} returns this
 */
proto.services.central.AccumulatedValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp first_reading_timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.AccumulatedValue.prototype.getFirstReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.AccumulatedValue} returns this
*/
proto.services.central.AccumulatedValue.prototype.setFirstReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AccumulatedValue} returns this
 */
proto.services.central.AccumulatedValue.prototype.clearFirstReadingTimestamp = function() {
  return this.setFirstReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AccumulatedValue.prototype.hasFirstReadingTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_reading_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.AccumulatedValue.prototype.getLastReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.AccumulatedValue} returns this
*/
proto.services.central.AccumulatedValue.prototype.setLastReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AccumulatedValue} returns this
 */
proto.services.central.AccumulatedValue.prototype.clearLastReadingTimestamp = function() {
  return this.setLastReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AccumulatedValue.prototype.hasLastReadingTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.services.central.AccumulatedValue.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AccumulatedValue} returns this
 */
proto.services.central.AccumulatedValue.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: (f = msg.getStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCostRequest}
 */
proto.services.central.GetCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCostRequest;
  return proto.services.central.GetCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCostRequest}
 */
proto.services.central.GetCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetCostRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCostRequest} returns this
 */
proto.services.central.GetCostRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetCostRequest} returns this
 */
proto.services.central.GetCostRequest.prototype.clearDeviceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostRequest.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetCostRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCostRequest} returns this
 */
proto.services.central.GetCostRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.DateTime start_date = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetCostRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetCostRequest} returns this
*/
proto.services.central.GetCostRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCostRequest} returns this
 */
proto.services.central.GetCostRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_date = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetCostRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetCostRequest} returns this
*/
proto.services.central.GetCostRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCostRequest} returns this
 */
proto.services.central.GetCostRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: (f = msg.getCost()) && google_type_money_pb.Money.toObject(includeInstance, f),
    consumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    firstReadingTimestamp: (f = msg.getFirstReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastReadingTimestamp: (f = msg.getLastReadingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCostResponse}
 */
proto.services.central.GetCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCostResponse;
  return proto.services.central.GetCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCostResponse}
 */
proto.services.central.GetCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_money_pb.Money;
      reader.readMessage(value,google_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConsumption(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstReadingTimestamp(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastReadingTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFirstReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastReadingTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Money cost = 1;
 * @return {?proto.google.type.Money}
 */
proto.services.central.GetCostResponse.prototype.getCost = function() {
  return /** @type{?proto.google.type.Money} */ (
    jspb.Message.getWrapperField(this, google_type_money_pb.Money, 1));
};


/**
 * @param {?proto.google.type.Money|undefined} value
 * @return {!proto.services.central.GetCostResponse} returns this
*/
proto.services.central.GetCostResponse.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCostResponse} returns this
 */
proto.services.central.GetCostResponse.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostResponse.prototype.hasCost = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double consumption = 2;
 * @return {number}
 */
proto.services.central.GetCostResponse.prototype.getConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetCostResponse} returns this
 */
proto.services.central.GetCostResponse.prototype.setConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp first_reading_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.GetCostResponse.prototype.getFirstReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.GetCostResponse} returns this
*/
proto.services.central.GetCostResponse.prototype.setFirstReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCostResponse} returns this
 */
proto.services.central.GetCostResponse.prototype.clearFirstReadingTimestamp = function() {
  return this.setFirstReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostResponse.prototype.hasFirstReadingTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp last_reading_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.services.central.GetCostResponse.prototype.getLastReadingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.services.central.GetCostResponse} returns this
*/
proto.services.central.GetCostResponse.prototype.setLastReadingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCostResponse} returns this
 */
proto.services.central.GetCostResponse.prototype.clearLastReadingTimestamp = function() {
  return this.setLastReadingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCostResponse.prototype.hasLastReadingTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetAverageRequest.oneofGroups_ = [[1,6]];

/**
 * @enum {number}
 */
proto.services.central.GetAverageRequest.AverageCase = {
  AVERAGE_NOT_SET: 0,
  OVER_A_PERIOD_OF_TIME: 1,
  OF_SPACES: 6
};

/**
 * @return {proto.services.central.GetAverageRequest.AverageCase}
 */
proto.services.central.GetAverageRequest.prototype.getAverageCase = function() {
  return /** @type {proto.services.central.GetAverageRequest.AverageCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetAverageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAverageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAverageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAverageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAverageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    overAPeriodOfTime: (f = msg.getOverAPeriodOfTime()) && proto.services.central.AverageOverPeriodOfTime.toObject(includeInstance, f),
    ofSpaces: (f = msg.getOfSpaces()) && proto.services.central.AverageOfSpaces.toObject(includeInstance, f),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAverageRequest}
 */
proto.services.central.GetAverageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAverageRequest;
  return proto.services.central.GetAverageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAverageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAverageRequest}
 */
proto.services.central.GetAverageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.AverageOverPeriodOfTime;
      reader.readMessage(value,proto.services.central.AverageOverPeriodOfTime.deserializeBinaryFromReader);
      msg.setOverAPeriodOfTime(value);
      break;
    case 6:
      var value = new proto.services.central.AverageOfSpaces;
      reader.readMessage(value,proto.services.central.AverageOfSpaces.deserializeBinaryFromReader);
      msg.setOfSpaces(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 4:
      var value = /** @type {!proto.services.central.GetAverageRequest.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAverageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAverageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAverageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAverageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverAPeriodOfTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.AverageOverPeriodOfTime.serializeBinaryToWriter
    );
  }
  f = message.getOfSpaces();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.central.AverageOfSpaces.serializeBinaryToWriter
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.GetAverageRequest.Type = {
  CONSUMPTION: 0,
  COST: 1
};

/**
 * optional AverageOverPeriodOfTime over_a_period_of_time = 1;
 * @return {?proto.services.central.AverageOverPeriodOfTime}
 */
proto.services.central.GetAverageRequest.prototype.getOverAPeriodOfTime = function() {
  return /** @type{?proto.services.central.AverageOverPeriodOfTime} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AverageOverPeriodOfTime, 1));
};


/**
 * @param {?proto.services.central.AverageOverPeriodOfTime|undefined} value
 * @return {!proto.services.central.GetAverageRequest} returns this
*/
proto.services.central.GetAverageRequest.prototype.setOverAPeriodOfTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetAverageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAverageRequest} returns this
 */
proto.services.central.GetAverageRequest.prototype.clearOverAPeriodOfTime = function() {
  return this.setOverAPeriodOfTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAverageRequest.prototype.hasOverAPeriodOfTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AverageOfSpaces of_spaces = 6;
 * @return {?proto.services.central.AverageOfSpaces}
 */
proto.services.central.GetAverageRequest.prototype.getOfSpaces = function() {
  return /** @type{?proto.services.central.AverageOfSpaces} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AverageOfSpaces, 6));
};


/**
 * @param {?proto.services.central.AverageOfSpaces|undefined} value
 * @return {!proto.services.central.GetAverageRequest} returns this
*/
proto.services.central.GetAverageRequest.prototype.setOfSpaces = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.services.central.GetAverageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAverageRequest} returns this
 */
proto.services.central.GetAverageRequest.prototype.clearOfSpaces = function() {
  return this.setOfSpaces(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAverageRequest.prototype.hasOfSpaces = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetAverageRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAverageRequest} returns this
 */
proto.services.central.GetAverageRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Type type = 4;
 * @return {!proto.services.central.GetAverageRequest.Type}
 */
proto.services.central.GetAverageRequest.prototype.getType = function() {
  return /** @type {!proto.services.central.GetAverageRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.central.GetAverageRequest.Type} value
 * @return {!proto.services.central.GetAverageRequest} returns this
 */
proto.services.central.GetAverageRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AverageOverPeriodOfTime.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AverageOverPeriodOfTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AverageOverPeriodOfTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AverageOverPeriodOfTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AverageOverPeriodOfTime}
 */
proto.services.central.AverageOverPeriodOfTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AverageOverPeriodOfTime;
  return proto.services.central.AverageOverPeriodOfTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AverageOverPeriodOfTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AverageOverPeriodOfTime}
 */
proto.services.central.AverageOverPeriodOfTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.TimeInterval} */ (reader.readEnum());
      msg.setTimeInterval(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AverageOverPeriodOfTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AverageOverPeriodOfTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AverageOverPeriodOfTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AverageOverPeriodOfTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.AverageOverPeriodOfTime.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AverageOverPeriodOfTime} returns this
 */
proto.services.central.AverageOverPeriodOfTime.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.TimeInterval time_interval = 2;
 * @return {!proto.types.TimeInterval}
 */
proto.services.central.AverageOverPeriodOfTime.prototype.getTimeInterval = function() {
  return /** @type {!proto.types.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.TimeInterval} value
 * @return {!proto.services.central.AverageOverPeriodOfTime} returns this
 */
proto.services.central.AverageOverPeriodOfTime.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.services.central.AverageOverPeriodOfTime.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AverageOverPeriodOfTime} returns this
 */
proto.services.central.AverageOverPeriodOfTime.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AverageOfSpaces.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AverageOfSpaces.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AverageOfSpaces} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AverageOfSpaces.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentSpaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timePeriod: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AverageOfSpaces}
 */
proto.services.central.AverageOfSpaces.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AverageOfSpaces;
  return proto.services.central.AverageOfSpaces.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AverageOfSpaces} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AverageOfSpaces}
 */
proto.services.central.AverageOfSpaces.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.TimePeriod} */ (reader.readEnum());
      msg.setTimePeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AverageOfSpaces.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AverageOfSpaces.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AverageOfSpaces} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AverageOfSpaces.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimePeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent_space_id = 1;
 * @return {string}
 */
proto.services.central.AverageOfSpaces.prototype.getParentSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AverageOfSpaces} returns this
 */
proto.services.central.AverageOfSpaces.prototype.setParentSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.TimePeriod time_period = 2;
 * @return {!proto.types.TimePeriod}
 */
proto.services.central.AverageOfSpaces.prototype.getTimePeriod = function() {
  return /** @type {!proto.types.TimePeriod} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.TimePeriod} value
 * @return {!proto.services.central.AverageOfSpaces} returns this
 */
proto.services.central.AverageOfSpaces.prototype.setTimePeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAverageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAverageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAverageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAverageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    average: (f = msg.getAverage()) && proto.services.central.Average.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAverageResponse}
 */
proto.services.central.GetAverageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAverageResponse;
  return proto.services.central.GetAverageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAverageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAverageResponse}
 */
proto.services.central.GetAverageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.Average;
      reader.readMessage(value,proto.services.central.Average.deserializeBinaryFromReader);
      msg.setAverage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAverageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAverageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAverageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAverageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAverage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.Average.serializeBinaryToWriter
    );
  }
};


/**
 * optional Average average = 1;
 * @return {?proto.services.central.Average}
 */
proto.services.central.GetAverageResponse.prototype.getAverage = function() {
  return /** @type{?proto.services.central.Average} */ (
    jspb.Message.getWrapperField(this, proto.services.central.Average, 1));
};


/**
 * @param {?proto.services.central.Average|undefined} value
 * @return {!proto.services.central.GetAverageResponse} returns this
*/
proto.services.central.GetAverageResponse.prototype.setAverage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAverageResponse} returns this
 */
proto.services.central.GetAverageResponse.prototype.clearAverage = function() {
  return this.setAverage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAverageResponse.prototype.hasAverage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.Average.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.Average.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.Average} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.Average.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.Average}
 */
proto.services.central.Average.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.Average;
  return proto.services.central.Average.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.Average} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.Average}
 */
proto.services.central.Average.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.Average.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.Average.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.Average} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.Average.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.services.central.Average.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.Average} returns this
 */
proto.services.central.Average.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 duration = 2;
 * @return {number}
 */
proto.services.central.Average.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.Average} returns this
 */
proto.services.central.Average.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.services.central.Average.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.Average} returns this
 */
proto.services.central.Average.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDeviceConsumptionValuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceConsumptionValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceConsumptionValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceConsumptionValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumptionValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    timeOfFirstNonZeroValue: (f = msg.getTimeOfFirstNonZeroValue()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    timeOfLastValue: (f = msg.getTimeOfLastValue()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceConsumptionValuesResponse;
  return proto.services.central.GetDeviceConsumptionValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceConsumptionValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConsumptionValues(values[i]);
      }
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setTimeOfFirstNonZeroValue(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setTimeOfLastValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceConsumptionValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceConsumptionValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceConsumptionValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumptionValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getTimeOfFirstNonZeroValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getTimeOfLastValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * repeated double consumption_values = 1;
 * @return {!Array<number>}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.getConsumptionValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.setConsumptionValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.addConsumptionValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.clearConsumptionValuesList = function() {
  return this.setConsumptionValuesList([]);
};


/**
 * optional google.type.DateTime time_of_first_non_zero_value = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.getTimeOfFirstNonZeroValue = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
*/
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.setTimeOfFirstNonZeroValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.clearTimeOfFirstNonZeroValue = function() {
  return this.setTimeOfFirstNonZeroValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.hasTimeOfFirstNonZeroValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.DateTime time_of_last_value = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.getTimeOfLastValue = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
*/
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.setTimeOfLastValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDeviceConsumptionValuesResponse} returns this
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.clearTimeOfLastValue = function() {
  return this.setTimeOfLastValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceConsumptionValuesResponse.prototype.hasTimeOfLastValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLocalExternalEnergyMixRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLocalExternalEnergyMixRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLocalExternalEnergyMixRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: (f = msg.getStartTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLocalExternalEnergyMixRequest;
  return proto.services.central.GetLocalExternalEnergyMixRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLocalExternalEnergyMixRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLocalExternalEnergyMixRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLocalExternalEnergyMixRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLocalExternalEnergyMixRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest} returns this
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DateTime start_time = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest} returns this
*/
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest} returns this
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.DateTime end_time = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest} returns this
*/
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLocalExternalEnergyMixRequest} returns this
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLocalExternalEnergyMixRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLocalExternalEnergyMixResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLocalExternalEnergyMixResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLocalExternalEnergyMixResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyMix: (f = msg.getEnergyMix()) && proto.services.central.LocalExternalEnergyMix.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLocalExternalEnergyMixResponse}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLocalExternalEnergyMixResponse;
  return proto.services.central.GetLocalExternalEnergyMixResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLocalExternalEnergyMixResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLocalExternalEnergyMixResponse}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.LocalExternalEnergyMix;
      reader.readMessage(value,proto.services.central.LocalExternalEnergyMix.deserializeBinaryFromReader);
      msg.setEnergyMix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLocalExternalEnergyMixResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLocalExternalEnergyMixResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLocalExternalEnergyMixResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyMix();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.LocalExternalEnergyMix.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalExternalEnergyMix energy_mix = 1;
 * @return {?proto.services.central.LocalExternalEnergyMix}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.getEnergyMix = function() {
  return /** @type{?proto.services.central.LocalExternalEnergyMix} */ (
    jspb.Message.getWrapperField(this, proto.services.central.LocalExternalEnergyMix, 1));
};


/**
 * @param {?proto.services.central.LocalExternalEnergyMix|undefined} value
 * @return {!proto.services.central.GetLocalExternalEnergyMixResponse} returns this
*/
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.setEnergyMix = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLocalExternalEnergyMixResponse} returns this
 */
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.clearEnergyMix = function() {
  return this.setEnergyMix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLocalExternalEnergyMixResponse.prototype.hasEnergyMix = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LocalExternalEnergyMix.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LocalExternalEnergyMix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LocalExternalEnergyMix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LocalExternalEnergyMix.toObject = function(includeInstance, msg) {
  var f, obj = {
    production: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    ingestion: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    consumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    localEnergy: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    externalEnergy: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    energyMix: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    numFlats: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LocalExternalEnergyMix}
 */
proto.services.central.LocalExternalEnergyMix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LocalExternalEnergyMix;
  return proto.services.central.LocalExternalEnergyMix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LocalExternalEnergyMix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LocalExternalEnergyMix}
 */
proto.services.central.LocalExternalEnergyMix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProduction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIngestion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConsumption(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocalEnergy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExternalEnergy(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnergyMix(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumFlats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LocalExternalEnergyMix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LocalExternalEnergyMix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LocalExternalEnergyMix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LocalExternalEnergyMix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduction();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getIngestion();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLocalEnergy();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getExternalEnergy();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getEnergyMix();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getNumFlats();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double production = 1;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getProduction = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setProduction = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double ingestion = 2;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getIngestion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setIngestion = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double consumption = 3;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double local_energy = 4;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getLocalEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setLocalEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double external_energy = 5;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getExternalEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setExternalEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double energy_mix = 6;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getEnergyMix = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setEnergyMix = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double num_flats = 7;
 * @return {number}
 */
proto.services.central.LocalExternalEnergyMix.prototype.getNumFlats = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LocalExternalEnergyMix} returns this
 */
proto.services.central.LocalExternalEnergyMix.prototype.setNumFlats = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTimeSeriesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTimeSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTimeSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTimeSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTimeSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variablesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxNumDataPoints: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTimeSeriesRequest}
 */
proto.services.central.GetTimeSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTimeSeriesRequest;
  return proto.services.central.GetTimeSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTimeSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTimeSeriesRequest}
 */
proto.services.central.GetTimeSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.types.Variable>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVariables(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxNumDataPoints(value);
      break;
    case 7:
      var value = /** @type {!proto.types.TimeInterval} */ (reader.readEnum());
      msg.setTimeInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTimeSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTimeSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTimeSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTimeSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMaxNumDataPoints();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {!proto.types.TimeInterval} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTimeSeriesRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated types.Variable variables = 3;
 * @return {!Array<!proto.types.Variable>}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getVariablesList = function() {
  return /** @type {!Array<!proto.types.Variable>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.types.Variable>} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setVariablesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.types.Variable} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.addVariables = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 end_time = 5;
 * @return {number}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_num_data_points = 6;
 * @return {number}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getMaxNumDataPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setMaxNumDataPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional types.TimeInterval time_interval = 7;
 * @return {!proto.types.TimeInterval}
 */
proto.services.central.GetTimeSeriesRequest.prototype.getTimeInterval = function() {
  return /** @type {!proto.types.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.TimeInterval} value
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetTimeSeriesRequest} returns this
 */
proto.services.central.GetTimeSeriesRequest.prototype.clearTimeInterval = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTimeSeriesRequest.prototype.hasTimeInterval = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTimeSeriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTimeSeriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTimeSeriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTimeSeriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTimeSeriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeSeriesList: jspb.Message.toObjectList(msg.getTimeSeriesList(),
    types_time_data_pb.TimeSeries.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTimeSeriesResponse}
 */
proto.services.central.GetTimeSeriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTimeSeriesResponse;
  return proto.services.central.GetTimeSeriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTimeSeriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTimeSeriesResponse}
 */
proto.services.central.GetTimeSeriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_time_data_pb.TimeSeries;
      reader.readMessage(value,types_time_data_pb.TimeSeries.deserializeBinaryFromReader);
      msg.addTimeSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTimeSeriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTimeSeriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTimeSeriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTimeSeriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_time_data_pb.TimeSeries.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.TimeSeries time_series = 1;
 * @return {!Array<!proto.types.TimeSeries>}
 */
proto.services.central.GetTimeSeriesResponse.prototype.getTimeSeriesList = function() {
  return /** @type{!Array<!proto.types.TimeSeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_time_data_pb.TimeSeries, 1));
};


/**
 * @param {!Array<!proto.types.TimeSeries>} value
 * @return {!proto.services.central.GetTimeSeriesResponse} returns this
*/
proto.services.central.GetTimeSeriesResponse.prototype.setTimeSeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.TimeSeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.TimeSeries}
 */
proto.services.central.GetTimeSeriesResponse.prototype.addTimeSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.TimeSeries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTimeSeriesResponse} returns this
 */
proto.services.central.GetTimeSeriesResponse.prototype.clearTimeSeriesList = function() {
  return this.setTimeSeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeviceReading.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeviceReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeviceReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: (f = msg.getDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeviceReading}
 */
proto.services.central.DeviceReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeviceReading;
  return proto.services.central.DeviceReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeviceReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeviceReading}
 */
proto.services.central.DeviceReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeviceReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeviceReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeviceReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {!proto.types.Direction} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.DeviceReading.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceReading} returns this
 */
proto.services.central.DeviceReading.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DateTime date = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.DeviceReading.prototype.getDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.DeviceReading} returns this
*/
proto.services.central.DeviceReading.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeviceReading} returns this
 */
proto.services.central.DeviceReading.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReading.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.services.central.DeviceReading.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.DeviceReading} returns this
 */
proto.services.central.DeviceReading.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional types.Direction direction = 4;
 * @return {!proto.types.Direction}
 */
proto.services.central.DeviceReading.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.DeviceReading} returns this
 */
proto.services.central.DeviceReading.prototype.setDirection = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceReading} returns this
 */
proto.services.central.DeviceReading.prototype.clearDirection = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceReading.prototype.hasDirection = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.ExportReadingsRequest.oneofGroups_ = [[1,2],[7,8]];

/**
 * @enum {number}
 */
proto.services.central.ExportReadingsRequest.DevicesCase = {
  DEVICES_NOT_SET: 0,
  BY_IDS: 1,
  BY_PARTNER: 2
};

/**
 * @return {proto.services.central.ExportReadingsRequest.DevicesCase}
 */
proto.services.central.ExportReadingsRequest.prototype.getDevicesCase = function() {
  return /** @type {proto.services.central.ExportReadingsRequest.DevicesCase} */(jspb.Message.computeOneofCase(this, proto.services.central.ExportReadingsRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.services.central.ExportReadingsRequest.TimeSpanCase = {
  TIME_SPAN_NOT_SET: 0,
  TIME_INTERVAL: 7,
  RESOLUTION: 8
};

/**
 * @return {proto.services.central.ExportReadingsRequest.TimeSpanCase}
 */
proto.services.central.ExportReadingsRequest.prototype.getTimeSpanCase = function() {
  return /** @type {proto.services.central.ExportReadingsRequest.TimeSpanCase} */(jspb.Message.computeOneofCase(this, proto.services.central.ExportReadingsRequest.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    byIds: (f = msg.getByIds()) && proto.services.central.ExportReadingsRequest.DeviceIds.toObject(includeInstance, f),
    byPartner: (f = msg.getByPartner()) && proto.services.central.PartnerDevices.toObject(includeInstance, f),
    fileFormat: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filter: (f = msg.getFilter()) && proto.services.central.DeviceReadingsFilter.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    resolution: jspb.Message.getFieldWithDefault(msg, 8, 0),
    includeOnlyLatestReadings: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportReadingsRequest}
 */
proto.services.central.ExportReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportReadingsRequest;
  return proto.services.central.ExportReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportReadingsRequest}
 */
proto.services.central.ExportReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ExportReadingsRequest.DeviceIds;
      reader.readMessage(value,proto.services.central.ExportReadingsRequest.DeviceIds.deserializeBinaryFromReader);
      msg.setByIds(value);
      break;
    case 2:
      var value = new proto.services.central.PartnerDevices;
      reader.readMessage(value,proto.services.central.PartnerDevices.deserializeBinaryFromReader);
      msg.setByPartner(value);
      break;
    case 3:
      var value = /** @type {!proto.services.central.FileFormat} */ (reader.readEnum());
      msg.setFileFormat(value);
      break;
    case 4:
      var value = new proto.services.central.DeviceReadingsFilter;
      reader.readMessage(value,proto.services.central.DeviceReadingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 5:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 6:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 7:
      var value = /** @type {!proto.types.TimeInterval} */ (reader.readEnum());
      msg.setTimeInterval(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolution(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeOnlyLatestReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getByIds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.ExportReadingsRequest.DeviceIds.serializeBinaryToWriter
    );
  }
  f = message.getByPartner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.PartnerDevices.serializeBinaryToWriter
    );
  }
  f = message.getFileFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.central.DeviceReadingsFilter.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.types.TimeInterval} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIncludeOnlyLatestReadings();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ExportReadingsRequest.DeviceIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportReadingsRequest.DeviceIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportReadingsRequest.DeviceIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsRequest.DeviceIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportReadingsRequest.DeviceIds}
 */
proto.services.central.ExportReadingsRequest.DeviceIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportReadingsRequest.DeviceIds;
  return proto.services.central.ExportReadingsRequest.DeviceIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportReadingsRequest.DeviceIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportReadingsRequest.DeviceIds}
 */
proto.services.central.ExportReadingsRequest.DeviceIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportReadingsRequest.DeviceIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportReadingsRequest.DeviceIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsRequest.DeviceIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string device_ids = 1;
 * @return {!Array<string>}
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.ExportReadingsRequest.DeviceIds} returns this
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ExportReadingsRequest.DeviceIds} returns this
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ExportReadingsRequest.DeviceIds} returns this
 */
proto.services.central.ExportReadingsRequest.DeviceIds.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional DeviceIds by_ids = 1;
 * @return {?proto.services.central.ExportReadingsRequest.DeviceIds}
 */
proto.services.central.ExportReadingsRequest.prototype.getByIds = function() {
  return /** @type{?proto.services.central.ExportReadingsRequest.DeviceIds} */ (
    jspb.Message.getWrapperField(this, proto.services.central.ExportReadingsRequest.DeviceIds, 1));
};


/**
 * @param {?proto.services.central.ExportReadingsRequest.DeviceIds|undefined} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
*/
proto.services.central.ExportReadingsRequest.prototype.setByIds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.ExportReadingsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearByIds = function() {
  return this.setByIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasByIds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PartnerDevices by_partner = 2;
 * @return {?proto.services.central.PartnerDevices}
 */
proto.services.central.ExportReadingsRequest.prototype.getByPartner = function() {
  return /** @type{?proto.services.central.PartnerDevices} */ (
    jspb.Message.getWrapperField(this, proto.services.central.PartnerDevices, 2));
};


/**
 * @param {?proto.services.central.PartnerDevices|undefined} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
*/
proto.services.central.ExportReadingsRequest.prototype.setByPartner = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.ExportReadingsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearByPartner = function() {
  return this.setByPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasByPartner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FileFormat file_format = 3;
 * @return {!proto.services.central.FileFormat}
 */
proto.services.central.ExportReadingsRequest.prototype.getFileFormat = function() {
  return /** @type {!proto.services.central.FileFormat} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.central.FileFormat} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.setFileFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DeviceReadingsFilter filter = 4;
 * @return {?proto.services.central.DeviceReadingsFilter}
 */
proto.services.central.ExportReadingsRequest.prototype.getFilter = function() {
  return /** @type{?proto.services.central.DeviceReadingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.services.central.DeviceReadingsFilter, 4));
};


/**
 * @param {?proto.services.central.DeviceReadingsFilter|undefined} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
*/
proto.services.central.ExportReadingsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.DateTime start_time = 5;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportReadingsRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 5));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
*/
proto.services.central.ExportReadingsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.DateTime end_time = 6;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportReadingsRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 6));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
*/
proto.services.central.ExportReadingsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional types.TimeInterval time_interval = 7;
 * @return {!proto.types.TimeInterval}
 */
proto.services.central.ExportReadingsRequest.prototype.getTimeInterval = function() {
  return /** @type {!proto.types.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.TimeInterval} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.services.central.ExportReadingsRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearTimeInterval = function() {
  return jspb.Message.setOneofField(this, 7, proto.services.central.ExportReadingsRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasTimeInterval = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 resolution = 8;
 * @return {number}
 */
proto.services.central.ExportReadingsRequest.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.setResolution = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.services.central.ExportReadingsRequest.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.clearResolution = function() {
  return jspb.Message.setOneofField(this, 8, proto.services.central.ExportReadingsRequest.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool include_only_latest_readings = 9;
 * @return {boolean}
 */
proto.services.central.ExportReadingsRequest.prototype.getIncludeOnlyLatestReadings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ExportReadingsRequest} returns this
 */
proto.services.central.ExportReadingsRequest.prototype.setIncludeOnlyLatestReadings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportReadingsResponse}
 */
proto.services.central.ExportReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportReadingsResponse;
  return proto.services.central.ExportReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportReadingsResponse}
 */
proto.services.central.ExportReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.services.central.ExportReadingsResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportReadingsResponse} returns this
 */
proto.services.central.ExportReadingsResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.services.central.ExportReadingsResponse.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.services.central.ExportReadingsResponse.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsResponse.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.services.central.ExportReadingsResponse} returns this
 */
proto.services.central.ExportReadingsResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ExportDeviceDataRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportDeviceDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportDeviceDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportDeviceDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDeviceDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: (f = msg.getStartTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    resolution: jspb.Message.getFieldWithDefault(msg, 5, 0),
    exportTypesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportDeviceDataRequest}
 */
proto.services.central.ExportDeviceDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportDeviceDataRequest;
  return proto.services.central.ExportDeviceDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportDeviceDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportDeviceDataRequest}
 */
proto.services.central.ExportDeviceDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolution(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.types.ExportDataType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExportTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportDeviceDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportDeviceDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportDeviceDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDeviceDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getResolution();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getExportTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.DateTime start_time = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
*/
proto.services.central.ExportDeviceDataRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportDeviceDataRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_time = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
*/
proto.services.central.ExportDeviceDataRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportDeviceDataRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 resolution = 5;
 * @return {number}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.setResolution = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated types.ExportDataType export_types = 6;
 * @return {!Array<!proto.types.ExportDataType>}
 */
proto.services.central.ExportDeviceDataRequest.prototype.getExportTypesList = function() {
  return /** @type {!Array<!proto.types.ExportDataType>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.types.ExportDataType>} value
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.setExportTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.types.ExportDataType} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.addExportTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ExportDeviceDataRequest} returns this
 */
proto.services.central.ExportDeviceDataRequest.prototype.clearExportTypesList = function() {
  return this.setExportTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportDeviceDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportDeviceDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportDeviceDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDeviceDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportDeviceDataResponse}
 */
proto.services.central.ExportDeviceDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportDeviceDataResponse;
  return proto.services.central.ExportDeviceDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportDeviceDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportDeviceDataResponse}
 */
proto.services.central.ExportDeviceDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportDeviceDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportDeviceDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportDeviceDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDeviceDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.services.central.ExportDeviceDataResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportDeviceDataResponse} returns this
 */
proto.services.central.ExportDeviceDataResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.services.central.ExportDeviceDataResponse.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.services.central.ExportDeviceDataResponse.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.services.central.ExportDeviceDataResponse.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.services.central.ExportDeviceDataResponse} returns this
 */
proto.services.central.ExportDeviceDataResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ExportMultipleDevicesDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportMultipleDevicesDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportMultipleDevicesDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportMultipleDevicesDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dateTime: (f = msg.getDateTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    deviation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest}
 */
proto.services.central.ExportMultipleDevicesDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportMultipleDevicesDataRequest;
  return proto.services.central.ExportMultipleDevicesDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportMultipleDevicesDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest}
 */
proto.services.central.ExportMultipleDevicesDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setDateTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviation(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportMultipleDevicesDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportMultipleDevicesDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportMultipleDevicesDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDateTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getDeviation();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated string device_ids = 1;
 * @return {!Array<string>}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional google.type.DateTime date_time = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.getDateTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
*/
proto.services.central.ExportMultipleDevicesDataRequest.prototype.setDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.clearDateTime = function() {
  return this.setDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.hasDateTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 deviation = 3;
 * @return {number}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional types.Direction direction = 4;
 * @return {!proto.types.Direction}
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.ExportMultipleDevicesDataRequest} returns this
 */
proto.services.central.ExportMultipleDevicesDataRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportMultipleDevicesDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportMultipleDevicesDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportMultipleDevicesDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportMultipleDevicesDataResponse}
 */
proto.services.central.ExportMultipleDevicesDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportMultipleDevicesDataResponse;
  return proto.services.central.ExportMultipleDevicesDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportMultipleDevicesDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportMultipleDevicesDataResponse}
 */
proto.services.central.ExportMultipleDevicesDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportMultipleDevicesDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportMultipleDevicesDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportMultipleDevicesDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportMultipleDevicesDataResponse} returns this
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.services.central.ExportMultipleDevicesDataResponse} returns this
 */
proto.services.central.ExportMultipleDevicesDataResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportReadingsByPartnerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportReadingsByPartnerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsByPartnerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileFormat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: (f = msg.getStartTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 5, 0),
    includeOnlyLatestReadings: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportReadingsByPartnerIdRequest;
  return proto.services.central.ExportReadingsByPartnerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportReadingsByPartnerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.services.central.FileFormat} */ (reader.readEnum());
      msg.setFileFormat(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {!proto.types.TimeInterval} */ (reader.readEnum());
      msg.setTimeInterval(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeOnlyLatestReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportReadingsByPartnerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportReadingsByPartnerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsByPartnerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getTimeInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIncludeOnlyLatestReadings();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string partner_id = 1;
 * @return {string}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FileFormat file_format = 2;
 * @return {!proto.services.central.FileFormat}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getFileFormat = function() {
  return /** @type {!proto.services.central.FileFormat} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.services.central.FileFormat} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setFileFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.type.DateTime start_time = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
*/
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_time = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
*/
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional types.TimeInterval time_interval = 5;
 * @return {!proto.types.TimeInterval}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getTimeInterval = function() {
  return /** @type {!proto.types.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.TimeInterval} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool include_only_latest_readings = 6;
 * @return {boolean}
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.getIncludeOnlyLatestReadings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdRequest} returns this
 */
proto.services.central.ExportReadingsByPartnerIdRequest.prototype.setIncludeOnlyLatestReadings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportReadingsByPartnerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportReadingsByPartnerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsByPartnerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportReadingsByPartnerIdResponse}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportReadingsByPartnerIdResponse;
  return proto.services.central.ExportReadingsByPartnerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportReadingsByPartnerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportReadingsByPartnerIdResponse}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportReadingsByPartnerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportReadingsByPartnerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportReadingsByPartnerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdResponse} returns this
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.services.central.ExportReadingsByPartnerIdResponse} returns this
 */
proto.services.central.ExportReadingsByPartnerIdResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.PartnerDevices.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.PartnerDevices.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.PartnerDevices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.PartnerDevices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.PartnerDevices.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    deviceProvidersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    partnerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.PartnerDevices}
 */
proto.services.central.PartnerDevices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.PartnerDevices;
  return proto.services.central.PartnerDevices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.PartnerDevices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.PartnerDevices}
 */
proto.services.central.PartnerDevices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.types.Device.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceTypes(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.types.Device.Provider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceProviders(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.PartnerDevices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.PartnerDevices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.PartnerDevices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.PartnerDevices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getDeviceProvidersList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated types.Device.Type device_types = 1;
 * @return {!Array<!proto.types.Device.Type>}
 */
proto.services.central.PartnerDevices.prototype.getDeviceTypesList = function() {
  return /** @type {!Array<!proto.types.Device.Type>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.types.Device.Type>} value
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.setDeviceTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.types.Device.Type} value
 * @param {number=} opt_index
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.addDeviceTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.clearDeviceTypesList = function() {
  return this.setDeviceTypesList([]);
};


/**
 * repeated types.Device.Provider device_providers = 2;
 * @return {!Array<!proto.types.Device.Provider>}
 */
proto.services.central.PartnerDevices.prototype.getDeviceProvidersList = function() {
  return /** @type {!Array<!proto.types.Device.Provider>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.types.Device.Provider>} value
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.setDeviceProvidersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.types.Device.Provider} value
 * @param {number=} opt_index
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.addDeviceProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.clearDeviceProvidersList = function() {
  return this.setDeviceProvidersList([]);
};


/**
 * optional string partner_id = 3;
 * @return {string}
 */
proto.services.central.PartnerDevices.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.PartnerDevices} returns this
 */
proto.services.central.PartnerDevices.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.PartnerDevices.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestDeviceReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestDeviceReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestDeviceReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && proto.services.central.DeviceReadingsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestDeviceReadingsRequest}
 */
proto.services.central.GetLatestDeviceReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestDeviceReadingsRequest;
  return proto.services.central.GetLatestDeviceReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestDeviceReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestDeviceReadingsRequest}
 */
proto.services.central.GetLatestDeviceReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new proto.services.central.DeviceReadingsFilter;
      reader.readMessage(value,proto.services.central.DeviceReadingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestDeviceReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestDeviceReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestDeviceReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.DeviceReadingsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetLatestDeviceReadingsRequest} returns this
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceReadingsFilter filter = 2;
 * @return {?proto.services.central.DeviceReadingsFilter}
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.getFilter = function() {
  return /** @type{?proto.services.central.DeviceReadingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.services.central.DeviceReadingsFilter, 2));
};


/**
 * @param {?proto.services.central.DeviceReadingsFilter|undefined} value
 * @return {!proto.services.central.GetLatestDeviceReadingsRequest} returns this
*/
proto.services.central.GetLatestDeviceReadingsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLatestDeviceReadingsRequest} returns this
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLatestDeviceReadingsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetLatestDeviceReadingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestDeviceReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestDeviceReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestDeviceReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestDeviceReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestReadingsList: jspb.Message.toObjectList(msg.getLatestReadingsList(),
    types_device_reading_pb.DeviceReading.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestDeviceReadingsResponse}
 */
proto.services.central.GetLatestDeviceReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestDeviceReadingsResponse;
  return proto.services.central.GetLatestDeviceReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestDeviceReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestDeviceReadingsResponse}
 */
proto.services.central.GetLatestDeviceReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_reading_pb.DeviceReading;
      reader.readMessage(value,types_device_reading_pb.DeviceReading.deserializeBinaryFromReader);
      msg.addLatestReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestDeviceReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestDeviceReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestDeviceReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestDeviceReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_reading_pb.DeviceReading.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.DeviceReading latest_readings = 1;
 * @return {!Array<!proto.types.DeviceReading>}
 */
proto.services.central.GetLatestDeviceReadingsResponse.prototype.getLatestReadingsList = function() {
  return /** @type{!Array<!proto.types.DeviceReading>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_reading_pb.DeviceReading, 1));
};


/**
 * @param {!Array<!proto.types.DeviceReading>} value
 * @return {!proto.services.central.GetLatestDeviceReadingsResponse} returns this
*/
proto.services.central.GetLatestDeviceReadingsResponse.prototype.setLatestReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DeviceReading=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceReading}
 */
proto.services.central.GetLatestDeviceReadingsResponse.prototype.addLatestReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DeviceReading, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetLatestDeviceReadingsResponse} returns this
 */
proto.services.central.GetLatestDeviceReadingsResponse.prototype.clearLatestReadingsList = function() {
  return this.setLatestReadingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetForwardingRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetForwardingRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetForwardingRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetForwardingRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetForwardingRulesRequest}
 */
proto.services.central.GetForwardingRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetForwardingRulesRequest;
  return proto.services.central.GetForwardingRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetForwardingRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetForwardingRulesRequest}
 */
proto.services.central.GetForwardingRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetForwardingRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetForwardingRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetForwardingRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetForwardingRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rule_id = 1;
 * @return {string}
 */
proto.services.central.GetForwardingRulesRequest.prototype.getRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetForwardingRulesRequest} returns this
 */
proto.services.central.GetForwardingRulesRequest.prototype.setRuleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetForwardingRulesRequest} returns this
 */
proto.services.central.GetForwardingRulesRequest.prototype.clearRuleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetForwardingRulesRequest.prototype.hasRuleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string partner_id = 2;
 * @return {string}
 */
proto.services.central.GetForwardingRulesRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetForwardingRulesRequest} returns this
 */
proto.services.central.GetForwardingRulesRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetForwardingRulesRequest} returns this
 */
proto.services.central.GetForwardingRulesRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetForwardingRulesRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetForwardingRulesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetForwardingRulesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetForwardingRulesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetForwardingRulesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetForwardingRulesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    types_forwarding_pb.ForwardingRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetForwardingRulesResponse}
 */
proto.services.central.GetForwardingRulesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetForwardingRulesResponse;
  return proto.services.central.GetForwardingRulesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetForwardingRulesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetForwardingRulesResponse}
 */
proto.services.central.GetForwardingRulesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_forwarding_pb.ForwardingRule;
      reader.readMessage(value,types_forwarding_pb.ForwardingRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetForwardingRulesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetForwardingRulesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetForwardingRulesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetForwardingRulesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_forwarding_pb.ForwardingRule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.ForwardingRule rules = 1;
 * @return {!Array<!proto.types.ForwardingRule>}
 */
proto.services.central.GetForwardingRulesResponse.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.types.ForwardingRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_forwarding_pb.ForwardingRule, 1));
};


/**
 * @param {!Array<!proto.types.ForwardingRule>} value
 * @return {!proto.services.central.GetForwardingRulesResponse} returns this
*/
proto.services.central.GetForwardingRulesResponse.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.ForwardingRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.ForwardingRule}
 */
proto.services.central.GetForwardingRulesResponse.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.ForwardingRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetForwardingRulesResponse} returns this
 */
proto.services.central.GetForwardingRulesResponse.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddForwardingRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddForwardingRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddForwardingRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddForwardingRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rule: (f = msg.getRule()) && types_forwarding_pb.ForwardingRule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddForwardingRuleRequest}
 */
proto.services.central.AddForwardingRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddForwardingRuleRequest;
  return proto.services.central.AddForwardingRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddForwardingRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddForwardingRuleRequest}
 */
proto.services.central.AddForwardingRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_forwarding_pb.ForwardingRule;
      reader.readMessage(value,types_forwarding_pb.ForwardingRule.deserializeBinaryFromReader);
      msg.setRule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddForwardingRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddForwardingRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddForwardingRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddForwardingRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_forwarding_pb.ForwardingRule.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.ForwardingRule rule = 1;
 * @return {?proto.types.ForwardingRule}
 */
proto.services.central.AddForwardingRuleRequest.prototype.getRule = function() {
  return /** @type{?proto.types.ForwardingRule} */ (
    jspb.Message.getWrapperField(this, types_forwarding_pb.ForwardingRule, 1));
};


/**
 * @param {?proto.types.ForwardingRule|undefined} value
 * @return {!proto.services.central.AddForwardingRuleRequest} returns this
*/
proto.services.central.AddForwardingRuleRequest.prototype.setRule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddForwardingRuleRequest} returns this
 */
proto.services.central.AddForwardingRuleRequest.prototype.clearRule = function() {
  return this.setRule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddForwardingRuleRequest.prototype.hasRule = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddForwardingRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddForwardingRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddForwardingRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddForwardingRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddForwardingRuleResponse}
 */
proto.services.central.AddForwardingRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddForwardingRuleResponse;
  return proto.services.central.AddForwardingRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddForwardingRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddForwardingRuleResponse}
 */
proto.services.central.AddForwardingRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddForwardingRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddForwardingRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddForwardingRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddForwardingRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteForwardingRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteForwardingRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteForwardingRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteForwardingRuleRequest}
 */
proto.services.central.DeleteForwardingRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteForwardingRuleRequest;
  return proto.services.central.DeleteForwardingRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteForwardingRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteForwardingRuleRequest}
 */
proto.services.central.DeleteForwardingRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteForwardingRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteForwardingRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteForwardingRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string rule_id = 1;
 * @return {string}
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.getRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteForwardingRuleRequest} returns this
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.setRuleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner_id = 2;
 * @return {string}
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteForwardingRuleRequest} returns this
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteForwardingRuleRequest} returns this
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteForwardingRuleRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteForwardingRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteForwardingRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteForwardingRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteForwardingRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteForwardingRuleResponse}
 */
proto.services.central.DeleteForwardingRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteForwardingRuleResponse;
  return proto.services.central.DeleteForwardingRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteForwardingRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteForwardingRuleResponse}
 */
proto.services.central.DeleteForwardingRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteForwardingRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteForwardingRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteForwardingRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteForwardingRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestStatusHandlesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestStatusHandlesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestStatusHandlesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusHandlesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestStatusHandlesRequest}
 */
proto.services.central.GetLatestStatusHandlesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestStatusHandlesRequest;
  return proto.services.central.GetLatestStatusHandlesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestStatusHandlesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestStatusHandlesRequest}
 */
proto.services.central.GetLatestStatusHandlesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestStatusHandlesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestStatusHandlesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestStatusHandlesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusHandlesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetLatestStatusHandlesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetLatestStatusHandlesRequest} returns this
 */
proto.services.central.GetLatestStatusHandlesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetLatestStatusHandlesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestStatusHandlesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestStatusHandlesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestStatusHandlesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusHandlesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    handlesList: jspb.Message.toObjectList(msg.getHandlesList(),
    types_latest_status_pb.LatestStatusHandle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestStatusHandlesResponse}
 */
proto.services.central.GetLatestStatusHandlesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestStatusHandlesResponse;
  return proto.services.central.GetLatestStatusHandlesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestStatusHandlesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestStatusHandlesResponse}
 */
proto.services.central.GetLatestStatusHandlesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_latest_status_pb.LatestStatusHandle;
      reader.readMessage(value,types_latest_status_pb.LatestStatusHandle.deserializeBinaryFromReader);
      msg.addHandles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestStatusHandlesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestStatusHandlesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestStatusHandlesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusHandlesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_latest_status_pb.LatestStatusHandle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.LatestStatusHandle handles = 1;
 * @return {!Array<!proto.types.LatestStatusHandle>}
 */
proto.services.central.GetLatestStatusHandlesResponse.prototype.getHandlesList = function() {
  return /** @type{!Array<!proto.types.LatestStatusHandle>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_latest_status_pb.LatestStatusHandle, 1));
};


/**
 * @param {!Array<!proto.types.LatestStatusHandle>} value
 * @return {!proto.services.central.GetLatestStatusHandlesResponse} returns this
*/
proto.services.central.GetLatestStatusHandlesResponse.prototype.setHandlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.LatestStatusHandle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.LatestStatusHandle}
 */
proto.services.central.GetLatestStatusHandlesResponse.prototype.addHandles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.LatestStatusHandle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetLatestStatusHandlesResponse} returns this
 */
proto.services.central.GetLatestStatusHandlesResponse.prototype.clearHandlesList = function() {
  return this.setHandlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    handle: (f = msg.getHandle()) && types_latest_status_pb.LatestStatusHandle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestStatusRequest}
 */
proto.services.central.GetLatestStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestStatusRequest;
  return proto.services.central.GetLatestStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestStatusRequest}
 */
proto.services.central.GetLatestStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_latest_status_pb.LatestStatusHandle;
      reader.readMessage(value,types_latest_status_pb.LatestStatusHandle.deserializeBinaryFromReader);
      msg.setHandle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_latest_status_pb.LatestStatusHandle.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.LatestStatusHandle handle = 1;
 * @return {?proto.types.LatestStatusHandle}
 */
proto.services.central.GetLatestStatusRequest.prototype.getHandle = function() {
  return /** @type{?proto.types.LatestStatusHandle} */ (
    jspb.Message.getWrapperField(this, types_latest_status_pb.LatestStatusHandle, 1));
};


/**
 * @param {?proto.types.LatestStatusHandle|undefined} value
 * @return {!proto.services.central.GetLatestStatusRequest} returns this
*/
proto.services.central.GetLatestStatusRequest.prototype.setHandle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLatestStatusRequest} returns this
 */
proto.services.central.GetLatestStatusRequest.prototype.clearHandle = function() {
  return this.setHandle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLatestStatusRequest.prototype.hasHandle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLatestStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLatestStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLatestStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && types_latest_status_pb.LatestStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLatestStatusResponse}
 */
proto.services.central.GetLatestStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLatestStatusResponse;
  return proto.services.central.GetLatestStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLatestStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLatestStatusResponse}
 */
proto.services.central.GetLatestStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_latest_status_pb.LatestStatus;
      reader.readMessage(value,types_latest_status_pb.LatestStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLatestStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLatestStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLatestStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLatestStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_latest_status_pb.LatestStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.LatestStatus status = 1;
 * @return {?proto.types.LatestStatus}
 */
proto.services.central.GetLatestStatusResponse.prototype.getStatus = function() {
  return /** @type{?proto.types.LatestStatus} */ (
    jspb.Message.getWrapperField(this, types_latest_status_pb.LatestStatus, 1));
};


/**
 * @param {?proto.types.LatestStatus|undefined} value
 * @return {!proto.services.central.GetLatestStatusResponse} returns this
*/
proto.services.central.GetLatestStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetLatestStatusResponse} returns this
 */
proto.services.central.GetLatestStatusResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetLatestStatusResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMarketPremiumInfosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMarketPremiumInfosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMarketPremiumInfosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketValueType: (f = msg.getMarketValueType()) && types_market_premium_pb.MarketValueType.toObject(includeInstance, f),
    energySource: jspb.Message.getFieldWithDefault(msg, 3, 0),
    referenceValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    numConsecutiveNegativePrices: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMarketPremiumInfosRequest}
 */
proto.services.central.GetMarketPremiumInfosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMarketPremiumInfosRequest;
  return proto.services.central.GetMarketPremiumInfosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMarketPremiumInfosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMarketPremiumInfosRequest}
 */
proto.services.central.GetMarketPremiumInfosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_market_premium_pb.MarketValueType;
      reader.readMessage(value,types_market_premium_pb.MarketValueType.deserializeBinaryFromReader);
      msg.setMarketValueType(value);
      break;
    case 3:
      var value = /** @type {!proto.types.EnergySource} */ (reader.readEnum());
      msg.setEnergySource(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReferenceValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumConsecutiveNegativePrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMarketPremiumInfosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMarketPremiumInfosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMarketPremiumInfosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketValueType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_market_premium_pb.MarketValueType.serializeBinaryToWriter
    );
  }
  f = message.getEnergySource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.MarketValueType market_value_type = 2;
 * @return {?proto.types.MarketValueType}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.getMarketValueType = function() {
  return /** @type{?proto.types.MarketValueType} */ (
    jspb.Message.getWrapperField(this, types_market_premium_pb.MarketValueType, 2));
};


/**
 * @param {?proto.types.MarketValueType|undefined} value
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
*/
proto.services.central.GetMarketPremiumInfosRequest.prototype.setMarketValueType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.clearMarketValueType = function() {
  return this.setMarketValueType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.hasMarketValueType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.EnergySource energy_source = 3;
 * @return {!proto.types.EnergySource}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.getEnergySource = function() {
  return /** @type {!proto.types.EnergySource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.EnergySource} value
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.setEnergySource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double reference_value = 4;
 * @return {number}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.getReferenceValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.setReferenceValue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.clearReferenceValue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.hasReferenceValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 num_consecutive_negative_prices = 5;
 * @return {number}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.getNumConsecutiveNegativePrices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.setNumConsecutiveNegativePrices = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetMarketPremiumInfosRequest} returns this
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.clearNumConsecutiveNegativePrices = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetMarketPremiumInfosRequest.prototype.hasNumConsecutiveNegativePrices = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMarketPremiumInfosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMarketPremiumInfosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMarketPremiumInfosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMarketPremiumInfosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMarketPremiumInfosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    infosList: jspb.Message.toObjectList(msg.getInfosList(),
    types_market_premium_pb.MarketPremiumInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMarketPremiumInfosResponse}
 */
proto.services.central.GetMarketPremiumInfosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMarketPremiumInfosResponse;
  return proto.services.central.GetMarketPremiumInfosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMarketPremiumInfosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMarketPremiumInfosResponse}
 */
proto.services.central.GetMarketPremiumInfosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_market_premium_pb.MarketPremiumInfo;
      reader.readMessage(value,types_market_premium_pb.MarketPremiumInfo.deserializeBinaryFromReader);
      msg.addInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMarketPremiumInfosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMarketPremiumInfosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMarketPremiumInfosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMarketPremiumInfosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_market_premium_pb.MarketPremiumInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.MarketPremiumInfo infos = 1;
 * @return {!Array<!proto.types.MarketPremiumInfo>}
 */
proto.services.central.GetMarketPremiumInfosResponse.prototype.getInfosList = function() {
  return /** @type{!Array<!proto.types.MarketPremiumInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_market_premium_pb.MarketPremiumInfo, 1));
};


/**
 * @param {!Array<!proto.types.MarketPremiumInfo>} value
 * @return {!proto.services.central.GetMarketPremiumInfosResponse} returns this
*/
proto.services.central.GetMarketPremiumInfosResponse.prototype.setInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.MarketPremiumInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.MarketPremiumInfo}
 */
proto.services.central.GetMarketPremiumInfosResponse.prototype.addInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.MarketPremiumInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMarketPremiumInfosResponse} returns this
 */
proto.services.central.GetMarketPremiumInfosResponse.prototype.clearInfosList = function() {
  return this.setInfosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetBatteryCapacityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetBatteryCapacityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetBatteryCapacityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetBatteryCapacityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    batteryCapacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetBatteryCapacityRequest}
 */
proto.services.central.SetBatteryCapacityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetBatteryCapacityRequest;
  return proto.services.central.SetBatteryCapacityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetBatteryCapacityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetBatteryCapacityRequest}
 */
proto.services.central.SetBatteryCapacityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBatteryCapacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetBatteryCapacityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetBatteryCapacityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetBatteryCapacityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetBatteryCapacityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBatteryCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.SetBatteryCapacityRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetBatteryCapacityRequest} returns this
 */
proto.services.central.SetBatteryCapacityRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double battery_capacity = 2;
 * @return {number}
 */
proto.services.central.SetBatteryCapacityRequest.prototype.getBatteryCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SetBatteryCapacityRequest} returns this
 */
proto.services.central.SetBatteryCapacityRequest.prototype.setBatteryCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetBatteryCapacityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetBatteryCapacityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetBatteryCapacityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetBatteryCapacityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetBatteryCapacityResponse}
 */
proto.services.central.SetBatteryCapacityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetBatteryCapacityResponse;
  return proto.services.central.SetBatteryCapacityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetBatteryCapacityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetBatteryCapacityResponse}
 */
proto.services.central.SetBatteryCapacityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetBatteryCapacityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetBatteryCapacityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetBatteryCapacityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetBatteryCapacityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBatteryCapacityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBatteryCapacityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBatteryCapacityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBatteryCapacityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBatteryCapacityRequest}
 */
proto.services.central.GetBatteryCapacityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBatteryCapacityRequest;
  return proto.services.central.GetBatteryCapacityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBatteryCapacityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBatteryCapacityRequest}
 */
proto.services.central.GetBatteryCapacityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBatteryCapacityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBatteryCapacityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBatteryCapacityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBatteryCapacityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetBatteryCapacityRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetBatteryCapacityRequest} returns this
 */
proto.services.central.GetBatteryCapacityRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBatteryCapacityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBatteryCapacityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBatteryCapacityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBatteryCapacityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batteryCapacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBatteryCapacityResponse}
 */
proto.services.central.GetBatteryCapacityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBatteryCapacityResponse;
  return proto.services.central.GetBatteryCapacityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBatteryCapacityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBatteryCapacityResponse}
 */
proto.services.central.GetBatteryCapacityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBatteryCapacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBatteryCapacityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBatteryCapacityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBatteryCapacityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBatteryCapacityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double battery_capacity = 1;
 * @return {number}
 */
proto.services.central.GetBatteryCapacityResponse.prototype.getBatteryCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetBatteryCapacityResponse} returns this
 */
proto.services.central.GetBatteryCapacityResponse.prototype.setBatteryCapacity = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetBatteryCapacityResponse} returns this
 */
proto.services.central.GetBatteryCapacityResponse.prototype.clearBatteryCapacity = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetBatteryCapacityResponse.prototype.hasBatteryCapacity = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetReadingsRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.GetReadingsRequest.QueryCase = {
  QUERY_NOT_SET: 0,
  BY_DEVICE: 1,
  BY_SPACE: 2
};

/**
 * @return {proto.services.central.GetReadingsRequest.QueryCase}
 */
proto.services.central.GetReadingsRequest.prototype.getQueryCase = function() {
  return /** @type {proto.services.central.GetReadingsRequest.QueryCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetReadingsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    byDevice: (f = msg.getByDevice()) && proto.services.central.GetReadingsRequest.ByDevice.toObject(includeInstance, f),
    bySpace: (f = msg.getBySpace()) && proto.services.central.GetReadingsRequest.BySpace.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    timeInterval: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measurement: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReadingsRequest}
 */
proto.services.central.GetReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReadingsRequest;
  return proto.services.central.GetReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReadingsRequest}
 */
proto.services.central.GetReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.GetReadingsRequest.ByDevice;
      reader.readMessage(value,proto.services.central.GetReadingsRequest.ByDevice.deserializeBinaryFromReader);
      msg.setByDevice(value);
      break;
    case 2:
      var value = new proto.services.central.GetReadingsRequest.BySpace;
      reader.readMessage(value,proto.services.central.GetReadingsRequest.BySpace.deserializeBinaryFromReader);
      msg.setBySpace(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {!proto.types.TimeInterval} */ (reader.readEnum());
      msg.setTimeInterval(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getByDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.GetReadingsRequest.ByDevice.serializeBinaryToWriter
    );
  }
  f = message.getBySpace();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.GetReadingsRequest.BySpace.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getTimeInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReadingsRequest.ByDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReadingsRequest.ByDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReadingsRequest.ByDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.ByDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReadingsRequest.ByDevice}
 */
proto.services.central.GetReadingsRequest.ByDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReadingsRequest.ByDevice;
  return proto.services.central.GetReadingsRequest.ByDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReadingsRequest.ByDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReadingsRequest.ByDevice}
 */
proto.services.central.GetReadingsRequest.ByDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReadingsRequest.ByDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReadingsRequest.ByDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReadingsRequest.ByDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.ByDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetReadingsRequest.ByDevice.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReadingsRequest.ByDevice} returns this
 */
proto.services.central.GetReadingsRequest.ByDevice.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReadingsRequest.BySpace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReadingsRequest.BySpace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.BySpace.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReadingsRequest.BySpace}
 */
proto.services.central.GetReadingsRequest.BySpace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReadingsRequest.BySpace;
  return proto.services.central.GetReadingsRequest.BySpace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReadingsRequest.BySpace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReadingsRequest.BySpace}
 */
proto.services.central.GetReadingsRequest.BySpace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReadingsRequest.BySpace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReadingsRequest.BySpace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsRequest.BySpace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReadingsRequest.BySpace} returns this
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Device.Medium medium = 2;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.GetReadingsRequest.BySpace} returns this
 */
proto.services.central.GetReadingsRequest.BySpace.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ByDevice by_device = 1;
 * @return {?proto.services.central.GetReadingsRequest.ByDevice}
 */
proto.services.central.GetReadingsRequest.prototype.getByDevice = function() {
  return /** @type{?proto.services.central.GetReadingsRequest.ByDevice} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GetReadingsRequest.ByDevice, 1));
};


/**
 * @param {?proto.services.central.GetReadingsRequest.ByDevice|undefined} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
*/
proto.services.central.GetReadingsRequest.prototype.setByDevice = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetReadingsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.clearByDevice = function() {
  return this.setByDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReadingsRequest.prototype.hasByDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BySpace by_space = 2;
 * @return {?proto.services.central.GetReadingsRequest.BySpace}
 */
proto.services.central.GetReadingsRequest.prototype.getBySpace = function() {
  return /** @type{?proto.services.central.GetReadingsRequest.BySpace} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GetReadingsRequest.BySpace, 2));
};


/**
 * @param {?proto.services.central.GetReadingsRequest.BySpace|undefined} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
*/
proto.services.central.GetReadingsRequest.prototype.setBySpace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.GetReadingsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.clearBySpace = function() {
  return this.setBySpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReadingsRequest.prototype.hasBySpace = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.DateTime start_time = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetReadingsRequest.prototype.getStartTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
*/
proto.services.central.GetReadingsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReadingsRequest.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.DateTime end_time = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.services.central.GetReadingsRequest.prototype.getEndTime = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
*/
proto.services.central.GetReadingsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReadingsRequest.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional types.TimeInterval time_interval = 5;
 * @return {!proto.types.TimeInterval}
 */
proto.services.central.GetReadingsRequest.prototype.getTimeInterval = function() {
  return /** @type {!proto.types.TimeInterval} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.TimeInterval} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.setTimeInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional types.Measurement measurement = 6;
 * @return {!proto.types.Measurement}
 */
proto.services.central.GetReadingsRequest.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.services.central.GetReadingsRequest} returns this
 */
proto.services.central.GetReadingsRequest.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetReadingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    readingsList: jspb.Message.toObjectList(msg.getReadingsList(),
    types_device_reading_pb.DeviceReading.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReadingsResponse}
 */
proto.services.central.GetReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReadingsResponse;
  return proto.services.central.GetReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReadingsResponse}
 */
proto.services.central.GetReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_reading_pb.DeviceReading;
      reader.readMessage(value,types_device_reading_pb.DeviceReading.deserializeBinaryFromReader);
      msg.addReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_reading_pb.DeviceReading.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.DeviceReading readings = 1;
 * @return {!Array<!proto.types.DeviceReading>}
 */
proto.services.central.GetReadingsResponse.prototype.getReadingsList = function() {
  return /** @type{!Array<!proto.types.DeviceReading>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_reading_pb.DeviceReading, 1));
};


/**
 * @param {!Array<!proto.types.DeviceReading>} value
 * @return {!proto.services.central.GetReadingsResponse} returns this
*/
proto.services.central.GetReadingsResponse.prototype.setReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DeviceReading=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceReading}
 */
proto.services.central.GetReadingsResponse.prototype.addReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DeviceReading, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReadingsResponse} returns this
 */
proto.services.central.GetReadingsResponse.prototype.clearReadingsList = function() {
  return this.setReadingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.StoreEnergySpotPricesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreEnergySpotPricesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreEnergySpotPricesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreEnergySpotPricesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreEnergySpotPricesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    types_spot_price_pb.EnergySpotPrice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreEnergySpotPricesRequest}
 */
proto.services.central.StoreEnergySpotPricesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreEnergySpotPricesRequest;
  return proto.services.central.StoreEnergySpotPricesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreEnergySpotPricesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreEnergySpotPricesRequest}
 */
proto.services.central.StoreEnergySpotPricesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_spot_price_pb.EnergySpotPrice;
      reader.readMessage(value,types_spot_price_pb.EnergySpotPrice.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreEnergySpotPricesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreEnergySpotPricesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreEnergySpotPricesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreEnergySpotPricesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_spot_price_pb.EnergySpotPrice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergySpotPrice prices = 1;
 * @return {!Array<!proto.types.EnergySpotPrice>}
 */
proto.services.central.StoreEnergySpotPricesRequest.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.types.EnergySpotPrice>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_spot_price_pb.EnergySpotPrice, 1));
};


/**
 * @param {!Array<!proto.types.EnergySpotPrice>} value
 * @return {!proto.services.central.StoreEnergySpotPricesRequest} returns this
*/
proto.services.central.StoreEnergySpotPricesRequest.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergySpotPrice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergySpotPrice}
 */
proto.services.central.StoreEnergySpotPricesRequest.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergySpotPrice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StoreEnergySpotPricesRequest} returns this
 */
proto.services.central.StoreEnergySpotPricesRequest.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreEnergySpotPricesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreEnergySpotPricesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreEnergySpotPricesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreEnergySpotPricesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreEnergySpotPricesResponse}
 */
proto.services.central.StoreEnergySpotPricesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreEnergySpotPricesResponse;
  return proto.services.central.StoreEnergySpotPricesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreEnergySpotPricesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreEnergySpotPricesResponse}
 */
proto.services.central.StoreEnergySpotPricesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreEnergySpotPricesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreEnergySpotPricesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreEnergySpotPricesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreEnergySpotPricesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.services.central.FileFormat = {
  XAP_E3_CSV: 0,
  STANDARD_CSV: 1
};

goog.object.extend(exports, proto.services.central);
