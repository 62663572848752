
import { Component, Prop } from "vue-property-decorator";
import TariffWatchdogExplanation from "@/components/subscription/TariffWatchdogExplanation.vue";
import {
  failure,
  initialized,
  pending,
  RemoteCall,
  RemoteData,
  RemoteDataVue,
  success,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";
import {
  CancelSubscriptionRequest,
  GetSubscriptionPlansRequest,
  SubscribeToPlanRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { userServiceClient } from "@/config/service-clients";
import subscription from "@/store/modules/subscription";
import { getAuthService } from "@/auth";
import { paths } from "@/router/routes";

@Component({
  components: {
    TariffWatchdogExplanation,
  },
})
export default class SubscriptionPlans extends RemoteDataVue {
  @Prop({ default: true }) isSignedIn: boolean;

  subscriptionPlans: RemoteData<UserError, SubscriptionPlan[]> = initialized;
  subscribeCall: RemoteCall<UserError> = initialized;
  cancelCall: RemoteCall<UserError> = initialized;
  confirmDialog: boolean = false;
  confirmCheckbox: boolean = false;
  planToSubscribe: SubscriptionPlan | null = null;
  cancelDialog: boolean = false;
  planToCancel: SubscriptionPlan | null = null;
  selectedCancelReason: string | null = null;

  cancelReasons = [
    "Zu teuer",
    "Meine Geräte werden nicht unterstützt",
    "Fehlender Funktionsumfang",
    "Die kostenlose Version reicht mir",
    "Sonstiges",
  ];

  otherReason: string = "";

  mounted(): void {
    this.getPlans();
  }

  async getPlans(): Promise<void> {
    try {
      this.subscriptionPlans = pending;

      const response = await userServiceClient.getSubscriptionPlans(new GetSubscriptionPlansRequest(), {});
      this.subscriptionPlans = success(response.getSubscriptionPlansList());
    } catch (error) {
      this.subscriptionPlans = failure(userErrorFrom(error));
    }
  }

  async subscribeToPlan(plan: SubscriptionPlan, confirmed: boolean = false): Promise<void> {
    if (!confirmed && plan.getPaymentType() === SubscriptionPlan.PaymentType.INVOICE) {
      this.confirmDialog = true;
      this.planToSubscribe = plan;
      return;
    }

    if (plan.getPaymentType() === SubscriptionPlan.PaymentType.EXTERNAL_URL) {
      window.open(plan.getExternalPaymentUrl(), "_blank");
      return;
    }

    const request = new SubscribeToPlanRequest();
    request.setBaseUrl(window.location.origin);
    request.setSubscriptionPlanId(plan.getId());

    try {
      this.subscribeCall = pending;

      const response = await userServiceClient.subscribeToPlan(request, {});
      this.subscribeCall = success(void 0);
      this.confirmDialog = false;

      if (response.getSubscriptionCompleted()) {
        this.getPlans();
        subscription.getCurrentSubscriptionPlan();
      } else if (response.getStripeCheckoutUrl()) {
        window.open(response.getStripeCheckoutUrl(), "_self");
      }
    } catch (error) {
      this.subscribeCall = failure(userErrorFrom(error));
    }
  }

  onActivatePlanClicked(monthlyFee: number): void {
    if (monthlyFee == 0) {
      this.$gtag.event("registration-clicked", {
        event_label: "Free Plan Registration clicked",
        event_category: "user-action",
      });
    } else {
      this.$gtag.event("registration-clicked", {
        event_label: "Pro Plan Registration clicked",
        event_category: "user-action",
      });
    }
    this.$gtag.event("conversion", {
      send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
      value: monthlyFee * 3,
      currency: "USD",
    });
    this.$cookies.set("REDIRECT_PATH", paths.platform.account.subscriptions);
    getAuthService().loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  }

  async cancelSubscription(plan: SubscriptionPlan, confirmed: boolean = false): Promise<void> {
    if (!confirmed) {
      this.cancelDialog = true;
      this.planToCancel = plan;
      return;
    }

    var cancelReason = "";
    if (this.selectedCancelReason) {
      if (this.selectedCancelReason === "Sonstiges") {
        cancelReason = this.otherReason;
      } else {
        cancelReason = this.selectedCancelReason;
      }
    }

    try {
      const request = new CancelSubscriptionRequest();
      request.setReason(cancelReason);
      request.setSubscriptionPlanId(plan.getId());

      this.cancelCall = pending;
      await userServiceClient.cancelSubscription(request, {});
      this.cancelCall = success(void 0);
      this.cancelDialog = false;
      this.getPlans();
      subscription.getCurrentSubscriptionPlan();
    } catch (error) {
      this.cancelCall = failure(userErrorFrom(error));
    }
  }
}
