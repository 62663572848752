import { render, staticRenderFns } from "./SpaceDevices.vue?vue&type=template&id=5de85daf&scoped=true"
import script from "./SpaceDevices.vue?vue&type=script&lang=ts"
export * from "./SpaceDevices.vue?vue&type=script&lang=ts"
import style0 from "./SpaceDevices.vue?vue&type=style&index=0&id=5de85daf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5de85daf",
  null
  
)

export default component.exports