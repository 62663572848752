
import { Vue, Component, Prop } from "vue-property-decorator";
import DeviceActionComponent from "./DeviceAction.vue";
import ChargingActionComponent from "./ChargingAction.vue";
import NotificationActionComponent from "./NotificationAction.vue";
import { Action } from "zaehlerfreunde-proto-types/automations_pb";

@Component({
  components: {
    DeviceActionComponent,
    ChargingActionComponent,
    NotificationActionComponent,
  },
})
export default class ActionComponent extends Vue {
  ActionType = Action.ActionCase;

  @Prop()
  value: Action;

  @Prop({ default: false })
  editable: boolean;

  @Prop({ default: false })
  showPushNotificationCheck: boolean;

  editing: boolean = false;
  valid: boolean = false;

  mounted(): void {
    this.editing = this.editable;
  }

  onFinishedEditingClicked(): void {
    this.editing = false;
    this.$emit("input", this.value);
  }

  onDeleteClicked(): void {
    this.$emit("delete");
  }
}
