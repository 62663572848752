
import { tariffServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { RemoteData, initialized, pending, success, failure } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetPartnerTariffsRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class TariffSelect extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  tariffs: RemoteData<UserError, Tariff[]> = initialized;
  selectedTariffId: string | null = null;
  getTariffsTimeoutHandle: number | null = null;
  searchFilter: string = "";

  @Watch("selectedTariffId")
  onSelectedSpaceIdChanged() {
    if (this.selectedTariffId) {
      this.$emit("input", this.selectedTariffId);
    }
  }

  @Watch("searchFilter")
  onSearchFilterChanged(): void {
    const selectedTariff = this.tariffs.data?.find((s) => s.getId() === this.selectedTariffId);

    if (selectedTariff?.getName() !== this.searchFilter) {
      clearTimeout(this.getTariffsTimeoutHandle ?? undefined);
      this.getTariffsTimeoutHandle = setTimeout(this.getTariffs, 500);
    }
  }

  get tariffItems() {
    return (
      this.tariffs.data?.map((s) => ({
        text: s.getName(),
        value: s.getId(),
      })) ?? []
    );
  }

  async getTariffs(): Promise<void> {
    try {
      this.tariffs = pending;

      const request = new GetPartnerTariffsRequest();
      request.setSearch(this.searchFilter);
      request.setPagination(new Pagination().setPage(0).setPageSize(1000));

      if (this.partner.data) {
        request.setPartnerId(this.partner.data.getId());
      }

      const response = await tariffServiceClient.getPartnerTariffs(request, {});

      this.tariffs = success(response.getTariffsList());
    } catch (error) {
      this.tariffs = failure(userErrorFrom(error));
    }
  }
}
