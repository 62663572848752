
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetAdminUsersRequest } from "zaehlerfreunde-central/user_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import moment from "moment";
import UsersTable from "@/components/users-table/UsersTable.vue";
import RemoveAdminDialog from "./RemoveAdminDialog.vue";
import AddAdminDialog from "./AddAdminDialog.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { userServiceClient } from "@/config/service-clients";

interface UserRow {
  id: string;
  name: string;
  email: string;
  picture: string;
  lastLogin: string;
  loginCount: number;
}

@Component({
  components: {
    UsersTable,
    RemoveAdminDialog,
    AddAdminDialog,
  },
})
export default class AdminsOverview extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  admins: RemoteData<UserError, User[]> = initialized;

  search: string = "";
  selectedUserForAdminRemove: UserRow | null = null;

  removeAdminDialog: boolean = false;
  addAdminDialog: boolean = false;

  itemControls = [
    {
      icon: "mdi-close-circle-outline",
      event: "remove-admin",
      color: "error",
    },
  ];

  headerControls = [
    {
      icon: "mdi-account-plus",
      event: "add-admin",
    },
  ];

  beforeMount(): void {
    this.loadUsers();
  }

  get users(): UserRow[] {
    let partnerUsers: UserRow[] = [];
    this.admins.list.forEach((u) => {
      partnerUsers.push({
        id: u.getId(),
        name: u.getName(),
        email: u.getEmail(),
        picture: u.getUserProfileInfo()?.getPicture() ?? "",
        loginCount: u.getUserProfileInfo()?.getLoginCount() ?? 0,
        lastLogin: moment
          .unix(u.getUserProfileInfo()?.getLastLogin() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      });
    });
    return partnerUsers;
  }

  async loadUsers() {
    const request = new GetAdminUsersRequest();
    if (this.partner.data) request.setPartnerId(this.partner.data.getId());

    try {
      this.admins = pending;
      const response = await userServiceClient.getAdminUsers(request, {});
      this.admins = success(response.getUsersList());
    } catch (error) {
      this.admins = failure(userErrorFrom(error));
    }
  }

  onRemoveAdminClicked(user: UserRow): void {
    event?.stopPropagation();
    this.selectedUserForAdminRemove = user;
    this.removeAdminDialog = true;
  }

  onAddAdminClicked(): void {
    event?.stopPropagation();
    this.addAdminDialog = true;
  }
}
