
import projects from "@/store/modules/projects";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { tariffServiceClient } from "@/config/service-clients";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CharityProject } from "zaehlerfreunde-proto-types/tariff_pb";
import { SelectCharityProjectRequest } from "zaehlerfreunde-central/tariff_service_pb";

@Component
export default class ProjectDetails extends Vue {
  @Prop() project: CharityProject;
  @Prop() projectsPerPage: number;

  selectProjectCall: RemoteCall<UserError> = initialized;

  async projectSelected(): Promise<void> {
    try {
      this.selectProjectCall = pending;

      const request = new SelectCharityProjectRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setProjectId(this.project.getId());
      const response = await tariffServiceClient.selectCharityProject(request, {});
      projects.setProjects(success(response.getCharityProjectsList()));
      this.selectProjectCall = success(void 0);
    } catch (error) {
      this.selectProjectCall = failure(userErrorFrom(error));
    }
  }

  onMoreInfoClicked(e: Event): void {
    e.stopPropagation();
  }
}
