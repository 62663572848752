
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  BlueMeteringDevice,
  GetBlueMeteringDevicesRequest,
  ImportBluemeteringDataRequest,
} from "zaehlerfreunde-central/device_service_pb";
import spaces from "@/store/modules/spaces";
import { getMediumName } from "@/utils/device-utils";
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class SelectBluemeteringDevice extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  @Prop({ default: false }) isAdminImport: boolean;

  paths = paths;

  step: "enter-api-key" | "select-device" = "enter-api-key";

  bluemeteringDevices: RemoteData<UserError, BlueMeteringDevice[]> = initialized;
  importDataCall: RemoteCall<UserError> = initialized;

  apiKey: string = "";

  headers = [
    { value: "selected" },
    { text: "Name", value: "name" },
    { text: "Melo", value: "melo" },
    { text: "Medium", value: "medium" },
  ];

  selectedDevices: BlueMeteringDevice[] = [];

  get showSkipBtn(): boolean {
    return this.$route.query["skippable"] === "true";
  }

  onDeviceSelected(device: BlueMeteringDevice, selected: boolean): void {
    if (selected) {
      this.selectedDevices.push(device);
    } else {
      this.selectedDevices = this.selectedDevices.filter((d) => d !== device);
    }
  }

  mounted(): void {
    const apiKey = this.$route.query["apiKey"];

    if (apiKey && typeof apiKey === "string") {
      this.apiKey = apiKey;
      this.getBluemeteringDevices();
    }
  }

  async getBluemeteringDevices(): Promise<void> {
    try {
      this.bluemeteringDevices = pending;

      const request = new GetBlueMeteringDevicesRequest();
      request.setBluemeteringApiKey(this.apiKey);

      if (this.isAdminImport) {
        request.setDoNotSaveApiKey(true);
      } else {
        request.setDoNotSaveApiKey(false);
      }

      const response = await deviceServiceClient.getBlueMeteringDevices(request, {});

      this.bluemeteringDevices = success(response.getDevicesList());
      this.step = "select-device";
      this.$emit("changedStep", this.step);
    } catch (error) {
      this.bluemeteringDevices = failure(userErrorFrom(error));
    }
  }

  async startImport(): Promise<void> {
    try {
      this.importDataCall = pending;

      const request = new ImportBluemeteringDataRequest();
      request.setDevicesList(this.selectedDevices);

      if (this.isAdminImport) {
        request.setDoNotAddOwnerToSpace(true);
        request.setBluemeteringApiKey(this.apiKey);
      }

      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      await deviceServiceClient.importBluemeteringData(request, {});

      this.importDataCall = success(void 0);
      spaces.getSpaces();
      this.$emit("done");
    } catch (error) {
      this.importDataCall = failure(userErrorFrom(error));
    }
  }

  get deviceItems() {
    return (this.bluemeteringDevices.data ?? []).map((d) => ({
      selected: this.selectedDevices.some((device) => device === d),
      disabled: !(d as any).hasMedium(),
      name: d.getName(),
      melo: d.getMeloId(),
      medium: (d as any).hasMedium() ? getMediumName(d.getMedium()) : "Unbekannt",
      device: d,
    }));
  }
}
