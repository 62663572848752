
import { RemoteData, RemoteCall, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import DeviceSpaces from "./DeviceSpaces.vue";
import DeviceDetails from "./DeviceDetails.vue";
import DeleteDeviceDialog from "./DeleteDeviceDialog.vue";
import { GetDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { deviceServiceClient } from "@/config/service-clients";
import ConsumptionGraph from "@/pages/platform/dashboard/ConsumptionGraph.vue";
@Component({
  components: {
    DeviceDetails,
    DeviceSpaces,
    ConsumptionGraph,
    DeleteDeviceDialog,
  },
})
export default class DeviceInfo extends Vue {
  deviceInfo: RemoteData<UserError, Device | undefined> = initialized;
  spaces: RemoteData<UserError, Space[]> = initialized;
  deleteDeviceCall: RemoteCall<UserError> = initialized;

  selectedTab: number = 1;
  deviceId: string = "";
  showDeleteDeviceDialog: boolean = false;

  mounted() {
    this.getDevice();
  }

  async getDevice() {
    this.deviceInfo = pending;
    this.deviceId = this.$route.params.deviceId;
    const request = new GetDeviceRequest();
    request.setDeviceId(this.deviceId);
    try {
      const response = await deviceServiceClient.getDevice(request, null);
      this.deviceInfo = success(response.getDevice());
    } catch (error) {
      this.deviceInfo = failure(userErrorFrom(error));
    }
  }

  back() {
    this.$router.go(-1);
  }

  changeTab(number: number) {
    this.selectedTab = number;
  }
}
