
import { readingsServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import {
  failure,
  initialized,
  pending,
  RemoteCall,
  RemoteData,
  success,
  successOptional,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { ForwardingRule } from "zaehlerfreunde-proto-types/forwarding_pb";
import { DeleteForwardingRuleRequest, GetForwardingRulesRequest } from "zaehlerfreunde-central/reading_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface Section {
  header: string;
  items: RuleItem[];
}

interface RuleItem {
  title: string;
  value: string;
  tooltip?: string;
}

@Component
export default class ForwardingRuleComponent extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  rule: RemoteData<UserError, ForwardingRule> = initialized;
  deleteRuleCall: RemoteCall<UserError> = initialized;

  showDeleteDialog: boolean = false;

  paths = paths;

  @Watch("showDeleteDialog")
  onShowDeleteDialogChanged(): void {
    if (this.deleteRuleCall.succeeded) {
      this.$router.replace(paths.admin.forwarding);
    }
  }

  get topic(): string {
    const mqtt = this.rule.data?.getDestination()?.getMqttDestination();
    return (mqtt?.hasDynamicTopic() ? mqtt.getDynamicTopic()?.getBase() : mqtt?.getStaticTopic()) ?? "";
  }

  get sections(): Section[] {
    const sections: Section[] = [
      {
        header: "Weiterleitungsregeln",
        items: [
          {
            title: "Geräte",
            value: this.rule.data?.getDeviceProviderNamesList()?.join(", ") ?? "",
          },
          {
            title: "Werte",
            value: this.rule.data?.getMeasurementNamesList()?.join(", ") ?? "",
          },
          {
            title: "Format",
            value: this.rule.data?.getDestination()?.getFormatName() ?? "",
          },
          {
            title: "Art",
            value: "MQTT",
          },
        ],
      },
    ];

    if (this.rule.data?.getDestination()?.hasMqttDestination()) {
      const mqtt = this.rule.data?.getDestination()?.getMqttDestination();

      sections.push({
        header: "MQTT Broker Einstellungen",
        items: [
          {
            title: "Broker",
            value: mqtt?.getBroker() ?? "",
          },
          {
            title: "Username",
            value: mqtt?.getUsername() || "-",
          },
          {
            title: "Password",
            value: mqtt?.getPassword() || "-",
          },
          {
            title: "TLS",
            value: mqtt?.getTls() ? "Ja" : "Nein",
          },
          {
            title: "Topic",
            value: this.topic,
          },
          ...(mqtt?.hasDynamicTopic()
            ? [
                {
                  title: "Dynamischer Teil",
                  value: mqtt.getDynamicTopic()?.getDynamicPartName() ?? "",
                  tooltip: "asd",
                },
              ]
            : []),
          {
            title: "Heartbeat Topic",
            value: mqtt?.getHeartbeatTopic() ?? "-",
          },
        ],
      });
    }

    return sections;
  }

  async mounted(): Promise<void> {
    try {
      this.rule = pending;
      const request = new GetForwardingRulesRequest();
      request.setRuleId(this.$route.params.ruleId);

      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      const response = await readingsServiceClient.getForwardingRules(request, {});

      this.rule = successOptional(response.getRulesList()[0]);
    } catch (error) {
      this.rule = failure(userErrorFrom(error));
    }
  }

  async deleteRule(): Promise<void> {
    const ruleId = this.rule.data?.getId();
    if (!ruleId) {
      return;
    }

    try {
      this.deleteRuleCall = pending;

      const request = new DeleteForwardingRuleRequest();
      request.setRuleId(ruleId);
      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      await readingsServiceClient.deleteForwardingRule(request, {});

      this.deleteRuleCall = success(void 0);
    } catch (error) {
      this.deleteRuleCall = failure(userErrorFrom(error));
    }
  }
}
