
import { Vue, Component, Watch } from "vue-property-decorator";
import { userErrorFrom, UserError } from "@/types/user-error";
import { partnerServiceClient } from "@/config/service-clients";
import { pending, success, initialized, failure, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { GetPartnerThemeRequest, UpdateThemeRequest } from "zaehlerfreunde-central/partner_service_pb";
import { PartnerTheme, PartnerLogo, Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { partnerModule } from "@/store/modules/partner";
import { hexCodeRule, imageUrlRule } from "@/utils/rules";

interface ColorPalleteItem {
  label: string;
  key: string;
  value: string;
}

@Component
export default class AdminAppearance extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  theme: RemoteData<UserError, PartnerTheme | undefined> = initialized;
  updateThemeCall: RemoteCall<UserError> = initialized;
  isValid: boolean = false;
  themeData: PartnerTheme;

  allowDarkMode: boolean = true;
  lightLogo: string = "https://cdn.zaehlerfreunde.com/logos/zaehlerfreunde-logo.png";
  darkLogo: string = "https://cdn.zaehlerfreunde.com/logos/zaehlerfreunde-logo-white.png";
  favicon: string = "https://cdn.zaehlerfreunde.com/logos/zaehlerfreunde-logo.png";
  widthLightLogo: number = 216;
  widthDarkLogo: number = 216;

  colorPalleteItems: ColorPalleteItem[] = [
    { label: "Primärfarbe", key: "primaryColor", value: "#05843b" },
    { label: "Sekundärfarbe", key: "secondaryColor", value: "#f29f05" },
    { label: "Akzentfarbe", key: "accentColor", value: "#82B1FF" },
    { label: "Infofarbe", key: "infoColor", value: "#2196F3" },
    { label: "Erfolgsfarbe", key: "successColor", value: "#05843b" },
    { label: "Warnungsfarbe", key: "warningColor", value: "#f29f05" },
    { label: "Fehlerfarbe", key: "errorColor", value: "#FF5252" },
  ];
  hexCodeRule = hexCodeRule;
  imageUrlRule = imageUrlRule;

  beforeMount(): void {
    this.getPartnerTheme();
  }

  async getPartnerTheme() {
    this.theme = pending;
    try {
      const request = new GetPartnerThemeRequest();
      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      const response = await partnerServiceClient.getPartnerTheme(request, {});
      this.theme = success(response.getPartnerTheme());
      if (this.theme.data != undefined) {
        this.allowDarkMode = this.theme.data.getAllowDarkMode();
        this.favicon = this.theme.data.getFavicon();
        this.lightLogo = this.theme.data.getLogoLight()?.getSrc() ?? this.lightLogo;
        this.widthLightLogo = this.theme.data.getLogoLight()?.getWidth() ?? this.widthLightLogo;
        this.darkLogo = this.theme.data.getLogoDark()?.getSrc() ?? this.darkLogo;
        this.widthDarkLogo = this.theme.data.getLogoDark()?.getWidth() ?? this.widthDarkLogo;
        this.setColorPalleteItems();
      }
    } catch (error) {
      this.theme = failure(userErrorFrom(error));
    }
  }

  async updateTheme() {
    if (this.isValid) {
      this.updateThemeCall = pending;
      try {
        const request = this.mapUpdateThemeRequest();

        if (this.partner.data) request.setPartnerId(this.partner.data.getId());

        const response = await partnerServiceClient.updateTheme(request, {});
        this.updateThemeCall = success(void response);
        this.$router.go(0);
      } catch (error) {
        this.updateThemeCall = failure(userErrorFrom(error));
      }
    }
  }

  setColorPalleteItems() {
    this.colorPalleteItems.forEach((item) => {
      if (item.key == "primaryColor") {
        item.value = this.theme.data?.getPrimaryColor() ?? "";
      } else if (item.key == "secondaryColor") {
        item.value = this.theme.data?.getSecondaryColor() ?? "";
      } else if (item.key == "accentColor") {
        item.value = this.theme.data?.getAccentColor() ?? "";
      } else if (item.key == "errorColor") {
        item.value = this.theme.data?.getErrorColor() ?? "";
      } else if (item.key == "infoColor") {
        item.value = this.theme.data?.getInfoColor() ?? "";
      } else if (item.key == "successColor") {
        item.value = this.theme.data?.getSuccessColor() ?? "";
      } else if (item.key == "warningColor") {
        item.value = this.theme.data?.getWarningColor() ?? "";
      }
    });
  }

  mapUpdateThemeRequest() {
    const request = new UpdateThemeRequest();
    const theme = new PartnerTheme();
    theme.setAllowDarkMode(this.allowDarkMode);
    theme.setFavicon(this.favicon);

    const lightLogo = new PartnerLogo();
    lightLogo.setSrc(this.lightLogo);
    lightLogo.setWidth(this.widthLightLogo);
    theme.setLogoLight(lightLogo);

    const darkLogo = new PartnerLogo();
    darkLogo.setSrc(this.darkLogo);
    darkLogo.setWidth(this.widthLightLogo);
    theme.setLogoDark(darkLogo);

    this.colorPalleteItems.forEach((item) => {
      if (item.key == "primaryColor") {
        theme.setPrimaryColor(item.value);
      } else if (item.key == "secondaryColor") {
        theme.setSecondaryColor(item.value);
      } else if (item.key == "accentColor") {
        theme.setAccentColor(item.value);
      } else if (item.key == "errorColor") {
        theme.setErrorColor(item.value);
      } else if (item.key == "infoColor") {
        theme.setInfoColor(item.value);
      } else if (item.key == "successColor") {
        theme.setSuccessColor(item.value);
      } else if (item.key == "warningColor") {
        theme.setWarningColor(item.value);
      }
    });

    request.setTheme(theme);
    return request;
  }

  checkWidth(val) {
    if (val > 0 && val <= 500) {
      return true;
    } else {
      return "The width has to be between 0 and 500";
    }
  }
}
