
import { tariffServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { formatNumberOptionalCustomDigits, formatNumerOptional, parseFloatOptional } from "@/utils/number-utils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetPriceComponentInfosRequest, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";

interface PriceComponentRow {
  id: string;
  name: string;
  value: string;
  unit: string;
}

@Component
export default class PriceComponents extends Vue {
  @Prop() tariff: Tariff;

  priceComponentInfos: RemoteData<UserError, Tariff.PriceComponentInfo[]> = initialized;
  updateCall: RemoteCall<UserError> = initialized;

  showDeletePriceComponentDialog: boolean = false;
  showAddPriceComponentDialog: boolean = false;
  priceComponentIdToDelete: string | null = null;

  selectedPriceComponentType: Tariff.PriceComponent.Type | null = null;
  selectedPriceComponentUnit: Tariff.PriceComponent.Unit | null = null;
  priceComponentName = "";
  priceComponentValue = "";

  headers = [
    { text: "Name", value: "name" },
    { text: "Wert", value: "value" },
    { text: "Einheit", value: "unit" },
    { text: "", value: "controls" },
  ];

  @Watch("selectedPriceComponentType")
  onSelectedComponentTypeChanged() {
    this.selectedPriceComponentUnit = null;
  }

  get rows(): PriceComponentRow[] {
    return this.tariff.getPriceComponentsList().map((pc) => ({
      id: pc.getId(),
      name: pc.getName(),
      value: pc.getValue() != 0 ? formatNumberOptionalCustomDigits(pc.getValue(), 3)! : "flexibel",
      unit: pc.getUnitDescription(),
    }));
  }

  get addPriceComponentBtnDisabled(): boolean {
    return (
      this.priceComponentName === null ||
      this.selectedPriceComponentType === null ||
      this.selectedPriceComponentUnit === null ||
      (this.showValueInput && !this.priceComponentValue)
    );
  }

  get showValueInput() {
    const pc = this.priceComponentInfos.data?.find((pc) => pc.getType() === this.selectedPriceComponentType);
    return pc?.getRequiresValue() ?? false;
  }

  get priceComponentUnits() {
    const pc = this.priceComponentInfos.data?.find((pc) => pc.getType() === this.selectedPriceComponentType);

    if (pc) {
      return pc.getAllowedUnitsList().map((unit, i) => ({
        text: pc.getAllowedUnitsDescriptionsList()[i],
        value: unit,
      }));
    } else {
      return [];
    }
  }

  get priceComponentTypes() {
    return (
      this.priceComponentInfos.data?.map((info) => ({
        text: info.getTypeDescription(),
        value: info.getType(),
      })) ?? []
    );
  }

  mounted(): void {
    this.getPriceComponentInfos();
  }

  showDeleteDialog(priceComponentId: string): void {
    this.priceComponentIdToDelete = priceComponentId;
    this.showDeletePriceComponentDialog = true;
  }

  async addPriceComponent(): Promise<void> {
    const type = this.selectedPriceComponentType;
    const unit = this.selectedPriceComponentUnit;
    const value = parseFloatOptional(this.priceComponentValue);

    if (type !== null && unit !== null) {
      const priceComponent = new Tariff.PriceComponent();
      priceComponent.setName(this.priceComponentName);
      priceComponent.setType(type);
      priceComponent.setUnit(unit);
      if (value) {
        priceComponent.setValue(value);
      }

      const tariff = this.tariff;
      tariff.addPriceComponents(priceComponent);

      const request = new UpdateTariffRequest();
      request.setTariff(tariff);

      try {
        this.updateCall = pending;
        const response = await tariffServiceClient.updateTariff(request, {});
        this.updateCall = success(void 0);
        this.$emit("change", response.getTariff());
        this.showAddPriceComponentDialog = false;
        this.selectedPriceComponentType = null;
        this.selectedPriceComponentUnit = null;
        this.priceComponentName = "";
        this.priceComponentValue = "";
      } catch (error) {
        this.updateCall = failure(userErrorFrom(error));
      }
    }
  }

  async deletePriceComponent(): Promise<void> {
    const tariff = this.tariff;
    tariff.setPriceComponentsList(
      this.tariff.getPriceComponentsList().filter((pc) => pc.getId() !== this.priceComponentIdToDelete)
    );

    const request = new UpdateTariffRequest();
    request.setTariff(tariff);

    try {
      this.updateCall = pending;
      await tariffServiceClient.updateTariff(request, {});
      this.updateCall = success(void 0);
      this.$emit("change", tariff);
      this.showDeletePriceComponentDialog = false;
    } catch (error) {
      this.updateCall = failure(userErrorFrom(error));
    }
  }

  async getPriceComponentInfos(): Promise<void> {
    const request = new GetPriceComponentInfosRequest();

    try {
      this.priceComponentInfos = pending;
      const response = await tariffServiceClient.getPriceComponentInfos(request, {});
      this.priceComponentInfos = success(response.getInfosList());
    } catch (error) {
      this.priceComponentInfos = failure(userErrorFrom(error));
    }
  }
}
