
import { Component, Vue } from "vue-property-decorator";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { accountModule } from "@/store/modules/account";
import { partnerModule } from "@/store/modules/partner";
import { AddUserToSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { spaceServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";

@Component({
  components: {},
})
export default class WelcomeScreen extends Vue {
  @accountModule.State userDetails: RemoteData<UserError, User>;
  @partnerModule.Getter supportEmail: string;
  @partnerModule.Getter isPartnerEssen: boolean;

  spaceMappingKey: string = "";
  addUserToSpaceCall: RemoteCall<UserError> = initialized;

  get name(): string {
    return this.userDetails.data?.getName() ?? "";
  }

  async onProceedClicked(): Promise<void> {
    const request = new AddUserToSpaceRequest();

    request.setAccessRole("standard");
    request.setByKey(this.spaceMappingKey);

    try {
      this.addUserToSpaceCall = pending;
      await spaceServiceClient.addUserToSpace(request, {});
      this.addUserToSpaceCall = success(void 0);
      spaces.getSpaces();
    } catch (error) {
      this.addUserToSpaceCall = failure(userErrorFrom(error));
    }
  }
}
