
import { accountModule } from "@/store/modules/account";
import { RemoteData, toNullable } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { User } from "@auth0/auth0-spa-js";
import { Component, Vue } from "vue-property-decorator";
import ChangePassword from "./ChangePassword.vue";
import DeleteAccount from "./DeleteAccount.vue";
import { partnerAuthConfig } from "@/config/auth-config";

@Component({
  components: {
    ChangePassword,
    DeleteAccount,
  },
})
export default class LoginInfos extends Vue {
  @accountModule.State user: RemoteData<UserError, User>;
  @accountModule.Getter isAuth0Account: boolean;
  @accountModule.State passwordUpdated: RemoteData<UserError, boolean>;

  showChangePasswordDialog: boolean = false;
  showDeleteAccountDialog: boolean = false;

  get emailAddress(): string | undefined {
    return this.user.data?.email;
  }

  get passwordChangeable(): boolean | null {
    return this.isAuth0Account;
  }

  get passwordEmailSent(): boolean | null {
    return toNullable(this.passwordUpdated);
  }

  closeChangePasswordDialog(): void {
    this.showChangePasswordDialog = false;
  }

  closeDeleteAccountDialog(): void {
    this.showDeleteAccountDialog = false;
  }
  async logout(): Promise<void> {
    Vue.$cookies.remove("demoToken");
    const authConfig = await partnerAuthConfig;
    this.$auth.logout({
      logoutParams: {
        returnTo: authConfig.getLogoutRedirectUrl(),
      },
    });
  }
}
