
import { partnerServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Watch } from "vue-property-decorator";
import CreateClientSecret from "./CreateClientSecret.vue";
import DeleteClientSecret from "./DeleteClientSecret.vue";
import { ClientSecret } from "zaehlerfreunde-proto-types/api_token_pb";
import { GetClientSecretsRequest } from "zaehlerfreunde-central/partner_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface Item {
  clientSecret: ClientSecret;
  name: string;
  owner: string;
  createdAt: string;
}

@Component({
  components: {
    CreateClientSecret,
    DeleteClientSecret,
  },
})
export default class ClientSecretsList extends Vue {
  @partnerModule.State partner: RemoteData<UserError, Partner | undefined>;

  clientSecrets: RemoteData<UserError, ClientSecret[]> = initialized;

  showGenerateClientSecretDialog = false;
  showDeleteSecretDialog = false;

  clientSecretToDelete: ClientSecret | null = null;

  headers = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Owner",
      value: "owner",
    },
    {
      text: "Erstellt",
      value: "createdAt",
    },
    {
      text: "",
      value: "deleteSecret",
    },
  ];

  get items(): Item[] {
    return this.clientSecrets.list.map((r: ClientSecret) => ({
      clientSecret: r,
      name: r.getName(),
      owner: r.getOwnerUserName(),
      createdAt: moment.unix(r.getCreatedAt()).fromNow(),
    }));
  }

  openDocumentation(): void {
    window.open("https://external.prod.zaehlerfreunde.com/partner/swagger/index.html", "_blank");
  }

  openIntegrationGuide(): void {
    window.open(
      "https://zaehlerfreunde.notion.site/Z-hlerfreunde-API-Integration-90db9e3ae8274071ba378b4983822157",
      "_blank"
    );
  }

  mounted(): void {
    this.getSecrets();
  }

  async getSecrets(): Promise<void> {
    try {
      this.clientSecrets = pending;
      const request = new GetClientSecretsRequest();
      if (this.partner.data) request.setPartnerId(this.partner.data.getId());

      const response = await partnerServiceClient.getClientSecrets(request, {});
      this.clientSecrets = success(response.getClientSecretsList());
    } catch (error) {
      this.clientSecrets = failure(userErrorFrom(error));
    }
  }

  deleteSecret(clientSecret: ClientSecret): void {
    this.clientSecretToDelete = clientSecret;
    this.showDeleteSecretDialog = true;
  }
}
